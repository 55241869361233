<template>
  <div class="bg-white shadow rounded-md pt-8 mb-6">
    <h2
      class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0 sm:mx-6"
    >
      Recent Activity
    </h2>
    <div class="flex flex-col hidden-print">
      <div class="overflow-x-auto rounded-lg">
        <div class="align-middle inline-block w-full">
          <table class="relative divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  v-for="column in columns"
                  :key="column.name"
                  scope="col"
                  class="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider uppercase"
                >
                  <FilterableHeaderCell
                    v-if="column.param"
                    :title="column.name"
                  />
                  <template v-else>
                    {{ column.name }}
                  </template>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                  style="width: 14rem"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="relative blur-content">
              <div
                class="absolute filter blur-none w-full h-full top-0 flex justify-center items-center z-10"
                style="background-color: rgba(255, 255, 255, 0.3)"
              >
                <div class="text-xl font-semibold opacity-100">
                  No recent activity on this account.
                </div>
              </div>
              <tr v-for="job in jobs" :key="job.id" class="border-b-1 bg-white">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 max-w-xs lg:max-w-md xl:max-w-lg"
                >
                  <div class="truncate">
                    {{ job.input_file?.name }}
                  </div>
                </td>
                <td class="px-6 py-8 text-sm text-gray-500">
                  {{ job.createdAt }}
                </td>
                <td class="px-6 py-8 whitespace-nowrap text-sm text-gray-500">
                  {{ job.emails_count }}
                </td>
                <template v-if="job.isEvaluation">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 middle-align"
                  >
                    <span class="inline-flex flex-wrap">
                      <span
                        class="px-2 text-gray-500 bg-gray-100 rounded-sm border-gray-200"
                      >
                        Evaluated
                      </span>
                      <span class="my-auto ml-1 text-left has-tooltip">
                        <QuestionMarkCircleIcon class="h-4" />
                      </span>
                    </span>
                  </td>
                </template>
                <template v-else>
                  <td class="px-6 py-4 whitespace-nowrap text-sm">
                    <span
                      class="px-2 text-gray-700 bg-gray-100 rounded-sm border-gray-200"
                    >
                      Completed
                    </span>
                  </td>
                </template>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="flex flex-row flex-shrink items-center justify-between"
                  >
                    <div
                      class="flex flex-row flex-wrap flex-shrink items-center"
                    >
                      <template v-if="job.isEvaluation">
                        <button
                          style="width: 120px"
                          type="button"
                          class="button-submit bg-gray-300 m-0 w-auto"
                        >
                          Clean List
                        </button>
                      </template>
                      <template v-else>
                        <button
                          v-show="job.result_file"
                          style="width: 120px"
                          type="button"
                          class="button-submit bg-gray-300 m-0"
                        >
                          Download List
                        </button>
                      </template>
                    </div>
                    <button class="button-icon ml-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Paginator ref="paginator" :onPageChange="() => null" :data="{}">
          <template v-slot:info="slotProps">
            <div class="w-full mr-4">
              <div
                class="flex flex-row-reverse items-center flex-shrink justify-between align-center"
              >
                <div
                  class="text-sm text-gray-700 whitespace-nowrap hidden sm:block"
                >
                  {{ " " }}
                  <span class="font-medium">{{ slotProps.page }}</span>
                  {{ " " }}
                  of
                  {{ " " }}
                  <span class="font-medium">{{ slotProps.pageCount }}</span>
                  {{ " " }}
                </div>
                <div class="flex-1 py-4">
                  <div class="text-sm text-left text-gray-500 mr-4 italic">
                    <span
                      >Your lists will be automatically deleted in
                      {{ settings?.AUTO_DELETE_DAYS || 14 }} days by default.
                    </span>
                    <router-link
                      to="/contact"
                      class="whitespace-nowrap underline hover:text-gray-400"
                      >Contact us</router-link
                    >
                    to request an extension or reduction.
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Paginator>
      </div>
    </div>
  </div>
</template>

<script>
import Paginator from "@/components/common/Paginator";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import { ref } from "vue";
import { mapGetters } from "vuex";
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import placeholderJobs from "@/constants/dashboard/placeholderJobs.json";

export default {
  name: "JobsPlaceholder",
  components: {
    QuestionMarkCircleIcon,
    Paginator,
    FilterableHeaderCell,
  },
  setup() {
    const columns = ref([
      { name: "Name", param: "input_file__name" },
      { name: "Created", param: "created_at" },
      { name: "Qty.", param: "emails_count" },
      { name: "Status", param: "status" },
    ]);
    const urlParams = ref({});
    const jobs = placeholderJobs;

    return { jobs, columns, urlParams, itemRefs: [] };
  },
  computed: {
    ...mapGetters({ settings: "settings/settings" }),
  },
};
</script>
<style scoped>
.blur-content > * {
  @apply filter;
  @apply blur-sm;
}
</style>
