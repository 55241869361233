<template>
  <div></div>
</template>
<script>
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import { mapGetters } from "vuex";
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";

export default {
  name: "CleanTheList",
  mixins: [formMixin],
  beforeMount() {
    eventBus.on("clean-the-list", this.onCleanTheList);
  },
  beforeUnmount() {
    eventBus.off("clean-the-list", this.onCleanTheList);
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
  },
  setup() {
    const job = ref(null);
    const price = ref(0);
    const missingCredits = ref(0);
    const toggleDetailsModal = ref(false);

    return {
      job,
      price,
      missingCredits,
      toggleDetailsModal,
    };
  },
  methods: {
    async onCleanTheList({ job, toggleModal, callback = null }) {
      this.toggleDetailsModal = toggleModal;
      this.job = job;
      try {
        await this.verifyJob();
      } finally {
        if (callback) {
          callback();
        }
      }

      const hasToPurchaseCredits = !!this.missingCredits;

      if (this.toggleDetailsModal) {
        eventBus.trigger("close-details");
      }
      if (!hasToPurchaseCredits) {
        await this.confirmRunningJob();
      } else {
        const {is_parent, allow_billing} = this.userData;
          if(!allow_billing && !is_parent){
            eventBus.trigger("notify", {
              notification: {
                group: "top-right",
                type: "warning",
                text: "Insufficient credits.",
              },
            });
            return false;
          }
        eventBus.trigger("select-credits", {
          onSubmit: this.onCheckout,
          missingCredits: this.missingCredits,
          onClose: this.onClose,
        });
      }
    },
    async onCheckout(data) {
      eventBus.trigger("checkout", {
        onSubmit: this.runCleaningJob,
        data: {
          primaryPrice: data.primaryPrice,
          credits: data.credits,
          price: data.price,
          discount: data.discount,
        },
      });
    },
    async verifyJob() {

      const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredService.verifyJob({
            input_file: {
             id: this.job.upload_id
            },
            actions: ["verification"],
          }),
        { throwExceptions: true }
      );
      this.price = response["credit_cost"];
      this.missingCredits = response["missing_credits"];
    },
    async confirmRunningJob() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to hygiene this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.runCleaningJob(),
        onClose: this.onClose,
      });
    },
    async runCleaningJob() {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredServiceV2.createJob({
            input_file: {
             id: this.job.upload_id
            },
            actions: ["verification"],
          }),
        {}
      );
      if (response) {
        eventBus.trigger("show-success");
        eventBus.trigger("update", { page: 1 });
        eventBus.trigger("reset-page");
      }
    },
    onClose() {
      if (this.toggleDetailsModal) {
        eventBus.trigger("open-details", this.job);
      }
    },
  },
};
</script>
