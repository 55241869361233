<template>
  <Container>
    <template v-slot:header>Global Defaults</template>
    <template v-slot:content>
      <div class="flex justify-between flex-col-reverse xl:flex-row">
        <div class="xl:w-4/6 p-8 mb-8 bg-white shadow rounded-md">
          <div>
            <h2
              class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0"
            >
              Pricing
            </h2>
            <div class="mt-4 max-w-xl flex flex-col items-start">
              <label for="email">
                Set credit quantity and price thresholds
              </label>
              <PricingAdminTable />
            </div>
          </div>
        </div>
        <div class="flex-grow sm:ml-4">
          <div class="flex justify-between">
            <div class="w-full p-8 mb-8 bg-white shadow rounded-md">
              <h2
                class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0"
              >
                Free Credits
              </h2>
              <FreeCreditsAdminForm />
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-full p-8 mb-8 bg-white shadow rounded-md">
              <h2
                class="text-center sm:text-left text-lg text-gray-900 mb-4 mx-0"
              >
                Auto-delete
              </h2>
              <AutoDeleteAdminForm />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import PricingAdminTable from "@/components/admin/common/PricingAdminTable";
import FreeCreditsAdminForm from "@/components/admin/common/FreeCreditsAdminForm";
import AutoDeleteAdminForm from "@/components/admin/common/AutoDeleteAdminForm";
import apiMixin from "@/mixins/apiMixin";

export default {
  name: "GlobalDefaults",
  mixins: [apiMixin],
  components: {
    Container,
    FreeCreditsAdminForm,
    AutoDeleteAdminForm,
    PricingAdminTable,
  },
};
</script>
