<template>
  <div :class="classes">
    <div>
      <div class="text-center sm:text-left">
        <div class="text-xs text-gray-400 mb-2">Available Credits</div>
        <div class="text-2xl text-gray-900">{{ userCredits }}</div>
      </div>
    </div>
    <router-link
      to="/account/billing"
      v-if="showButton"
      class="button-cancel sm:mt-0"
    >
      Buy More
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import humanize from "humanize";

export default {
  name: "AvailableCredits",
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default:
        "flex flex-grow flex-wrap sm:flex-nowrap sm:ml-6 justify-center sm:justify-between items-center bg-white shadow rounded-md p-8 mb-6",
    },
  },
  computed: {
    ...mapGetters({
      userData: "auth/userData",
    }),
    userCredits() {
      let userCredits = 0;
      if(this.userData?.credits == 0){
        userCredits = this.userData?.credits;
      }else{
        userCredits = this.userData?.credits || this.userData?.organization?.credits;
      }
      return humanize.numberFormat(userCredits, 0);
    },
  },
};
</script>
