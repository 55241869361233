<template>
  <div class="mt-4">
    <form @submit.prevent="onSubmit" class="flex flex-col items-start">
      <label for="email"> Set amount of free credits for new users </label>
      <div class="mt-1 w-full">
        <NumberInput
          v-model="freeCredits"
          @input="formActions.clearFormErrors"
          required
        />
        <p class="text-red-500 text-sm mt-1">{{ errors.value }}</p>
      </div>
      <button
        type="submit"
        :disabled="Object.keys(errors).length > 0"
        class="button-submit"
      >
        Confirm
      </button>
    </form>
  </div>
</template>
<script>
import { ref } from "vue";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import useConfirmationModal from "@/api/confirmationModal";

export default {
  name: "FreeCreditsAdminForm",
  mixins: [formMixin],
  components: {
    NumberInput,
  },
  setup() {
    const { errors, formActions } = useFormErrors();
    const freeCredits = ref(256);

    return {
      freeCredits,
      errors,
      formActions,
    };
  },
  beforeMount() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      try {
        const response = await this.$store.dispatch("fetching/FETCH", {
          apiCall: async () =>
            await this.$alfredService.getSetting("FREE_CREDITS"),
          handleErrors: false,
        });
        this.freeCredits = response?.value || 256;
      } catch (e) {
        this.freeCredits = 256;
      }
    },
    async onSubmit() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        message: "This action will overwrite all settings in the system",
        onSubmit: () => this.handleChangeSetting(),
      });
    },
    async handleChangeSetting() {
      const response = await this.handleSubmissionErrors(
        () =>
          this.$alfredService.changeSetting({
            key: "FREE_CREDITS",
            value: this.freeCredits,
          }),
        { formActions: this.formActions, throwExceptions: true }
      );
      if (response) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Free credits setting successfully updated!",
          },
        });
      }
    },
  },
};
</script>
