<template>
  <div class="pb-4">
    <ExpertDownloadModal />
    <ExportKlaviyoList />
    <KlaviyoDownload ref="KlaviyoDownload" />
    <div
      class="flex sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8"
    >
      <div class="flex flex-col text-sm text-left sm:w-1/4 w-full">
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Name</span>
          <span class="text-gray-500 whitespace-nowrap">{{ fileName }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Created</span>
          <span class="text-gray-500 whitespace-nowrap">{{ createdAt }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Quantity</span>
          <span class="text-gray-500 whitespace-nowrap">{{
            totalEmails(emailsCount)
          }}</span>
        </div>
        <div class="grid grid-cols-2 mb-2">
          <span class="font-medium text-gray-600 uppercase">Status</span>
          <span class="text-gray-500 whitespace-nowrap">Complete</span>
        </div>
      </div>
    </div>

    <TabContent :data="charts" :count="emailsCount" />
  </div>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import TabContent from "@/components/basic/file-details-page/TabContent";
import apiMixin from "@/mixins/apiMixin";
import modalMixin from "@/mixins/modalMixin";

import humanize from "humanize";

export default {
  name: "UsageCompleteDetails",
  mixins: [modalMixin, apiMixin],
  components: {
    TabContent,
  },

  setup() {
    return {
      job: reactive({}),
      jobResult: reactive({}),
      createdAt: ref(""),
      emailsCount: ref(0),
      fileName: ref(""),
      charts: reactive({}),
      determineEmailCount: ref(0),
      KlaviyoDownload: ref(),
    };
  },

  async beforeMount() {
    const jobDetails = await this.$store.dispatch("fetching/FETCH", {
      apiCall: async () =>
        await this.$alfredService.getJob(this.$route.params.id),
    });
    
    this.job = jobDetails;
    this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
      "en-US"
    );
    this.fileName = jobDetails.input_file.name;
    this.emailsCount = jobDetails.emails_count;
    if (jobDetails.actions[0] === "deliverability"){
      this.jobResult = {
      deliverabilityStats: jobDetails.result?.deliverability_job?.deliverability,
      providers: jobDetails.result?.deliverability_job?.providers,
      reasons: jobDetails.result?.deliverability_job?.reasons,
    };
      this.charts = [
      this.determineDeliverabilityStatusChart(
        this.jobResult.deliverabilityStats
      ),
      this.determineReasonChart(),
      this.determineProviderChart(),
    ];
    }else{
      this.jobResult = {
      deliverabilityStats: jobDetails.result?.verification_job?.deliverability,
      qualityStats: jobDetails.result?.verification_job?.scores,
      threatStats: jobDetails.result?.verification_job?.threat?.threat_scores,
      scoreStatus: jobDetails.result?.verification_job?.scores,
      emailStatus: jobDetails.result?.verification_job?.scores,
      reasons: jobDetails.result?.verification_job?.reasons,
      providers: jobDetails.result?.verification_job?.providers,
    };
    this.charts = [
      this.determineObjectiveChart(),
      this.determineRecommendationChart(),
      this.determineRatingScoreChart(),
      this.determineDeliverabilityStatusChart(
        this.jobResult.deliverabilityStats
      ),
      this.determineThreatLevelChart(),
      this.determineReasonChart(),
      this.determineProviderChart(),
    ];
    }
    this.determineEmailCount = this.emailsCount;
    this.createdAt = new Date(jobDetails.input_file.created_at).toLocaleString(
      "en-US"
    );
    this.fileName = jobDetails.input_file.name;
    this.emailsCount = jobDetails.emails_count;
  },

  methods: {
    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },
    totalEmails(count) {
      return humanize.numberFormat(count, 0);
    },
    determineObjectiveChart() {
      const qualityStats = this.jobResult.qualityStats.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 7) {
            accumulator[0] += currentValue.email_count;
          }
          if (currentValue.score >= 6) {
            accumulator[1] += currentValue.email_count;
          }
          return accumulator;
        },
        [0, 0]
      );

      return {
        title: "Objective",
        visible: true,
        labels: [
          { text: "Best Deliverability", color_class: "text-green-600" },
          { text: "Maximum Reach", color_class: "text-yellow-400" },
        ],
        data: qualityStats,
      };
    },

    determineRecommendationChart() {
      const qualityStats = this.jobResult.qualityStats.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 7) {
            accumulator[0] += currentValue.email_count;
          } else if (currentValue.score >= 4) {
            accumulator[1] += currentValue.email_count;
          } else {
            accumulator[2] += currentValue.email_count;
          }
          return accumulator;
        },
        [0, 0, 0]
      );

      return {
        title: "Recommendation",
        visible: true,
        labels: [
          { text: "Safe to Send", color_class: "text-green-600" },
          { text: "Send with Caution", color_class: "text-yellow-400" },
          { text: "Do not Send", color_class: "text-red-600" },
        ],
        data: qualityStats,
      };
    },

    determineRatingScoreChart() {
      const qualityStats = this.jobResult.scoreStatus.reduce(
        (accumulator, currentValue) => {
          if (currentValue.score >= 9) {
            accumulator[0] += currentValue.email_count;
          } else if (currentValue.score >= 7) {
            accumulator[1] += currentValue.email_count;
          } else if (currentValue.score == 6) {
            accumulator[2] += currentValue.email_count;
          } else if (currentValue.score >= 4) {
            accumulator[3] += currentValue.email_count;
          } else {
            accumulator[4] += currentValue.email_count;
          }

          return accumulator;
        },
        [0, 0, 0, 0, 0]
      );

      return {
        title: "Score",
        visible: true,
        labels: [
          { text: "10-9 (out of 10)", color_class: "text-green-600" },
          { text: "7-8 (out of 10)", color_class: "text-green-400" },
          { text: "6 (out of 10)", color_class: "text-yellow-400" },
          { text: "4-5 (out of 10)", color_class: " text-red-400" },
          { text: "1-3 (out of 10)", color_class: " text-red-600" },
        ],
        data: qualityStats,
      };
    },

    determineDeliverabilityStatusChart({
      deliverable,
      possibly_deliverable,
      undeliverable,
      unknown_deliverability,
    }) {
      return {
        title: "Deliverability Status",
        visible: true,
        labels: [
          { text: "Deliverable", color_class: "text-green-600" },
          { text: "Possibly Deliverable", color_class: "text-yellow-400" },
          { text: "Undeliverable", color_class: "text-red-600" },
          { text: "Unknown", color_class: "text-gray-400" },
        ],
        data: [
          deliverable,
          possibly_deliverable,
          undeliverable,
          unknown_deliverability,
        ],
      };
    },
    determineThreatLevelChart() {
      let tempThreatStats = this.jobResult.threatStats;
      if (this.jobResult.threatStats.length > 3) {
        tempThreatStats = [
          { score: 1, email_count: 0 },
          { score: 2, email_count: 0 },
          { score: 3, email_count: 0 },
        ];
        //reduce to low mid high
        this.jobResult.threatStats.forEach((element) => {
          if (element.score == 1) {
            tempThreatStats[0].email_count += element.email_count;
          } else if (element.score <= 3) {
            tempThreatStats[1].email_count += element.email_count;
          } else if (element.score <= 5) {
            tempThreatStats[2].email_count += element.email_count;
          }
        });
      }
      const threatStats = tempThreatStats.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.email_count);
          return accumulator;
        },
        []
      );
      const totalKnown = threatStats.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const totalUnknown = this.job.emails_count - totalKnown;

      //new respose with low,mid,high
      return {
        title: "Threat Level",
        visible: true,
        labels: [
          { text: "Low", color_class: "text-green-400" },
          { text: "Moderate", color_class: "text-yellow-400" },
          { text: "High", color_class: "text-red-600" },
          { text: "Undetermined/Unknown", color_class: "text-gray-400" },
        ],
        data: [...threatStats, totalUnknown],
      };
    },

    determineReasonChart() {
      let labels = [];
      let data = [];
      const color_class = {
        accepted_email: "text-green-600",
        catch_all: "text-yellow-400",
        disposable: "text-yellow-400",
        invalid_email: "text-red-600",
        invalid_domain: "text-red-600",
        rejected_email: "text-red-600",
        dns_error: "text-yellow-400",
        unavailable_smtp: "text-yellow-400",
        unknown: "text-gray-400",
        role_based: "text-yellow-400",
        mailbox_disabled: "text-gray-400",
        mailbox_full: "text-gray-400",
        suspicious: "text-yellow-400",
        dangerous: "text-red-600",
        malicious: "text-red-600",
      };
      const acceptemails = Object.keys(this.jobResult.reasons)
        .filter((key) => key.includes("accepted_email"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.reasons[key],
          });
        }, {});

      const acceptemailbox = Object.keys(this.jobResult.reasons)
        .filter((key) => key.includes("mailbox_full"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.reasons[key],
          });
        }, {});

      const selectedyello = [
        "catch_all",
        "disposable",
        "dns_error",
        "role_based",
        "unavailable_smtp",
        "suspicious",
      ];

      const filteredyellow = Object.keys(this.jobResult.reasons)
        .filter((key) => selectedyello.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.jobResult.reasons[key];
          return obj;
        }, {});

      const sortableYellow = Object.fromEntries(
        Object.entries(filteredyellow).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );

      const selectedred = [
        "malicious",
        "dangerous",
        "rejected_email",
        "invalid_domain",
        "invalid_email",
      ];

      const filteredred = Object.keys(this.jobResult.reasons)
        .filter((key) => selectedred.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.jobResult.reasons[key];
          return obj;
        }, {});

      const sortablered = Object.fromEntries(
        Object.entries(filteredred).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );
      const object = {
        ...acceptemails,
        ...sortableYellow,
        ...sortablered,
        ...acceptemailbox,
      };
      const finalObject = object;

      this.jobResult?.reasons &&
        Object.keys(finalObject).forEach((element) => {
          labels.push({
            text: this.humanize(element),
            color_class: color_class[element] || "text-gray-400",
          });
          data.push(finalObject[element]);
        });

      return {
        title: "Reason",
        visible: this.jobResult?.reasons ? true : false,
        labels: labels,
        data: data,
      };
    },
    determineProviderChart() {
      let labels = [];
      let data = [];
      const excluded = Object.keys(this.jobResult.providers)
        .filter((key) => !key.includes("Other"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.providers[key],
          });
        }, {});
      const sortableexcluded = Object.fromEntries(
        Object.entries(excluded).sort((a, b) => {
          if (b[1] > a[1]) return 1;
          else if (b[1] < a[1]) return -1;
        })
      );

      const othersmails = Object.keys(this.jobResult.providers)
        .filter((key) => key.includes("Other"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.jobResult.providers[key],
          });
        }, {});

      const providerdata = { ...sortableexcluded, ...othersmails };

      this.jobResult?.providers &&
        Object.keys(providerdata).forEach((element) => {
          labels.push({
            text: element,
            color_class: "text-yellow-400",
          });
          data.push(providerdata[element]);
        });

      return {
        title: "Providers",
        visible: this.jobResult?.providers ? true : false,
        labels: labels,
        data: data,
      };
    },
  },
};
</script>
