import { ref } from "vue";

const isOpen = ref(false);
const submitCallback = ref(null);
const closeCallback = ref(null);
const modalTitle = ref("");
const modalMessage = ref("");
const modalPrimaryButton = ref("");
const modalSecondaryButton = ref("");

export default function useConfirmationModal() {
  async function openModal({
    onSubmit = null,
    onClose = null,
    title,
    message,
    primaryButton,
    secondaryButton
  }) {
    modalTitle.value = title;
    modalMessage.value = message;
    submitCallback.value = onSubmit;
    closeCallback.value = onClose;
    isOpen.value = true;
    modalPrimaryButton.value = primaryButton;
    modalSecondaryButton.value = secondaryButton;
  }

  return {
    isOpen,
    openModal,
    submitCallback,
    closeCallback,
    modalTitle,
    modalMessage,
    modalPrimaryButton,
    modalSecondaryButton
  };
}
