<template>

  <section class="flex flex-col mt-6 mb-8">
    <span class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center">
      <span class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center">

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">Start Date</span>
          <input type="date" class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
            v-model="payload.start_date" @change="changeFilter()" />
        </span>

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">End Date</span>
          <input type="date" class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
            v-model="payload.end_date" @change="changeFilter()" />
        </span>

      </span>
    </span>
  </section>
  <div class="align-middle p-5 bg-white inline-block w-full">
    <table class="divide-gray-300 w-full text-left table-auto">
      <tbody>
        <div class="p-2">
          <span class="font-medium text-gray-600 uppercase text-left">NEW USERS: </span><span
          class="text-gray-500 whitespace-nowrap pl-3 text-right"> {{ formatN(new_users) }}</span>
        </div>
        <div class="p-2">
          <span class="font-medium text-gray-600 uppercase text-left">ACTIVE USERS: </span><span
          class="text-gray-500 whitespace-nowrap pl-3 text-right"> {{ formatN(active_users) }}</span>
        </div>
         <div class="p-2">
          <span class="font-medium text-gray-600 uppercase text-left">USERS WITH ACTIVE INTEGRATIONS: </span><span
          class="text-gray-500 whitespace-nowrap pl-3 text-right"> {{ formatN(active_integrations) }}</span>
         </div>
         <div class="p-2">
          <span class="font-medium text-gray-600 uppercase text-left">TOTAL CREDITS USED: </span><span
          class="text-gray-500 whitespace-nowrap pl-3 text-right"> {{ formatN(total_credits_used) }}</span>
         </div>

      </tbody>
    </table>
  </div>

</template>

<script>
import { ref, reactive } from "vue";
import apiMixin from "@/mixins/apiMixin";
import { mapGetters } from "vuex";
import humanize from "humanize";

export default {
  name: "AdminNewUsers",
  mixins: [apiMixin],
  data() {
    return {
      filters: reactive({
        start_date: "",
        end_date: "",
      }),
      new_users: ref([]),
      active_users: ref([]),
      active_integrations: ref([]),
      total_credits_used: ref([]),
      payload: reactive({
        start_date: "",
        end_date: "",
      })
    };
  },
  computed: {
    ...mapGetters({adminStatsDate: "settings/adminStatsDate"})
  },
  mounted() {
    this.payload.end_date = this.adminStatsDate.end_date;
    this.payload.start_date = this.adminStatsDate.start_date;
    this.fetchNewUsers()
  },
  methods: {
    async fetchNewUsers() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.StatsNewUsers(this.payload),
      });
      if (response) {
        this.new_users = response?.new_users;
        this.active_users = response?.active_users;
        this.active_integrations = response?.active_integrations;
        this.total_credits_used = response?.total_credits_used;
      }
    },
    changeFilter() {
      let admin_stats_date = {
      start_date: this.payload.start_date,
      end_date: this.payload.end_date,
    }
    this.$emit('getDate', admin_stats_date);
    this.$store.dispatch('settings/SET_ADMIN_STATS_DATE', admin_stats_date);
      this.fetchNewUsers();
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style scoped>
select {
  background-image: none;
  border-color: #ffffff00;
}

.date_inp {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>