<template>
  <DialogContainer :isOpen="isOpen" :onClose="onClose">
    <template v-slot:title>Select Credits</template>
    <template v-slot:content>
      <p class="block text-sm mt-2">
        You have {{ userCreditsDisplay }} credits. Buy more credits!
      </p>
      <div class="mt-6">
        <ThresholdCheckboxList
          v-if="showThresholds"
          @onChosen="onCheckboxOption"
          :thresholds="thresholds"
          :userCredits="userCredits"
          :minimalPurchase="minimalPurchase"
          ref="thresholdCheckboxes"
        />
        <div class="mt-6 sm:flex flex-row items-center">
          <span @click="enableInput" class="w-full">
            <label class="mb-1">Exact Amount</label>
            <span class="flex items-center">
              <NumberInput
                v-model="typedCredits"
                @input="onCreditsInput"
                :disabled="checkedCredits"
                class="form-field-no-icon sm:flex-1"
                ref="creditsInput"
              />
              <span v-if="!errors.credits"
                class="sm:flex-1 ml-4 hidden sm:flex flex-row justify-center h-full text-sm"
              >
                ${{ creditsPriceDisplay }}
              </span>
            </span>
          </span>
          <p class="block text-sm sm:hidden mt-2">
            Price: ${{ creditsPriceDisplay }}
          </p>
        </div>
        <p class="text-red-500 text-sm mt-1">
          {{ errors.credits }}
        </p>
      </div>

      <div class="flex flex-col items-start">
        <DiscountInput v-model="discount" />
        <div class="mt-6 flex flex-col items-start w-full">
          <button
            type="button"
            :disabled="hasErrors || !finalCredits || !finalPrice"
            @click="onSubmit"
            class="button-submit"
          >
            Proceed to Checkout
          </button>
          <div class="mt-2">
            <div class="italic text-sm">Credits never expire</div>
          </div>
        </div>
      </div>
    </template>
  </DialogContainer>
</template>
<script>
import DialogContainer from "@/components/common/DialogContainer";
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";
import useFormErrors from "@/api/formErrors";
import humanize from "humanize";
import modalMixin from "@/mixins/modalMixin";
import thresholdsMixin from "@/mixins/thresholdsMixin";
import NumberInput from "@/components/common/NumberInput";
import ThresholdCheckboxList from "@/components/basic/common/ThresholdCheckboxList";
import { mapGetters } from "vuex";
import DiscountInput from "@/components/basic/common/DiscountInput";
import { debounce } from "lodash";

export default {
  name: "BuyCreditsModal",
  mixins: [formMixin, modalMixin, thresholdsMixin],
  components: {
    DialogContainer,
    NumberInput,
    ThresholdCheckboxList,
    DiscountInput,
  },
  setup() {
    const isOpen = ref(false);
    const submitCallback = ref(null);
    const closeCallback = ref(null);
    const modalEventName = "buy-credits";

    const { errors, formActions } = useFormErrors();

    const primaryPrice = ref(null);
    const typedCredits = ref(null);
    const typedPrice = ref(null);
    const checkedCredits = ref(null);
    const checkedPrice = ref(null);
    const pricingSchema = ref([]);
    const minimalPurchase = 100;

    const discount = ref({});
    const thresholds = ref([]);
    const creditPack = [1000, 5000, 25000, 50000, 100000, 500000, 1000000];

    const showThresholds = ref(false);
    const userCreditlimit = ref(null);

    return {
      isOpen,
      submitCallback,
      closeCallback,
      discount,
      creditPack,
      errors,
      formActions,
      pricingSchema,
      primaryPrice,
      typedCredits,
      typedPrice,
      checkedCredits,
      checkedPrice,
      minimalPurchase,
      modalEventName,
      thresholds,
      showThresholds,
      userCreditlimit
    };
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.formActions.clearFormErrors();
        this.checkedCredits = null;
        this.checkedPrice = null;
        this.discount = {};
      }
    },
    checkedCredits(value) {
      if (value) {
        this.typedCredits = null;
        this.typedPrice = null;
        this.formActions.clearFormErrors();
      }
    },
    discount: {
      deep: true,
      async handler(value) {
        if (this.typedCredits) {
          await this.getTypedPrice(this.typedCredits, value?.name);
        }
        await this.getPricing(value?.name);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    userCredits() {
      return this.userData?.organization?.credits || 0;
    },
    userCreditsDisplay() {
      return humanize.numberFormat(this.userCredits, 0);
    },
    creditsPriceDisplay() {
      return humanize.numberFormat(this.typedPrice, 2);
    },
    finalCredits() {
      return this.checkedCredits || this.typedCredits;
    },
    finalPrice() {
      return this.checkedPrice || this.typedPrice;
    },
  },
  methods: {
    async openModal({ onSubmit = null, onClose = null, initialValue = null }) {
      this.submitCallback = onSubmit;
      this.closeCallback = onClose;
      this.isOpen = true;
      this.typedCredits = initialValue;
      if (initialValue === null) {
        this.typedPrice = null;
        this.showThresholds = true;
      } else {
        this.getTypedPrice(initialValue);
        this.showThresholds = false;
      }
      await this.getPricing();
    },
    onSubmit() {
      this.isOpen = false;
      if (!this.hasErrors && this.submitCallback) {
        this.submitCallback({
          primaryPrice: this.primaryPrice,
          credits: this.finalCredits,
          price: this.finalPrice,
          discount: this.discount,
        });
      }
    },
    onClose() {
      this.isOpen = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    },
    onCheckboxOption(option) {
      this.primaryPrice = option?.primaryPrice;
      this.checkedPrice = option?.price;
      this.checkedCredits = option?.credits;
    },
    enableInput() {
      if (this.checkedCredits) {
        this.checkedCredits = null;
        this.$refs.thresholdCheckboxes.resetSelection();
      }
    },
    onCreditsInput: debounce(function (value) {
      if (value > 200000000) {
        this.errors.credits = "Credit limit exceeded!"
        return
      }
      this.getTypedPrice(value, this.discount?.name);
    }, 300),
    async getTypedPrice(value, discountName) {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.verifyCreditPack({
            credits: value,
            discount_code: discountName,
          }),
        { formActions: this.formActions }
      );
      if (response) {
        await this.formActions.clearFieldError("credits");
        this.typedPrice = response["price"];
        this.primaryPrice = response["initial_price"];
      }
    },
    async getPricing(discountCode = undefined) {
      let urlParams = this.creditPack.map((credits) => [
        "credit_query",
        credits,
      ]);
      if (discountCode) {
        urlParams.push(["discount_code", discountCode]);
      }
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: () => this.$alfredService.getPricing(urlParams),
      });
      if (response) {
        this.pricingSchema = response.pricing_table;
        this.userCreditlimit = this.pricingSchema[this.pricingSchema.length - 1];
        this.thresholds = this.determineThresholds(response.credit_table);
      }
    },
  },
};
</script>
