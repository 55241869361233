<template>
  <Container>
    <template v-slot:header>API</template>
    <template v-slot:content>
      <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
        <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
          Verification API
        </h2>
        <div class="flex flex-col items-start pb-8">
          <span class="text-sm font-medium text-gray-700">
            Generate an API Key to clean files outside the application
          </span>
          <button
            type="button"
            @click="generateVerificationApiKey"
            class="button-submit"
          >
            Generate New Key
          </button>
        </div>
        <ApiKeysTable :data="apiKeys" />
      </section>
      <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
        <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
          Webhooks
        </h2>
        <div class="flex flex-col items-start pb-8">
          <span class="block text-sm font-medium text-gray-700">
            Specify the URL and add webhook to connect the external services
          </span>
          <div class="flex flex-col sm:flex-row items-start w-full sm:w-10/12">
            <span
              class="inline-flex flex-col items-start w-full sm:max-w-xl sm:flex-grow mb-2 sm:mb-0"
            >
              <input
                v-model="webhookUrl"
                class="form-field-no-icon inline w-full mt-1"
              />
              <p class="text-red-500 text-sm mt-1">{{ errors?.url }}</p>
            </span>
            <button
              type="button"
              :disabled="!webhookUrl"
              @click="addWebhook"
              class="button-submit mt-1 sm:ml-3"
            >
              Add Webhook
            </button>
          </div>
        </div>
        <WebhooksTable :data="webhooks" />
      </section>
      <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
        <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
          API Documentation
        </h2>
        <div class="flex flex-col items-start pb-8">
          <a class="link-text" target="_blank" href="https://docs.alfred.email"
            >https://docs.alfred.email</a
          >
        </div>
      </section>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import ApiKeysTable from "@/components/basic/api-keys-page/ApiKeysTable";
import WebhooksTable from "@/components/basic/api-keys-page/WebhooksTable";
import apiMixin from "@/mixins/apiMixin";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import { ref } from "vue";

export default {
  name: "ApiKeys",
  mixins: [apiMixin, formMixin],
  components: {
    Container,
    ApiKeysTable,
    WebhooksTable,
  },
  beforeMount() {
    eventBus.on("update-webhooks", this.fetchWebhooks);
    eventBus.on("update-api-keys", this.fetchApiKeys);
  },
  beforeUnmount() {
    eventBus.off("update-webhooks", this.fetchWebhooks);
    eventBus.off("update-api-keys", this.fetchApiKeys);
  },
  setup() {
    const webhookUrl = ref("");
    const webhooks = ref([]);
    const apiKeys = ref([]);
    const { errors, formActions } = useFormErrors();

    return { webhookUrl, webhooks, apiKeys, errors, formActions };
  },
  methods: {
    async generateVerificationApiKey() {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.createApiKey({
            scope: "VERIFICATION",
            name: "Verification API Key",
          }),
        { formActions: this.formActions }
      );
      if (response) {
        await this.fetchApiKeys();
      }
    },
    async fetchApiKeys() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.fetchApiKeys(),
      });
      this.apiKeys = response ? response : [];
    },
    async addWebhook() {
      const response = await this.handleSubmissionErrors(
        async () =>
          await this.$alfredService.createWebhook({
            url: this.webhookUrl,
          }),
        { formActions: this.formActions }
      );
      if (response) {
        this.webhookUrl = "";
        this.formActions.clearFormErrors();
        await this.fetchWebhooks();
      }
    },
    async fetchWebhooks() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.fetchWebhooks(),
      });
      this.webhooks = response
        ? response.sort((first, second) => {
            return new Date(second.created_at) - new Date(first.created_at);
          })
        : [];
    },
  },
};
</script>
<style>
.trash-cell {
  width: 100px;
}
</style>
