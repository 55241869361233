<template>
  <span class="flex mt-6 mb-6 text-gray-600 items-center">
    <span class="w-full sm:w-auto">TYPE</span>
    <select class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase" v-model="churn_type"
      @change="onChange()">
      <option v-for="churn in churn_type_options" :key="churn" :value="churn">
        {{ churn }}
      </option>
    </select>
  </span>
  <span class="flex mt-6 mb-3 text-gray-600 items-center">TOTAL: {{ formatN(count) }}</span>
  <div class="float-right">
    <span v-if="churn_type=='Credit Purchase'" class="text-gray-400">*No purchase in the last 30 days after having a purchase in the 60 days prior to that</span>
    <span v-else class="text-gray-400">*No activity in the last 30 days after being active in the 60 days prior to that</span>
  </div>
  <div class="align-middle mt-3 p-5 bg-white inline-block w-full">
    <table class="divide-gray-300 w-full text-left table-auto">
      <thead class="border-b-2">
        <tr>
          <th v-for="columnName in columnNames" :key="columnName.name" scope="col" :class="`
                px-6
                py-5
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              `">
            <FilterableHeaderCell :title="columnName.name" :param="columnName.param"
              @switchOrder="onSwitchOrders(columnName.param)" />
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data">
          <tr v-for="(row, index) in data" :key="index">

            <td class="px-6 py-5 text-sm text-gray-500">
              {{ row }}
            </td>
          </tr>
        </template>
        <template v-else>
          <div class="px-6 py-5 text-sm text-gray-500 text-left">
            No data
          </div>
        </template>
      </tbody>
    </table>
  </div>
  <Paginator ref="paginator" :onPageChange="onPageChange" :pageSize="15" :data="paginationData" />
</template>

<script>
import { ref, reactive } from "vue";
import apiMixin from "@/mixins/apiMixin";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import Paginator from "@/components/common/Paginator";
import humanize from "humanize";

export default {
  name: "AdminChurnUsers",
  mixins: [apiMixin],
  components: {
    Paginator,
    FilterableHeaderCell,
  },
  data() {
    return {
      data: ref([]),
      columnNames: ref([
        { name: "Email" },
      ]),
      paginationData: reactive(""),
      page: ref(1),
      churn_type_options: ref(["Credit Purchase", "User Activity"]),
      churn_type: ref("Credit Purchase"),
      ch_type: ref("credit_pack"),
      count: ref("")
    };
  },
  mounted() {
    this.fetchCreditChurnUsers()
  },
  methods: {
    async fetchCreditChurnUsers() {
      let urlParams = {
        churn_type: this.ch_type,
        page: this.page
      };
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.StatsCreditChurnUsers(urlParams),
      });
      if (response) {
        this.data = response.results;
        delete response.results;
        this.paginationData = response;
        this.count = response.count;
      }
    },
    onChange() {
      if (this.churn_type == "Credit Purchase") {
        this.ch_type = "credit_pack";
      } else {
        this.ch_type = "activity";
      }
      this.fetchCreditChurnUsers();
    },
    async onPageChange(page) {
      this.page = page;
      await this.fetchCreditChurnUsers();
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
  },
};
</script>
<style scoped>
select {
  background-image: none;
  border-color: #ffffff00;
}

.date_inp {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>