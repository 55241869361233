<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 w-72 mx-8 my-4 h-48 flex flex-col"
  >
    <div class="px-4 py-6 sm:p-6">
      <div class="flex flex-row justify-between items-center">
        <span>Billing Information</span>
      </div>
      <div class="mt-10 flex flex-row flex-grow justify-between">
        <div v-if="data?.name">{{ data.name }}</div>
        <div v-else><br/></div>

      </div>
    </div>
    <button @click="onUpdate" class="button-submit py-4 mt-0 rounded-none">
      <a class="text-base">Update</a>
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";
export default {
  name: "BillingDetails",
  mixins: [formMixin],
  inject: ["BillingDetailsModal"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      pendingUpdate: ref(false),
    };
  },
  methods: {
    async onUpdate() {
      this.BillingDetailsModal.value.openModal(this.data);
    },
  },
};
</script>
