const stripeElementsMixin = {
  methods: {
    async initialize() {
      this.updateWrapperStyle("cardNumber");
      this.updateWrapperStyle("cardExpiry");
      this.updateWrapperStyle("cardCvc");

      const stripe = await this.$stripeService;
      const elements = stripe.elements({
        locale: window.__exampleLocale,
      });
      const elementStyles = {
        base: {
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily:
            'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        },
      };
      const cardNumber = elements.create("cardNumber", {
        style: elementStyles,
      });
      const cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
      });
      const cardCvc = elements.create("cardCvc", {
        style: elementStyles,
      });

      cardNumber.on("focus", () => this.updateWrapperStyle("cardNumber"));
      cardNumber.on("blur", () => this.updateWrapperStyle("cardNumber"));
      cardNumber.on("change", () =>
        this.formActions.clearFieldError("cardNumber")
      );
      cardExpiry.on("focus", () => this.updateWrapperStyle("cardExpiry"));
      cardExpiry.on("blur", () => this.updateWrapperStyle("cardExpiry"));
      cardExpiry.on("change", () =>
        this.formActions.clearFieldError("cardExpiry")
      );
      cardCvc.on("focus", () => {
        if (this.triggerFocus) {
          this.$refs.streetAddress.focus();
        }
        this.updateWrapperStyle("cardCvc");
      });
      cardCvc.on("blur", () => {
        this.triggerFocus = true;
        setTimeout(() => {
          this.triggerFocus = false;
        }, 500);
        this.updateWrapperStyle("cardCvc");
      });
      cardCvc.on("change", () => this.formActions.clearFieldError("cardCvc"));

      cardNumber.mount(this.$refs.cardNumber);
      cardExpiry.mount(this.$refs.cardExpiry);
      cardCvc.mount(this.$refs.cardCvc);

      this.adjustTabIndexes(cardNumber, cardExpiry, cardCvc);

      this.stripeElements = {
        cardNumber,
        cardExpiry,
        cardCvc,
      };
    },
    adjustTabIndexes(cardNumber, cardExpiry, cardCvc) {
      cardNumber._implementation._fakeInput.tabIndex = -1;
      if (this.isFirefox()) {
        cardExpiry._implementation._fakeInput.tabIndex = -1;
        cardCvc._implementation._fakeInput.tabIndex = -1;
        cardNumber._implementation._component.children[1].tabIndex = -1;
        cardExpiry._implementation._component.children[1].tabIndex = -1;
        cardCvc._implementation._component.children[1].tabIndex = -1;
      }
    },
    updateWrapperStyle(key) {
      this.wrapperStyles[key] = this.getWrapperStyle(this.stripeElements[key]);
    },
    getWrapperStyle(element) {
      let style =
        "mt-1 shadow-sm w-full text-sm rounded-md py-2 px-3 border ring-1";
      if (element?._focused) {
        style += " focused";
      } else {
        style += " ring-transparent border-gray-300";
      }
      return style;
    },
    blurInput(ref) {
      if (this.isFirefox()) {
        this.stripeElements?.cardNumber.blur();
        this.stripeElements?.cardExpiry.blur();
        this.stripeElements?.cardCvc.blur();
        this.$refs[ref].focus();
      }
    },
    isFirefox() {
      return navigator.userAgent.indexOf("Firefox") !== -1;
    },
  },
};

export default stripeElementsMixin;
