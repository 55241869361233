<template>
  <div>
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate bg-white shadow p-4 sm:p-6 lg:px-8"
    >
      <slot name="header" />
    </h2>
    <div class="text-center pt-6 pb-0 px-4 sm:px-6 lg:pt-10 lg:px-8">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Container",
};
</script>
