<template>
  <div class="flex flex-col items-start">
    <label for="email" class="p-3">
      Get usage summary for the selected dates
    </label>
    <div class="mt-1 w-full">
      <div class="flex items-center justify-center">
        <div date-rangepicker class="flex items-center relative">
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              name="start"
              v-model="startdate"
              type="date"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select date start"
            />
          </div>
          <span class="mx-4 text-gray-500">to</span>
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              name="end"
              v-model="enddate"
              type="date"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select date end"
            />
          </div>

          <div class="p-2">
            <button
              type="button"
              @click="totalCount"
              class="button-submit absolute bottom-0"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="w-full bg-white shadow-md border rounded-md p-8" v-if="respon">
      <div v-if="countResults.length > 0">
        <div
          class="w-full text-sm p-2"
          v-for="(item, index) in countResults"
          :key="index"
        >
          <div class="w-full flex" id="name">
            <div class="font-medium text-gray-600 uppercase text-left">
              {{ item.name }}:
            </div>
            <div class="text-gray-500 whitespace-nowrap pl-3 text-right">
              {{ item.val }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";

export default {
  name: "UsageSummaryAdminForm",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },

  setup() {
    return {
      startdate: ref(),
      enddate: ref(),
      respon: ref(false),
      objectarray: ref([]),
      countResults: ref([]),
    };
  },

  methods: {
    async totalCount() {
      this.countResults = [];
      const response = await this.$alfredService.usageSummary({
        start_date: this.startdate,
        end_date: this.enddate,
        user: this.userId,
      });
      if (response) {
        this.objectarray = Object.keys(response);
        for (let i = 0; i < this.objectarray.length; i++) {
          var obj = {
            name: this.humanize(this.objectarray[i]),
            val: response[this.objectarray[i]],
          };
          this.countResults.push(JSON.parse(JSON.stringify(obj)));
        }

        this.respon = true;
      }
    },
    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },
  },
};
</script>
