<template>
  <div class="grid grid-cols-2">
    <div class="pr-2">
      <div class="text-left mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="recurring_type" class="block text-sm font-medium text-gray-700">
            Recurrence Type
          </label>
          <div class="mt-1">
            <select class="form-field-no-icon sm:w-full" v-model="type_of_recurring">
              <option v-for="reccuring in recurring_options" :key="reccuring" :value="reccuring">
                {{ reccuring }}
              </option>
            </select>
            <p class="text-red-500 text-sm mt-1">
              {{ errors["type_of_recurring"] }}
            </p>
          </div>
        </div>
        <div v-if="is_time_period" class="sm:col-span-6">
          <label for="recurring_time_period" class="block text-sm font-medium text-gray-700">
            Auto-Purchase Interval (Days)
          </label>
          <div>
            <NumberInput id="time_period" name="time_period" type="number" v-model="time_period"
              class="form-field-no-icon w-full"  />
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.time_period }}
          </p>
        </div>

        <div v-else class="sm:col-span-6">
          <label for="recurring_credit_limit" class="block text-sm font-medium text-gray-700">
            Auto-Purchase When Credit Balance Falls Below
          </label>
          <div>
            <NumberInput id="credit_limit" name="credit_limit" type="number" v-model="credit_limit"
              class="form-field-no-icon w-full"/>
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.credit_limit }}
          </p>
        </div>
        <div class="sm:col-span-6">
          <label for="recurring_credits" class="block text-sm font-medium text-gray-700">
            Credits to Purchase
          </label>
          <div>
            <NumberInput id="credits_to_purchase" name="credits_to_purchase" type="number" v-model="credits_to_purchase"
             class="form-field-no-icon w-full" />
            <p class="text-red-500 text-sm mt-1">
              {{ errors.credits_to_purchase }}
            </p>
            <p class="block text-sm font-bold mt-2">
              Price: ${{ formattedPrice }}
            </p>
          </div>
        </div>

        <div class="flex gap-5">
          <button v-if="this.is_update" type="submit" class="button-cancel" @click="Oncancel"
          >
            Delete
          </button>
          <button type="submit" class="button-submit" @click="Onsubmit">
            Submit
          </button>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { ref, reactive } from "vue";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import useFormErrors from "@/api/formErrors";
import humanize from "humanize";

export default {
  name: "RecurringPaymentAdmin",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },
  components: {
    NumberInput
  },
  computed: {
    formattedPrice() {
      return humanize.numberFormat(this.price, 2);
    },
  },
  setup() {
    const errorClasses =
      " form-field-no-icon w-full border-red-300 bg-red-50 focus:ring-red-500 focus:border-red-500";
    const schema = ref([]);
    const { errors, formActions } = useFormErrors();
    const data = reactive({
      account_name: "",
      credits: "",
      partner_code: "",
      tapfiliate_code: "",
      pricing: []
    })
    return {
      schema,
      errorClasses,
      errors,
      formActions,
      enablePricing: ref(false),
      data,
      showCode: ref(false),
      baseUrl: "",

      type_of_recurring: ref("Time-Based"),
      time_period: ref(null),
      credit_limit: ref(null),
      user: ref(null),
      is_time_period: ref(true),
      credits_to_purchase: ref("1000"),
      recurring_options: ref(["Time-Based", "Credit-Based"]),
      price: ref("10"),
      is_update: ref(false),
      is_admin: ref(true)
    };
  },
  async beforeMount() {
    await this.fetchRecurringData();
  },
  watch: {
    type_of_recurring() {
      this.is_time_period = this.type_of_recurring === "Credit-Based" ? false : true;
      this.formActions.clearFormErrors();
    },
    credits_to_purchase(newVal) {
      this.errors.credits_to_purchase = "";
      this.getPrice(newVal).then((resp) => {
        this.price = resp ? resp : 0;
      });
    },
  },
  methods: {
    async fetchRecurringData() {
      try {
        const response = await this.$alfredService.AdminRecurringPaymentGet(this.userId);
        let recurring_data = response[0];
        if (recurring_data) {
          this.type_of_recurring = recurring_data.type_of_recurring;
          this.time_period = recurring_data.time_period;
          this.credit_limit = recurring_data.credit_limit;
          this.credits_to_purchase = recurring_data.credits_to_purchase;
          this.is_update = true;
          this.is_admin = recurring_data.is_admin;
        } else {
          this.type_of_recurring = "Time-Based";
          this.time_period = null;
          this.credit_limit = null;
          this.credits_to_purchase = 1000
          this.is_update = false;
          this.is_time_period = true;
          this.is_admin = true;
        }
      } catch (error) {
        console.error("Error fetching recurring data:", error);
      }
    },
    OnchangeRecurring(value) {
      this.is_time_period = value === "credit_limit" ? false : true;
      this.formActions.clearFormErrors();
    },
    async getPrice(credits) {
      try {
        const response = await this.$alfredService.verifyCreditPack({
          credits: credits,
          discount_code: this.discount?.name,
        });
        if (response) {
          this.errors.credits_to_purchase = ""
          return response.price;
        }
      } catch (error) {
        this.errors.credits_to_purchase = error.response.data.credits[0]
        return null;
      }
    },
    async Onsubmit() {
      if (!this.validateForm()) return;
      this.formActions.clearFormErrors();
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        onSubmit: () => this.submitForm(),
      });
    },
    async submitForm() {
      let payload = {
        type_of_recurring: this.type_of_recurring,
        credits_to_purchase: Number(this.credits_to_purchase),
        user: this.userId,
      }
      if (this.is_time_period) {
        payload.time_period = this.time_period;
      } else {
        payload.credit_limit = this.credit_limit;
      }
      try {
        let response;
        if (this.is_update) {
          response = await this.$store.dispatch("fetching/FETCH", {
            apiCall: async () => await this.$alfredService.AdminRecurringPaymentEdit(this.userId, payload),
            handleErrors: false
          });
        } else {
          response = await this.$store.dispatch("fetching/FETCH", {
            apiCall: async () => await this.$alfredService.AdminRecurringPaymentAdd(payload),
            handleErrors: false
          });
        }
        if (response) {
          this.fetchRecurringData()
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: this.is_update ? "Recurring payment setting successfully updated!" : "Recurring payment setting successfully added!"
            },
          });
        }
      }
      catch (error) {
        console.error("API call failed:", error);
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Unexpected error has occurred!",
            type: "error",
          },
        });
      }
    },
    validateForm() {
      // this.formActions.clearFormErrors();
      let valid = true;
      this.errors.credits_to_purchase = this.errors.credits_to_purchase || '';
      if (!this.type_of_recurring) {
        this.errors.type_of_recurring = "This field cannot be blank";
        valid = false;
      }
      if (this.errors.credits_to_purchase!=='') {
        valid = false;
      }
      if (this.is_time_period) {
        if (!this.time_period) {
          this.errors.time_period = "This field cannot be blank";
          valid = false;
        }
      } else {
        if (!this.credit_limit) {
          this.errors.credit_limit = "This field cannot be blank";
          valid = false;
        }
      }
      return valid;
    },
    async Oncancel() {
      this.formActions.clearFormErrors();
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure want to remove this recurring payment?",
        message: "This action will stop future automatic payments.",
        onSubmit: async () => {
          this.$alfredService.AdminRecurringPaymentDelete(this.userId);
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: " Successfully removed!",
            },
          });
          this.fetchRecurringData()
          this.formActions.clearFormErrors();
        },
      });
    },
  },
};
</script>