<template>
  <div v-if="!isYotpoAccess" style="
    min-height: 25rem;
">
    <h2 class="text-lg leading-6 font-medium text-gray-900">
      Connect to Yotpo
    </h2>
    <div class="text-sm pb-3 text-gray-600 list-inside list-decimal pt-3">
      Before importing your list, you'll need to connect your Yotpo account with
      Alfred. Click below link to start the process.
    </div>
    <a
      class="link-text cursor-pointer"
      target="_blank"
      href="https://integrations-center.yotpo.com/app/#/install/applications/biphfduckjsgezckzmnwqglfnervy58n?state="
    >
      Connect Yotpo with Alfred</a
    >
  </div>
  <div v-else style="
    min-height: 25rem;
">
    <h2 class="text-lg leading-6 font-medium text-gray-900 pb-2">
        Choose list to import emails from Yotpo
    </h2>
    <div>
      <div class="flex sm:w-30 items-start justify-items-start mt-2">
                <SearchInput
                 v-model="searchKeyword"
                  @input="searchList"
                  placeholder="Search by List"
                />
              </div>
    <div
        class="h-60 pt-7 overflow-auto overflow-x-hidden scroll-style w-full"
              >
                <table class="w-full">
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(list) in yotpoList"
                      :key="list"
                    >
                    <td
                        class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 max-w-sm truncate"
                      >
                        <input
                          type="radio"
                          :checked="selection"
                          :id="list.list_id"
                          :name="list.name"
                          @change="onSelectList($event,list.count)"
                          :value="list.list_id"
                        />
                        <span
                          class="text-sm text-black px-6 py-3 text-center max-w-sm truncate"
                          :for="list.list_id"
                          >{{ list.name }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                 
                </table>
              </div>

    <div class="absolute right-5 bottom-5 flex p-3">
      <button @click="removeYotpo" class="button-cancel sm:ml-3">
        Remove Yotpo
      </button>
      <button
          type="button"
          class="button-submit ml-3 rounded-md bg-red-100 text-red-800"
          @click="importYotpoJob('evaluation', 0)"
        >
          Evaluate List &nbsp;<span class="font-bold">FREE</span>
      </button>
      <button @click="showPopup()" class="button-submit sm:ml-3">Hygiene List</button>
    </div>
    </div>        
    <teleport to="body">
      <transition name="fade">
      <div v-if="showPopups" class="popup-container">
        <div>

        <div class="popup inline-block align-bottom bg-white text-left transform transition-all p-4 w-full">
            <h3 id="headlessui-dialog-title-12" class="text-lg leading-6 font-medium text-gray-900"><div class="pb-3">
              <span class="font-bold">Hygiene</span>   {{ yotpoImportListName }} ({{ humanizeNumber(yotpoImportListCount) }} emails)
             </div></h3>
             <span class="pl-5 text-sm font-bold">Select Service:</span>
  <div class="flex">  
            <!-- First Box with Highlighted Top Border and Text -->
    <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
    <span class="bg-red-100 flex justify-center text-red-800 px-2 py-1 rounded-t-lg block">Most Popular</span>
    <div
      @click="selectBox(1)"
      :class="{ 'selected-box': selectedBox === 1, 'unselected-box': selectedBox !== 1 }"
      class="box-item best-value rounded-b-lg" 
    >
      <p class="font-bold">Deliverability + Threat Detection</p>
      <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Recommended for most lists</li>
      <li class="text-sm text-gray-500 text-left">Best value</li>
    </ul>
    <div  class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
      <p class="pt-3 absolute bottom-2">{{ humanizeNumber(Math.ceil(parseInt(yotpoImportListCount)))}} credits</p>
    </div>

    </div>
    </div>

   <!-- Second Box -->
   <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
        <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
    <div
      @click="selectBox(2)"
      :class="{ 'selected-box': selectedBox === 2, 'unselected-box': selectedBox !== 2 }"
      class="box-item rounded-lg"
    >
    <p class="font-bold">Deliverability Check</p>
    <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Validates email addresses</li>
      <li class="text-sm text-gray-500 text-left">Excludes threat detection</li>
      </ul>
      <div  class="bg-gray-300 p-5 w-full absolute left-0 rounded-b-lg bottom-0">
      <p class="pt-3 absolute bottom-2">{{ humanizeNumber(Math.ceil(parseInt(yotpoImportListCount) * 0.9))}} credits</p>
    </div>
    </div>
    </div>
    <!-- Third Box -->
    <div class="item-center justify-center px-5 py-5 p-2 flex-auto md:w-1/4">
        <span class="bg-white-500 text-white px-2 py-1 rounded-t-lg block">.</span>
        <div
        @click="selectBox(3)"
      :class="{ 'selected-box': selectedBox === 3, 'unselected-box': selectedBox !== 3 }"
      class="box-item rounded-lg"
    >
    <p class="font-bold">Threat Detection</p>
    <ul class="ml-3 pl-3 list-disc">
      <li class="text-sm text-gray-500 text-left">Identifies toxic addresses</li>
      <li class="text-sm text-gray-500 text-left">Excludes deliverability check</li>
      </ul>
      <div class="bg-gray-300 p-5 w-full absolute left-0 bottom-0 rounded-b-lg">
      <p class="pt-3 absolute bottom-2">{{ humanizeNumber(Math.ceil(parseInt(yotpoImportListCount) * 0.4))}} credits</p>
      </div>
    </div>
    </div>
  </div>
        </div>
        <div class="text-right bg-gray-50 rounded-b-lg p-4 shadow-xl">
        <button type="button" @click="back()" class="button-cancel">
          Cancel
        </button>
        <button type="button" @click="proceedJob(selectedBox)" class="button-submit ml-3">
          Proceed
        </button>
  </div>
      </div>
      </div>
    </transition>
    </teleport>
  </div>

</template>

<script>
import { ref } from "vue";
import eventBus from "@/utils/eventBus";
import SearchInput from "@/components/common/SearchInput";
import useConfirmationModal from "@/api/confirmationModal";
import _sortBy from "lodash/sortBy";
import humanize from "humanize";

export default {
  name: "YotpoIntegrationCenter",
  components: {
    SearchInput
  },
  setup() {
    const isYotpoAccess = ref(false);
    const yotpoList = ref([]);
    const urlParams = ref({ limit: 10, page_info: "" });
    const radioButton = ref(false);
    const radioButtons = [];
    const yotpoIdImport = ref(0);
    const yotpoImportListName = ref(0);
    const yotpoImportListCount = ref("");
    const tempyotpoList = ref([]);
    const searchKeyword = ref("");
    const showPopups = ref(false);
    const isActive = ref(false);
    const selectedBox = ref(1);
    const selectBox = (boxNumber) => {
    selectedBox.value = boxNumber;
};
    return {
      isYotpoAccess,
      yotpoList,
      urlParams,
      yotpoIdImport,
      yotpoImportListName,
      yotpoImportListCount,
      radioButtons,
      radioButton,
      tempyotpoList,
      searchKeyword,
      showPopups,
      isActive,
      selectedBox,
      selectBox,
    };
  },
  mounted() {
    this.callYotpoList();
    this.getYotpoAccess();//For testing
  },
  methods: {
    showPopup(){
      if (!this.yotpoIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
    this.showPopups = true;
    this.$emit('yotpo-clicked', true);
   },
   proceedJob(selectedBox){
    if(selectedBox == 1){
      this.importYotpoJob('verification', this.humanizeNumber(Math.ceil(parseInt(this.yotpoImportListCount))))
    }else if(selectedBox == 2){
      this.importYotpoJob('deliverability', this.humanizeNumber(Math.ceil(parseInt(this.yotpoImportListCount) * 0.9)))
    }else if(selectedBox == 3){
      this.importYotpoJob('threat_assessment', this.humanizeNumber(Math.ceil(parseInt(this.yotpoImportListCount) * 0.4)))
    }
   },
    onSelectList(event, count) {
      this.radioButton = document.getElementById(event.target.id);
      this.radioButtons.push(this.radioButton);
      if (this.radioButtons.length > 0) {
        this.radioButtons.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
      }
      this.radioButton.checked = true;
      this.yotpoIdImport = event.target.id;
      this.yotpoImportListName = event.target.name;
      this.yotpoImportListCount = count;
    },
    humanizeNumber(count) {
      return humanize.numberFormat(count, 0);
    },
    async searchList() {
      this.yotpoIdImport = "";
      if (this.radioButton) {
        this.radioButton.checked = false;
      }
      if (this.searchKeyword) {
        this.yotpoList = this.tempyotpoList.filter((x) =>
          x.name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        this.yotpoList = this.tempyotpoList;
      }
      this.yotpoList = _sortBy(this.yotpoList, "name");
    },
    async getYotpoAccess() {
      try {
        await this.$alfredService.yotpoGetAccessToken();
        this.isYotpoAccess = true;
        this.callYotpoList();
      } catch (e) {
        await this.$alfredService.yotpoRemoveAccessToken();
        this.isYotpoAccess = false;
      }
    },
    async callYotpoList() {
      try {
        const response = await this.$alfredService.yotpoGetLists(
          this.urlParams
        );
        this.yotpoList = response;
        this.isYotpoAccess = true;
        this.tempyotpoList = this.yotpoList;
      } catch (e) {
        await this.$alfredService.yotpoRemoveAccessToken();
        this.isYotpoAccess = false;
      }
    },
    back(){
      this.$emit('yotpo-clicked', false);
      this.showPopups = false;
      this.selectedBox = 1;
    },
    async importYotpoJob(action, price){
      if (!this.yotpoIdImport) {
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please select a list to import",
          },
        });
        return false;
      }
      this.showPopups = false;
      let action_map = {
        evaluation : "evaluate",
        threat_assessment: "threat assess",
        deliverability: "check deliverability of",
        verification: "hygiene"
      }
      const action_ = action_map[action]
      const modal = useConfirmationModal();
      await modal.openModal({
        title: `Are you sure you want to ${action_} this list?`,
        message: `It is going to cost <strong>${price} Credits</strong>.`,
        onSubmit: () => this.importYotpo(action),
        onClose: () => this.$emit('yotpo-clicked', false)
      });
    },
    async importYotpo(action){
      let payload = {
        list_id: this.yotpoIdImport,
        list_name: this.yotpoImportListName,
        count: this.yotpoImportListCount,
        actions: [action]
      }
      try{
        const response = await this.$alfredService.yotpoImport(payload)
        if(response){
          eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Yotpo list is uploading!",
          },
        });
        this.$emit('yotpoImport', true);
        }
      }catch(e){
        console.error(e);
      }
    },
    async removeYotpo() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to permanently remove Yotpo?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.removeYotpoapp();
        },
      });
    },
    async removeYotpoapp() {
      try {
        await this.$alfredService.yotpoRemoveAccessToken();

        this.isYotpoAccess = false;
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Successfully removed Yotpo",
          },
        });
      } catch (e) {
        console.log("error", e);
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  box-shadow: None !important;
}
.box-item {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    position: relative;
    height: 10rem;
  }
  .box-item:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .selected-box {
    background-color: #fee2e2;
    border: 1px solid #fd7e7e !important;
  }
  .box-color {
    background-color: #c9c9c9;
  }
  .selected-color{
    background-color: #ffc4c4 !important;
  }
  .used-box {
    opacity: 0.5;
    cursor:not-allowed;
  }
  .unselected-box {
    opacity: 0.8;
  }

  .checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #ff0000; 
    background-color: #ff0000; 
  }

  .checkbox:checked {
    background-color: #ff0000; 
  }
  [type='checkbox'], [type='radio'] {
    color: #ff0000;
  }
</style>