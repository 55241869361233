<template>
    <div class="min-h-screen bg-gray-50 p-2">
      <div class="flex justify-center sm:justify-start px-3 py-3">
      </div>
      <div
        class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-6 text-center text-lg font-extrabold text-gray-900">
            <div>
                <PulseLoader color="#ff5a5f" />
              </div>
          </h2>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  import PulseLoader from "vue-spinner/src/PulseLoader";

  export default {
    name: "YotpoIntegration",
    components: {
      PulseLoader
    },
    setup() {
      return {
      };
    },
    mounted (){
      const element = this.$refs.navbar;
      if (element) {
      element.classList.add('navbar hidden');
    }
    window.onload = () => {
      this.getURLParams();
    };
    },
    methods: {
      async getURLParams(){
        this.$store.dispatch('settings/SET_SHOW_UPLOAD_MODAL', true);
        await this.$router.push({ name: "Dashboard" });
      },
    },
  };
  </script>
  