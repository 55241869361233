<template>
  <DialogContainer :isOpen="isOpen" :onClose="onClose" :maxWidth="'2xl'">
    <template v-slot:title>Success</template>
    <template v-slot:content>
      <div class="flex flex-col sm:flex-row items-center">
        <div>
          <p>We'll send you an email when your list has been cleaned.</p>
          <p class="mt-6">
            Depending on the size of your list this could take a few minutes to
            a few hours.
          </p>
        </div>
        <img
          :src="require('@/assets/images/dashboard/levelup.png')"
          alt="Completed"
          class="sm:ml-8"
        />
      </div>
      <div class="flex justify-center">
        <button
          type="button"
          @click="onClose"
          class="button-submit mt-10 sm:mt-5 sm:mb-5"
        >
          Go To Dashboard
        </button>
      </div>
    </template>
  </DialogContainer>
</template>
<script>
import { ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import modalMixin from "@/mixins/modalMixin";

export default {
  name: "SuccessModal",
  mixins: [modalMixin],
  components: {
    DialogContainer,
  },
  setup() {
    const isOpen = ref(false);
    const modalEventName = "show-success";

    return {
      isOpen,
      modalEventName,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
      this.$router.push({
        name: "Dashboard",
      });
    },
  },
};
</script>
