const sortingTableMixin = {
  beforeUpdate() {
    this.itemRefs = [];
  },
  methods: {
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    resetOtherSorting(ordering) {
      this.itemRefs.forEach((ref) => {
        if (ref.ordering !== ordering) {
          ref.setOrdering(null);
        }
      });
    },
    onSwitchOrder(ordering) {
      this.resetOtherSorting(ordering);
      this.applyOrdering(ordering);
      this.$refs.paginator.setPage(1);
    },
  },
};

export default sortingTableMixin;
