<template>
  <div class="bg-white shadow rounded-b-md pt-8 mb-6 hidden-print">
    <div class="flex flex-col">
      <div class="overflow-x-auto rounded-lg">
        <div class="align-middle inline-block w-full">
          <table class="relative divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  v-for="(column, index) in columns"
                  :key="column.name"
                  scope="col"
                  :class="`
                      px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider uppercase
                      ${index === columns.length - 1 ? 'w-56' : ''}
                    `"
                >
                  <a class="inline-flex hover:text-gray-900 cursor-pointer">
                    <span class="flex" @click="fetchRealTimeList(column.name)">
                      {{ column.name }}
                      <span class="my-auto" v-if="column.name != 'Actions'">
                        <SelectorIcon class="h-4" />
                      </span>
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in results" :key="item.id">
                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                  {{ item.created_at }}
                </td>
                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                  {{ item.created_with }}
                </td>
                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                  {{ item.emails_count }}
                </td>
                <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                  <button
                    type="button"
                    @click="
                      email_check_details(
                        item.id,
                        item.created_at,
                        item.emails_count
                      )
                    "
                    class="rounded-md text-gray-700 bg-white border border-gray-300 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium m-0 w-auto"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <nav
          class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
          aria-label="Pagination"
        >
          <div class="flex-1 py-4">
            <div class="text-sm text-left text-gray-500 mr-4 italic">
              <span
                >Your lists will be automatically deleted in
                {{ settings?.AUTO_DELETE_DAYS || 14 }} days by default.
              </span>
              <router-link
                to="/contact"
                class="whitespace-nowrap underline hover:text-gray-400"
                >Contact us</router-link
              >
              to request an extension or reduction.
            </div>
          </div>
          <div class="hidden sm:block mr-4">
            <p class="text-sm text-right text-gray-700">
              {{ " " }}
              <span class="font-medium">{{ currentPage }}</span>
              {{ " " }}
              of
              {{ " " }}
              <span class="font-medium">{{ totalPages }}</span>
              {{ " " }}
            </p>
          </div>
          <div class="flex justify-between sm:justify-end">
            <button
              @click="goToPage(currentPage - 1)"
              :disabled="currentPage === 1"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              @click="goToPage(currentPage + 1)"
              :disabled="currentPage === totalPages"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { SelectorIcon } from "@heroicons/vue/solid";

export default {
  name: "RealTimeList",
  components: {
    SelectorIcon,
  },
  setup() {
    const columns = ref([
      { name: "Created", param: "created_at" },
      { name: "Created With", param: "created_with" },
      { name: "Qty.", param: "emails_count" },
      { name: "Actions" },
    ]);
    const results = ref([]);
    return {
      columns,
      itemRefs: [],
      urlParams: { page: 1, ordering: "-created_at", size: 10 },
      results,
      currentPage: 1,
      totalPages: 1,
    };
  },
  computed: {
    paginatedResults() {
      const startIndex = (this.currentPage - 1) * this.urlParams.size;
      const endIndex = startIndex + this.urlParams.size;
      return this.results.slice(startIndex, endIndex);
    },
    hasPagination() {
      return this.totalPages >= 1;
    },
  },
  mounted() {
    this.fetchRealTimeList();
  },
  methods: {
    async fetchRealTimeList(name) {
      if (name == "Created") {
        this.urlParams.ordering =
          this.urlParams.ordering === "-created_at"
            ? "created_at"
            : "-created_at";
      }else if(name === "Created With"){
        this.urlParams.ordering =
        this.urlParams.ordering === "created_with"
            ? "-created_at"
            : "created_at";
      }else if(name === "Qty."){
        this.urlParams.ordering =
        this.urlParams.ordering === "emails_count"
            ? "-emails_count"
            : "emails_count";
      }
      const response = await this.$alfredService.realtimeEmailDashboard(
        this.urlParams
      );
      if (response) {
        this.results = response.results;
        this.totalPages = Math.ceil(response.count / this.urlParams.size);
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.urlParams.page = pageNumber;
        this.fetchRealTimeList();
        this.currentPage = pageNumber;
      }
    },
    email_check_details(id) {
      this.$router.push({
        name: "EmailCheckDetails",
        params: { id: id },
      });
    },
  },
};
</script>
<style scoped>
.file-name {
  @apply text-main;
  @apply hover:text-main-hover;
  @apply cursor-pointer;
  width: 200px;
}
</style>
