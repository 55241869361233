<template>
  <div class="overflow-x-auto rounded-lg">
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="column in columns"
              :key="column.name"
              scope="col"
              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left"
            >
              <FilterableHeaderCell
                :ref="setItemRef"
                v-if="column.param"
                :title="column.name"
                :param="column.param"
                @switchOrder="onSwitchOrder"
              />
              <template v-else>
                {{ column.name }}
              </template>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <template v-if="data?.length">
            <tr v-for="(row, index) in data" :key="index">
              <template
                v-for="[columnKey, columnValue] in Object.entries(row)"
                :key="columnValue.id"
              >
                <td
                  v-if="columnKey === 'name'"
                  class="px-6 py-5 text-sm text-gray-500 text-left"
                >
                  {{ columnValue }}
                  <button v-clipboard="columnValue" class="button-icon">
                    <DocumentDuplicateIcon class="h-6 inline" />
                  </button>
                </td>
                <td
                  v-if="columnKey === 'value'"
                  class="px-6 py-5 text-sm text-gray-500 text-left"
                >
                  {{ columnValue }}%
                </td>
                <td
                  v-if="columnKey === 'end_date' || columnKey === 'start_date'"
                  class="px-6 py-5 text-sm text-gray-500 text-left"
                >
                  {{ convert(columnValue) }}
                </td>
                <td
                  v-if="columnKey === 'end_date'"
                  class="px-6 py-5 text-sm text-gray-500 text-left button-cell"
                >
                  <button @click="editDiscount(row)" class="button-icon mx-2">
                    <PencilIcon class="h-6" />
                  </button>
                  <button
                    @click="onDeleteDiscount(row.id)"
                    class="button-icon mx-2"
                  >
                    <TrashIcon class="h-6" />
                  </button>
                </td>
              </template>
            </tr>
          </template>
          <template v-else>
            <div class="px-6 py-4 text-sm text-gray-500 text-center">
              No data.
            </div>
          </template>
        </tbody>
      </table>
    </div>
    <Paginator
      ref="paginator"
      :onPageChange="onPageChange"
      :data="paginationData"
    />
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import {
  PencilIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/outline";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import Paginator from "@/components/common/Paginator";
import apiMixin from "@/mixins/apiMixin";
import formMixin from "@/mixins/formMixin";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import sortingTableMixin from "@/mixins/sortingTableMixin";

export default {
  name: "DiscountTable",
  mixins: [apiMixin, formMixin, sortingTableMixin],
  components: {
    Paginator,
    PencilIcon,
    TrashIcon,
    DocumentDuplicateIcon,
    FilterableHeaderCell,
  },
  beforeMount() {
    this.fetchDiscountCodes();
    eventBus.on("update-discount-codes", this.fetchDiscountCodes);
  },
  beforeUnmount() {
    eventBus.off("update-discount-codes", this.fetchDiscountCodes);
  },
  setup() {
    const paginationData = reactive({});
    const data = ref([]);
    const columns = ref([
      { name: "Discount Code", param: "name" },
      { name: "Value", param: "value" },
      { name: "Start Date", param: "start_date" },
      { name: "End Date", param: "end_date" },
      { name: "" },
    ]);
    const urlParams = reactive({ page: 1 });

    return { columns, paginationData, data, urlParams, itemRefs: [] };
  },
  methods: {
    openCreationModal() {
      eventBus.trigger("create-discount-code");
    },
    async fetchDiscountCodes() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchDiscountCodes(this.urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
      }
    },
    editDiscount(originalDiscount) {
      eventBus.trigger("edit-discount-code", {
        originalDiscount: {
          ...originalDiscount,
          start_date: new Date(originalDiscount.start_date),
          end_date: new Date(originalDiscount.end_date),
        },
      });
    },
    convert(date){
const dateObj = new Date(date);
const normalDate = dateObj.toISOString().split('.')[0]
    .replace('T', ' ')
    .replace('Z', '');
return normalDate;
    },
    async onDeleteDiscount(id) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title:
          "Are you sure you want to permanently delete this discount code?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.handleSubmissionErrors(
            () => this.$alfredService.deleteDiscountCode(id),
            { throwExceptions: true }
          );
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Discount code successfully deleted!",
            },
          });
          await this.fetchDiscountCodes();
        },
      });
    },
    async onPageChange(page) {
      this.urlParams.page = page;
      await this.fetchDiscountCodes();
    },
    applyOrdering(ordering) {
      this.urlParams.ordering = ordering;
    },
  },
};
</script>
<style scoped>
.button-cell {
  max-width: 90px;
}
</style>
