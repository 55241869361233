<template>
  <DialogContainer
    :isOpen="isOpen"
    :onClose="onClose"
    :maxWidth="'md'"
    :hasCloseBtn="false"
  >
    <template v-slot:title
      >{{ originalDiscount ? "Edit" : "Create New" }} Discount Code
    </template>
    <template v-slot:content>
      <div class="mt-5 p-1">
        <label for="discount-code"> Discount Code </label>
        <input
          id="discount-code"
          class="form-field-no-icon sm:w-full outline-none"
          v-model="discount.name"
          placeholder="Discount Code"
          @input="formActions.clearFieldError('name')"
        />
        <p v-show="errors.name" class="text-red-500 text-sm mt-1">
          {{ errors.name }}
        </p>
      </div>
      <div class="mt-3 p-1">
        <label for="value"> Value </label>
        <div
          :class="`form-field-no-icon sm:w-full align-center ${
            focused ? 'focused' : ''
          }`"
        >
          <input
            id="value"
            type="number"
            min="1"
            max="100"
            v-model="discount.value"
            placeholder="Value"
            class="w-full p-0 border-none focus:outline-none placeholder-gray-400 sm:text-sm"
            @focusin="
              () => {
                focused = true;
              }
            "
            @focusout="focused = false"
            @input="formActions.clearFieldError('value')"
            style="box-shadow: none"
          />
          <span class="pl-1">%</span>
        </div>
        <p v-show="errors.value" class="text-red-500 text-sm mt-1">
          {{ errors.value }}
        </p>
      </div>
      <DateField
        v-model="discount.start_date"
        :errors="errors.start_date"
        :label="'Start Date'"
        @change="
          formActions.clearFieldError('start_date') ||
            formActions.clearFieldError('name')
        "
      />
      <DateField
        v-model="discount.end_date"
        :errors="errors.end_date"
        :label="'End Date'"
        @change="
          formActions.clearFieldError('end_date') ||
            formActions.clearFieldError('name')
        "
      />
    </template>
    <template v-slot:buttons>
      <button type="button" class="button-cancel ml-0 sm:ml-3" @click="onClose">
        Cancel
      </button>
      <button
        @click="onSubmit"
        :disabled="!isModified() || hasErrors"
        class="button-submit"
      >
        {{ originalDiscount ? "Submit" : "Add" }}
      </button>
    </template>
  </DialogContainer>
</template>
<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import { cloneDeep, isEqual } from "lodash";
import formMixin from "@/mixins/formMixin";
import useFormErrors from "@/api/formErrors";
import DateField from "@/components/admin/discount-codes-page/DateField";
import eventBus from "@/utils/eventBus";

export default {
  name: "DiscountCodeModal",
  mixins: [formMixin],
  components: {
    DialogContainer,
    DateField,
  },
  beforeMount() {
    eventBus.on(this.modalCreationEventName, this.openCreationModal);
    eventBus.on(this.modalEditionEventName, this.openEditionModal);
  },
  beforeUnmount() {
    eventBus.off(this.modalCreationEventName, this.openCreationModal);
    eventBus.off(this.modalEditionEventName, this.openEditionModal);
  },
  setup() {
    const isOpen = ref(false);
    const modalCreationEventName = "create-discount-code";
    const modalEditionEventName = "edit-discount-code";

    const { errors, formActions } = useFormErrors();

    const originalDiscount = ref(null);
    const discount = reactive({
      name: null,
      value: null,
      startDate: null,
      endDate: null,
    });
    const focused = ref(false);

    return {
      isOpen,
      errors,
      formActions,
      modalCreationEventName,
      modalEditionEventName,
      discount,
      originalDiscount,
      focused,
    };
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.formActions.clearFormErrors();
      }
    },
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length;
    },
  },
  methods: {
    openCreationModal() {
      this.originalDiscount = null;
      this.discount = {
        name: null,
        value: null,
        start_date: null,
        end_date: null,
      };
      this.isOpen = true;
    },
    openEditionModal({ originalDiscount }) {
      originalDiscount = {
        ...originalDiscount,
        value: originalDiscount.value.toString(),
      };
      this.originalDiscount = reactive(originalDiscount);
      this.discount = reactive(cloneDeep(originalDiscount));
      this.isOpen = true;
    },
    async onSubmit(e) {
      e.preventDefault();
      if (this.hasErrors) {
        return;
      }
      if (!this.originalDiscount) {
        const response = await this.handleSubmissionErrors(
          async () =>
            await this.$alfredService.createDiscountCode({
              name: this.discount.name,
              value: this.discount.value,
              start_date: this.discount.start_date,
              end_date: this.discount.end_date,
            }),
          { formActions: this.formActions }
        );
        if (response) {
          this.isOpen = false;
          eventBus.trigger("update-discount-codes");
        }
      } else {
        const response = await this.handleSubmissionErrors(
          async () =>
            await this.$alfredService.changeDiscountCode(
              this.originalDiscount.id,
              {
                name: this.discount.name,
                value: this.discount.value,
                start_date: this.discount.start_date,
                end_date: this.discount.end_date,
              }
            ),
          { formActions: this.formActions }
        );
        if (response) {
          this.isOpen = false;
          eventBus.trigger("update-discount-codes");
        }
      }
    },
    onClose() {
      this.isOpen = false;
    },
    isModified() {
      return (
        !this.originalDiscount || !isEqual(this.discount, this.originalDiscount)
      );
    },
  },
};
</script>
<style>
input {
  box-shadow: none;
}
</style>
