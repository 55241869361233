<template>
  <Container>
    <template v-slot:header>Bouncer</template>
    <template v-slot:content>
      <div class="tab bg-white shadow rounded-t-md p-8 pt-4 hidden-print">
        <nav class="float-left">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            :class="[
              tab.current
                ? 'current'
                : 'border-transparent hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap mr-5 pb-2 px-1 border-b-2 text-md cursor-pointer',
            ]"
            @click="changeTab(tab.name)"
          >
            {{ tab.name }}
          </a>
        </nav>
      </div>

      <section
        v-if="activeTab === 'Current Jobs'"
        class="flex flex-row-reverse"
      >
        <div class="mb-6 mt-6">
          <SearchInput
            v-model="searchKeyword"
            @input="onSearchInput"
            v-on:keyup="onSearchInput"
          />
        </div>
      </section>

      <div v-show="activeTab === 'Current Jobs'">
        <div class="align-middle bg-white inline-block w-full">
          <table class="divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  v-for="columnName in columnNames"
                  :key="columnName.name"
                  scope="col"
                  :class="`
                px-6
                py-5
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              `"
                >
                  <FilterableHeaderCell
                    :title="columnName.name"
                    :param="columnName.param"
                    @switchOrder="onSwitchOrders(columnName.param)"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="data?.length">
                <tr v-for="(row, index) in data" :key="index">
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ row.created_by }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ row.input_file_name }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ row.emails_count }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ serviceMappings[formatString(row.actions)] }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{
                      row?.created_at?.length === 10
                        ? new Date(row.created_at).toLocaleDateString("en-US")
                        : new Date(row.created_at).toLocaleString("en-US")
                    }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ row.bouncer_time }}
                  </td>
                  <td class="px-6 py-5 text-sm text-gray-500">
                    {{ row.total_time }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <div class="px-6 py-5 text-sm text-gray-500 text-left">
                  No data
                </div>
              </template>
            </tbody>
          </table>
          <nav
            class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
            aria-label="Pagination"
          >
            <div class="flex-1 py-4"></div>
            <div class="hidden sm:block mr-4">
              <p class="text-sm text-right text-gray-700">
                {{ " " }}
                <span class="font-medium">{{ currentPage }}</span>
                {{ " " }}
                of
                {{ " " }}
                <span class="font-medium">{{ totalPages }}</span>
                {{ " " }}
              </p>
            </div>
            <div class="flex justify-between sm:justify-end">
              <button
                @click="goToPage(currentPage - 1)"
                :disabled="currentPage === 1"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <button
                @click="goToPage(currentPage + 1)"
                :disabled="currentPage === totalPages"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div></div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import apiMixin from "@/mixins/apiMixin";
import { ref } from "vue";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import SearchInput from "@/components/common/SearchInput";

export default {
  name: "Bouncer",
  mixins: [apiMixin],
  components: {
    Container,
    FilterableHeaderCell,
    SearchInput,
  },
  data() {
    return {
      columnNames: ref([
        { name: "Created by", param: "created_by__email" },
        { name: "File Name", param: "input_file__name" },
        { name: "Email Count", param: "emails_count" },
        { name: "Service", param: "actions" },
        { name: "Created at", param: "created_at" },
        { name: "Bouncer Time", param: "bouncer_time" },
        { name: "Total time", param: "total_time" },
      ]),
      data: ref([]),
      payload: {
        page: 1,
        size: 15,
        ordering: "-created_at",
        search: "",
      },
      currentPage: 1,
      totalPages: 1,
      searchKeyword: "",
      tabs: [{ name: "Current Jobs", current: true }],
      activeTab: "Current Jobs",
      serviceMappings : {
                evaluation: "Evaluation",
                threat_evaluation: "Threat Evaluation",
                verification: "Deliverability + Threat Detection",
                deliverability: "Deliverability Check",
                threat_assessment: "Threat Detection",
                real_time: "Real Time"
                }
    };
  },
  mounted() {
    this.fetchBouncer();
  },
  methods: {
    changeTab(tabName) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === tabName;
      });
      this.activeTab = tabName;
    },
    onSearchInput() {
      this.payload.search = this.searchKeyword;
      this.fetchBouncer();
    },
    async onSwitchOrders(name) {
      let order = this.payload.ordering == name ? `-${name}` : name;
      this.payload.ordering = order;
      this.payload.page = 1;
      this.fetchBouncer();
    },
    async fetchBouncer() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.Bouncer(this.payload),
      });
      if (response) {
        this.data = response.results;
        this.totalPages = Math.ceil(response.count / this.payload.size);
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.payload.page = pageNumber;
        this.fetchBouncer();
        this.currentPage = pageNumber;
      }
    },
    formatString(str) {
      if (str) {
        return str
          .replace(/['[\]]/g, "")
      }
    },
  },
};
</script>

<style scoped>
.current {
  border-color: var(--main-color);
  color: var(--main-color);
}
select {
  background-image: none;
  border-color: #ffffff00;
}
</style>
