<template>
    <div class="min-h-screen bg-white" v-if="pageLoad && !invalidPartner">
      <div class="flex relative bg-white shadow justify-center sm:justify-start px-3 py-3">
        <router-link to="/">
          <img
            class="h-10"
            :src="require('@/assets/images/logo.png')"
            alt="Alfred Knows"
          />
        </router-link>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center lg:text-left tailwind-css-content-block">
      <div class="flex items-center flex-wrap space-y-10 bg-white p-10 pt-2">
<div class="flex-1">
<h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">Free Email Hygiene Credits, Courtesy of<span class="text-rose-100">&nbsp;<span class="text-red-400">{{account_name}}</span>&nbsp;&nbsp;</span></h1>
<p class="pt-5">{{ account_name }} customers get <strong>{{ humanizeNumber(credits) }} free credits </strong>to use Alfred, the only email hygiene service trusted by the world’s most respected organizations.&nbsp;</p>
</div>
<div><div class="eds-animate animated fadeIn" data-eds-entry-animation="fadeIn" data-eds-entry-delay="0" data-eds-entry-duration="1" data-eds-entry-timing="linear" data-eds-exit-animation="" data-eds-exit-delay="" data-eds-exit-duration="" data-eds-exit-timing="" data-eds-repeat-count="1" data-eds-keep="yes" data-eds-animate-on="load" data-eds-scroll-offset="" style="animation-duration: 1s !important; animation-delay: 0s !important; animation-iteration-count: 1 !important; animation-timing-function: linear !important;"><img fetchpriority="high" decoding="async" class="alignnone wp-image-1386 size-large" src="https://alfred.email/wp-content/uploads/2021/09/clean2-1024x768.jpg" alt="" width="580" height="435" srcset="https://alfred.email/wp-content/uploads/2021/09/clean2-1024x768.jpg 1024w, https://alfred.email/wp-content/uploads/2021/09/clean2-300x225.jpg 300w, https://alfred.email/wp-content/uploads/2021/09/clean2-768x576.jpg 768w, https://alfred.email/wp-content/uploads/2021/09/clean2-1536x1152.jpg 1536w, https://alfred.email/wp-content/uploads/2021/09/clean2-2048x1536.jpg 2048w, https://alfred.email/wp-content/uploads/2021/09/clean2-1200x900.jpg 1200w, https://alfred.email/wp-content/uploads/2021/09/clean2-1980x1485.jpg 1980w" sizes="(max-width: 580px) 100vw, 580px"></div></div>
</div>

<div class="relative bg-white">
<div class="absolute inset-0">
<div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50">&nbsp;</div>
</div>
<div class="relative max-w-7xl lg:grid lg:grid-cols-5">
<div class="bg-gray-50 py-12 px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
<div class="max-w-lg mx-auto">
<h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Questions?</h2>
<div class="mt-8 text-base text-gray-500">
<div>
<div class="sr-only">Postal address</div>
</div>
<div class="mt-6">
<div class="sr-only"></div>
<div class="flex"><span class="material-icons md-18"><PhoneIcon class="h-5"/></span><span id="contact-phone" class="ml-3">+1 (706) 426-1970</span></div>
</div>
<div class="mt-3">
<div class="sr-only"></div>
<div class="flex"><span class="material-icons mt-1"><MailIcon class="h-5"/></span><a :href="'mailto:alfred@emailindustries.com?subject=Question about '+account_name"><span id="contact-email" class="ml-3">alfred@emailindustries.com</span></a></div>
</div>
</div>
</div>
</div>
<div class="bg-white py-12 px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12">
<h2 class="text-2xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Register here 👇</h2>
<div id="contact-chat" class="max-w-lg mx-auto lg:max-w-none">{{humanizeNumber(credits)}} free credits courtesy of our friends at {{ account_name }}.</div>
<div></div>

<Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6 pt-3">

            <div>
              <label for="name"> Name </label>
              <div class="mt-1">
                <Field
                  id="name"
                  name="name"
                  type="text"
                  v-model="name"
                  class="form-field-no-icon w-full"
                />
              </div>
            </div>

            <div>
              <label for="email"> Email address </label>
              <div class="mt-1">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  v-model="email"
                  autocomplete="email"
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
              </div>
            </div>

            <div>
              <label for="password"> Password </label>
              <div class="mt-1">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  v-model="password"
                  autocomplete="current-password"
                  required=""
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
              </div>
            </div>

            <div>
              <label for="password"> Confirm Password </label>
              <div class="mt-1">
                <Field
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  v-model="confirmPassword"
                  autocomplete="current-password"
                  required=""
                  class="form-field-no-icon w-full"
                />
                <p class="text-red-500 text-sm mt-1">
                  {{ errors.confirm_password }}
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Field
                  name="agree_to_terms"
                  type="checkbox"
                  v-model="agreeToTerms"
                >
                  <input
                    ref="agreeRef"
                    id="agree_to_terms"
                    name="agree_to_terms"
                    type="checkbox"
                    v-model="agreeToTerms"
                    class="h-4 w-4 text-red-900 border-gray-300 rounded"
                  />
                  <label
                    for="agree"
                    class="ml-2"
                    v-bind:class="
                      errors.agree_to_terms
                        ? 'text-red-500 font-medium'
                        : 'text-gray-900'
                    "
                  >
                    <span @click="() => $refs.agreeRef.click()">
                      I agree and accept the
                    </span>
                    <a
                      href="https://alfredknows.com/company/legal/"
                      class="text-gray-500 hover:text-gray-900 cursor-pointer"
                    >
                      Terms of Use and Privacy Policy
                    </a>
                  </label>
                </Field>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Field
                  name="subscribed_to_newsletter"
                  type="checkbox"
                  v-model="subscribedToNewsletter"
                >
                  <input
                    ref="agreeSub"
                    id="subscribed_to_newsletter"
                    name="subscribed_to_newsletter"
                    type="checkbox"
                    v-model="subscribedToNewsletter"
                    class="h-4 w-4 text-red-900 border-gray-300 rounded"
                  />
                    <label
                        class="ml-2"
                        v-bind:class="text-gray-900"
                    >
                        <span @click="() => $refs.agreeSub.click()">
                          I would like to subscribe to your email newsletter!
                        </span>
                    </label>
                </Field>
              </div>
            </div>

            <div>
              <button type="submit" :disabled="isLoading" class="button-submit w-1/2 m-0" @click="submit">
                <span v-if="isLoading">
                        <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>                        
                    </span>
                    Create Free Account
              </button>
            </div>

            <div class="flex items-center justify-between">
            </div>
          </Form>
    </div>
    </div>
    </div>
    </div>
  </div> 
  <div class="flex flex-col justify-between h-screen"  v-else-if="!pageLoad && !invalidPartner">
      <div
        class="flex flex-col flex-grow"
      >
        <div
          class="flex flex-grow justify-center items-center"
        >
          <PulseLoader color="#ff5a5f" />
        </div>
        <div v-show="!showSpinner">
          <router-view />
        </div>
      </div>
    </div>
    <div v-else>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { Field, Form } from "vee-validate";
  import formMixin from "@/mixins/formMixin";
  import eventBus from "@/utils/eventBus";
  import { MailIcon, PhoneIcon } from "@heroicons/vue/solid";
  import PulseLoader from "vue-spinner/src/PulseLoader";

  export default {
    name: "PartnerRegistration",
    components: {
      Field,
      Form,
      MailIcon,
      PhoneIcon,
      PulseLoader
    },
    mixins: [formMixin],
    setup() {
      return {
        partner_code: ref(""),
        user: ref(""),
        account_name: ref(""),
        credits: ref(""),
        tapfiliate_code: ref(""),
        pricing: ref([]),
        agreeToTerms: ref(false),
        subscribedToNewsletter: ref(false),
      success: ref(false),
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      pageLoad: ref(false),
      invalidPartner: ref(false)
      };
    },
    mounted (){
      const element = this.$refs.navbar;
      if (element) {
      element.classList.add('navbar hidden');
    }
    window.onload = () => {
      this.getURLParams();
    };
      
    },
    methods: {
      async getURLParams() {
        const urlParams = new URLSearchParams(window.location.search);
        this.partner_code = urlParams.get("partner");
        if(this.partner_code){
        try{
        const resp = await this.$alfredService.partnerSettingsRegGet(this.partner_code);
        if (resp && resp.length) {    
          this.account_name = resp[0].account_name;
          this.credits = resp[0].credits;
          this.user = resp[0].user.email;
          this.pageLoad = true;
      }else{
        this.pageLoad = true;
        this.invalidPartner = true;
      } 
      }catch(e){
        console.log(e, "error");
      } 
        }
      },
      humanizeNumber(number) {
      if (number){
        var parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        var formatted = Math.floor(parsedNumber).toString();
        return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
          return number;
      }
      }
    },
      async onSubmit(values, actions) {
      values.partner_code = this.partner_code;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.register(values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully registered! Now you need to confirm your email address.",
            type: "success",
          },
        });
        this.success = true;
        this.name = "";
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.tapfiliate_referral_code = "";
        this.agreeToTerms = false;
        this.subscribedToNewsletter = true;
        this.isLoading = false;
        await this.$router.push({ name: "RegistrationConfirmation" });
      } else {
        this.isLoading = false;
        this.success = false;
      }
    },
    async submit(){
      this.isLoading = true;
    },
    showLegalModal() {
      eventBus.trigger("show-legal");
    },
    },
  };
  </script>
  