<template>
  <div>
    <div v-if="isGenerating" class="sm:flex sm:items-start justify-center">
      <PulseLoader color="#ff5a5f" style="position: relative; top: -30rem" />
    </div>
    <div>
      <div
        class="sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md canvas_div_pdf"
        id="content"
        ref="content"
        v-show="isGenerating"
      >
        <div class="main">
          <div class="flex justify-between items-start py-6">
            <div class="text-left">
              <p class="font-bold text-sm pb-2">Email Industries</p>
              <p class="text-xs pb-1">3540 Wheeler Rd Ste 604</p>
              <p class="text-xs pb-1">Augusta, GA 30909</p>
              <p class="text-xs pb-1">+1 7064261970</p>
              <p class="text-xs pb-1">
                <a
                  target="_blank"
                  href="mailto:accounting@emailindustries.com"
                  >accounting@emailindustries.com</a
                >
              </p>
              <p class="text-xs">
                <a
                  target="_blank"
                  href="https://www.emailindustries.com"
                  >www.emailindustries.com</a
                >
              </p>
            </div>
            <div>
              <img
                :src="require('@/assets/images/receipt_logo.png')"
                alt="Logo"
                class="h-32 w-auto"
              />
            </div>
          </div>

          <div class="flex justify-between items-start py-6">
            <div class="text-left">
              <p class="font-bold text-lg">SALES RECEIPT</p>
              <span class="whitespace-nowrap text-sm font-medium text-gray-500"
                >BILL TO</span
              >
              <p class="text-sm">{{ name }}</p>
              <p v-if="line_2" class="text-sm">{{ line_1 }}, {{ line_2 }}</p>
              <p v-else class="text-sm">{{ line_1 }}</p>
              <p v-if="state" class="text-sm">{{ city }}, {{ state }}</p>
              <p v-else class="text-sm">{{ city }}</p>
              <p class="text-sm">{{ postal_code }}, {{ country }}</p>
              <p class="text-sm">{{ VAT_type }} {{ VAT_value }}</p>
            </div>
            <div class="pr-10">
              <span class="whitespace-nowrap text-sm font-medium text-gray-500"
                >DATE</span
              ><span>&nbsp;&nbsp;{{ transaction.created_at }}</span>
            </div>
          </div>

          <table
            class="divide-gray-300 w-full text-left table-auto border-b-2 border-dashed border-sky-500"
          >
            <thead class="bg-gray-300">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 pb-5 text-left text-xs font-medium uppercase tracking-wider"
                >
                  Activity
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 pb-5 text-left text-xs font-medium uppercase tracking-wider"
                >
                  Description
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 pb-5 text-left text-xs font-medium uppercase tracking-wider"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 pb-5 text-left text-xs font-medium uppercase tracking-wider"
                >
                  Rate
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 pb-5 text-left text-xs font-medium uppercase tracking-wider"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="px-6 py-3 whitespace-nowrap text-sm font-medium"
                >
                List Hygiene
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm">
                  Alfred List Hygiene <br />
                  {{ transaction.credits }} <br />
                  {{ formatDate(transaction.created_at) }}
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm">
                  1
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm">
                  {{ humanizeNumber(transaction.pack_price) }}
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm">
                  {{ humanizeNumber(transaction.pack_price) }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex justify-between items-start py-6">
            <div class="text-left leading-5">
              <span class="text-gray-500 text-xs">ACH INFO:</span><br />
              <span class="text-gray-500 text-xs">Acct. name: Indiemark LLC</span><br />
              <span class="text-gray-500 text-xs">Bank  name: First Citizens Bank</span><br />
              <span class="text-gray-500 text-xs">Acct.  #: 90004733301</span><br />
              <span class="text-gray-500 text-xs">Routing. #: 053906041</span><br /><br />

              <span class="text-gray-500 text-xs">TAX ID:</span><br />
              <span class="text-gray-500 text-xs">22-3980285</span
              ><br /><br />
              <span class="text-gray-500 text-xs">W-9:</span><br />
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1UMTvpihoq7LOP5uffpCKQiXnza2FjQpN/view?
usp=share_link"
                class="text-gray-500 underline w-2/4 absolute text-xs"
                >https://drive.google.com/file/d/1UMTvpihoq7LOP5uffpCKQiXnza2FjQpN/view?usp=share_link</a
              >
            </div>
            <div class="text-left">
              <div class="flex justify-between items-start py-8">
                <div class="text-left">
                  <p
                    class="font-semibold table-auto border-b-2 border-dashed border-sky-500 pb-4 text-sm"
                  >
                    TOTAL
                  </p>
                  <p class="font-semibold pt-6 pr-20 text-sm">BALANCE DUE</p>
                </div>
                <div class="text-right">
                  <p class="border-b-2 border-dashed border-sky-500 pb-4 text-sm">
                    {{ humanizeNumber(transaction.pack_price) }}
                  </p>
                  <p class="font-bold pt-4 text-lg">USD 0.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import PulseLoader from "vue-spinner/src/PulseLoader";
import eventBus from "@/utils/eventBus";
import countryCodes from "@/constants/countryCodes.json";

export default {
  name: "BillingReceipt",
  components: {
    PulseLoader,
  },
  data() {
    return {
      isGenerating: false,
      fileName: "",
      name: "",
      line_1: "",
      line_2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
      VAT_type: "",
      VAT_value: "",
    };
  },
  props: {
    transaction: {
      type: Object,
    },
  },
  watch: {
    transaction() {
      this.loadStripeData();
    },
  },
  methods: {
     humanizeNumber(number) {
      if (number){
        var parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
          var formatted = parsedNumber.toFixed(2);
          return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');;
      } else {
          return number;
      }
      }
    },
    formatDate(dateString){
    if (dateString){
    var parts = dateString.split('/');
    var date = new Date(parts[2], parts[0] - 1, parts[1]);
    var formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    return formattedDate;
    }
    
    },
    loadStripeData() {
      const result = this.$alfredService.getBillingAddress();
      result
        .then((result) => {
          if (result) {
            this.name = result?.name;
            this.line_1 = result.address?.line1;
            this.line_2 = result.address?.line2;
            this.postal_code = result.address?.postal_code;
            this.city = result.address?.city;
            this.country = this.nameOf(result)
            this.VAT_type = result.vat_details.data[0]?.country;
            this.VAT_value = result.vat_details.data[0]?.value;
            this.fileDetails();
          }
        })
        .catch((error) => {
          console.error("Error occurred while resolving the Promise:", error);
          eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please add billing address",
          },
        }); 
          
        });
    },
    nameOf(result){
      const country = countryCodes.find(c => c.code === result.address?.country);
      if (country.states && result.address?.state) {
        const state = country.states.find(s => s.code === result.address?.state);
        if (state){
          this.state = state.name;
        }
      }
      return country.country;
    },
    async fileDetails() {
      if(!this.name){
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "warning",
            text: "Please add billing address",
          },
        });  
        return
      }
      this.isGenerating = true;
      this.$refs.content.removeAttribute("hidden");
      // this.fileName = this.$props.transaction.created_at;
      // const namePos = this.fileName.indexOf('.');
      const name = this.$props.transaction.created_at;

      setTimeout(async () => {
        const content = this.$refs.content;
        const opt = {
          margin: [0.5, 0.5, 0.5, 0.5],
          filename: name + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
        };

        try {
          await html2pdf().from(content).set(opt).save();
        } finally {
          this.isGenerating = false;
          this.$refs.content.setAttribute("hidden", "true");
        }
      }, 3000);
    },
  },
};
</script>

<style scoped>
.bg-gray-300 {
    --tw-bg-opacity: 1 !important;
    background-color: #CFCFCF;
}
</style>
