<template>
  <div class="grid grid-cols-2">
    <div class="pr-2">
      <div class="mt-5 p-1 text-left">
        <label for="discount-code"> Account Name </label>
        <input id="discount-code" class="form-field-no-icon sm:w-full outline-none" v-model="data.account_name" @input="
          formActions.clearFieldError(
            'account_name'
          )
          " @change="generateCode()" />
        <p class="text-red-500 text-sm mt-1">
          {{ errors["account_name"] }}
        </p>
      </div>
      <div class="mt-5 p-1 text-left">
        <label for="discount-code"> Credits </label>
        <NumberInput
          v-model="data.credits"
          @input="formActions.clearFieldError(
            'credits'
          )"
          required
        />
        <p class="text-red-500 text-sm mt-1">
          {{ errors["credits"] }}
        </p>
      </div>
      <div class="mt-5 p-1 text-left">
        <label for="partner-code">Partner Code</label>
        <input id="partner-code" class="form-field-no-icon sm:w-full outline-none" v-model="data.partner_code" @input="
          formActions.clearFieldError(
            'partner_code'
          )
          " />
        <p class="text-red-500 text-sm mt-1">
          {{ cleanError(errors["partner_code"]) }}
        </p>
      </div>
      <div class="mt-5 p-1 text-left">
        <label for="partner-code">Tapfiliate Code</label>
        <input id="partner-code" class="form-field-no-icon sm:w-full outline-none" v-model="data.tapfiliate_code" />
      </div>

      <button type="submit" class="button-submit" @click="onSubmit()">Submit</button>
      <br /><br />
      <div class="text-left" v-show="showCode">
        <span class="text-md">Copy below link and share with your partner</span>
       <div class="flex">
        <td class="px-6 py-5 text-sm text-gray-500 text-left border-dashed border-2 border-red-500 rounded-md">
          <div class="flex"> {{  baseUrl + "partner-register/?partner=" + data.partner_code }}
          <button v-clipboard="baseUrl + 'partner-register/?partner=' + data.partner_code"
            v-clipboard:success="clipboardSuccessHandler" class="button-icon">
            <DocumentDuplicateIcon class="h-6 inline" />
          </button>
        </div>
        </td>
        <div class="content-center pl-5">
          <button
        @click="removePartnerSetting()"
        ><TrashIcon class="h-6 content-center" /></button>

        </div>
        </div>
      </div>
    </div>

    <div class="pl-2">
      <div class="text-left flex mt-5 p-1 text-left">
        <div class="ml-3">
          <input class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300 rounded" type="checkbox"
            v-model="enablePricing" />
        </div>
        <p class="pl-3">Enable custom pricing</p>
      </div>

      <div class="flex flex-col items-start" v-if="enablePricing">
        <!--pricing-->
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="mt-5">
              <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="divide-gray-300 table-fixed w-full">
                  <thead class="border-b-2">
                    <tr>
                      <th scope="col"
                        class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        From
                      </th>
                      <th scope="col"
                        class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        To
                      </th>
                      <th scope="col"
                        class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Price per Credit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pricingRange, rangeIdx) in schema" :key="pricingRange.id" class="border-b-2">
                      <td class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900">
                        <NumberInput v-model="pricingRange.from" :disabled="true" />
                      </td>
                      <td class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900">
                        <NumberInput v-model="pricingRange.to" @input="onRowInput(rangeIdx)" :classes="errors[rangeIdx]?.to ? errorClasses : defaultClasses
                          " />
                      </td>
                      <td class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900 price">
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm"> $ </span>
                          </div>
                          <div class="pl-7">
                            <NumberInput v-model="pricingRange.price" :decimals="6" @input="onRowInput(rangeIdx)"
                              :classes="`form-field-no-icon w-full
                        ${errors[rangeIdx]?.price ? errorClasses : ''}`" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--end of pricing-->

      </div>
    </div>
  </div>

</template>

<script>
import { computed, ref, reactive } from "vue";
import { mapGetters } from "vuex";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import { DocumentDuplicateIcon, TrashIcon } from "@heroicons/vue/outline";
import useFormErrors from "@/api/formErrors";
import { required } from "@/utils/validators";

export default {
  name: "PartnerSettings",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },
  components: {
    NumberInput,
    DocumentDuplicateIcon,
    TrashIcon
  },
  setup() {
    const errorClasses =
      " form-field-no-icon w-full border-red-300 bg-red-50 focus:ring-red-500 focus:border-red-500";
    const schema = ref([]);
    const { errors, formActions } = useFormErrors();
    const data = reactive({
      account_name: "",
      credits: "",
      partner_code: "",
      tapfiliate_code: "",
      pricing: []
    })
    return {
      schema,
      errorClasses,
      errors,
      formActions,
      enablePricing: ref(false),
      data,
      showCode: ref(false),
      baseUrl: ""
    };
  },
  async beforeMount() {
    await this.getPricingSchema();
    await this.convertSchema();
    if (this.userId) {
      await this.$store.dispatch("user/FETCH_USER", { userId: this.userId });
    }
    this.getPartnerPricing();
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const baseUrl = `${url.protocol}//${url.hostname}/`;
    this.baseUrl = baseUrl;
  },
  computed: {
    isLastRowEmpty() {
      if (!this.schema.length) {
        return false;
      }
      const lastRow = this.schema[this.schema.length - 1];
      return !lastRow.price && !lastRow.to;
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    ...mapGetters({ user: "user/getUser" }),
  },
  methods: {
    validate() {
      const validators = {
        "account_name": [required],
        "credits": [required],
        "partner_code": [required]
      };

      this.formActions.validateForm(this.data, validators);

      return !Object.keys(this.errors).length;
    },
    generateCode() {
      this.data.partner_code = this.data.account_name.toLowerCase().replace(/\s/g, '');
      this.data.credits = 250;
    },
    validatePartnerCode() {
      var regex = /^[a-zA-Z0-9]+$/;
      return regex.test(this.data.partner_code);
    },
    clipboardSuccessHandler(){
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          type: "success",
          text: "Partner link copied!",
        },
      });
    },
    async removePartnerSetting(){
      const modal = useConfirmationModal();
      await modal.openModal({
        title:
          "Are you sure you want to permanently delete this partner setting?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.handleSubmissionErrors(
            () => this.$alfredService.partnerSettingsDelete(this.userId),
            { throwExceptions: true }
          );
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Partner setting successfully deleted!",
            },
          });
          this.clearFields();
        },
      });
    },
    clearFields(){
      this.data ={
      account_name: "",
      credits: "",
      partner_code: "",
      tapfiliate_code: "",
      pricing: []
      }
      this.enablePricing = false;
      this.showCode = false;
    },
    cleanError(message) {
      if (message) {
        let cleanedMessage = String(message);
        cleanedMessage = cleanedMessage.replace(/^\[?"|"?\]$/g, '');
        return cleanedMessage;
      }
      return ''; 
    },
    async getPartnerPricing() {
      const resp = await this.$alfredService.partnerSettingsGet(this.userId);
      if (resp) {
        this.data.account_name = resp[0].account_name;
        this.data.credits = resp[0].credits;
        this.data.partner_code = resp[0].partner_code;
        if (resp[0].tapfiliate_code) {
          this.data.tapfiliate_code = resp[0].tapfiliate_code;
        }
        if (resp[0].pricing) {
          this.enablePricing = true;
          const pricingSchema = resp[0].pricing;
          this.errors = Object.assign(this.errors, {});
          this.setSchema(pricingSchema);
          this.$forceUpdate();
        }
        this.showCode = true;
      }
    },
    async getPricingSchema() {
      const { value } = await this.getPricing();
      const pricingSchema = value || [];
      this.errors = Object.assign(this.errors, {});
      this.setSchema(pricingSchema);
      this.$forceUpdate();
    },
    async getPricing() {
      try {
        return await this.getPricingRequest({ user: this.userId || null });
      } catch (_) {
        const pricing = await this.$alfredService.getPricing();
        return { value: pricing.pricing_table };
      }
    },
    async getPricingRequest(payload) {
      return await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getSetting("PRICING", payload),
        handleErrors: false,
      });
    },
    async getPartnerSettings() {

    },
    setSchema(pricingSchema) {
      this.schema = pricingSchema.reduce(
        (previousValue, currentValue, index, array) => {
          const nextIndex = index + 1;
          previousValue.push({
            from: currentValue["from"] + 1,
            to: array[nextIndex] ? array[nextIndex]["from"] : null,
            price: currentValue["price"],
            id: index,
          });
          return previousValue;
        },
        []
      );
      this.schema.forEach((row, rowIndex) => {
        const previousLine = this.schema[rowIndex - 1];
        row.from = previousLine ? computed(() => previousLine?.to + 1) : 1;
      });
      if (this.schema.length === 0) {
        this.addNewLine();
      } else {
        this.validateAll();
      }
    },
    async onSubmit() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        message: "",
        onSubmit: () => this.handleChangeSetting(),
      });
    },
    async handleChangeSetting() {
      if (!this.validate()) {
        return;
      }
      if (!this.validatePartnerCode()) {
        this.errors["partner_code"] = "Invalid code. Please try with alphabets and numbers only."
        return;
      }
      let schema = this.schema.map((row) => {
        return {
          from: row["from"] - 1,
          price: row["price"],
        };
      });
      if (!this.enablePricing) {
        schema = null;
      }
      try {
        const response = await this.$alfredService.partnerSettingsCreate({
          account_name: this.data.account_name,
          credits: this.data.credits,
          pricing: schema,
          partner_code: this.data.partner_code,
          tapfiliate_code: this.data.tapfiliate_code,
          user: {
            id: this.userId
          },
        });

        if (response) {
          const pricingSchema = response?.pricing || [];
          this.setSchema(pricingSchema);
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Partner settings successfully updated!",
            },
          });
          this.showCode = true;
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          Object.keys(error.response.data).forEach(field => {
            this.errors[field] = error.response.data[field]
          });

        } else {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "error",
              text: "Try again later!",
            },
          });
        }
      }
    },
    onRowInput(rowIndex) {
      this.$nextTick(() => {
        this.validateRow(this.schema[rowIndex], rowIndex);
        let index = rowIndex + 1;
        while (index >= 0) {
          if (!this.schema[index]) {
            index -= 1;
            continue;
          }
          const currentIndex = index;
          this.validateRow(this.schema[currentIndex], currentIndex);
          index -= 1;
        }
      });
    },
    validateAll() {
      this.schema.forEach((row, rowIndex) => {
        this.validateRow(row, rowIndex);
      });
    },
    validateRow(row, rowIndex) {
      if (!row) {
        return;
      }
      const isLastRow = rowIndex === this.schema.length - 1;

      if (isLastRow && this.isLastRowEmpty) {
        const previousRow = this.schema[rowIndex - 1];
        if (previousRow && !previousRow.to) {
          this.deleteRow(row.id);
          return;
        }
      }

      const errors = this.getRowErrors(row, rowIndex);
      const hasErrors = Object.values(errors).reduce(
        (prevValue, currentValue) => {
          return prevValue || currentValue;
        },
        false
      );
      if (hasErrors) {
        Object.assign(this.errors, this.errors, {
          [rowIndex]: errors,
        });
      } else {
        delete this.errors[rowIndex];
      }
      const isLastLine = rowIndex === this.schema.length - 1;
      if (isLastLine && !hasErrors && row.to) {
        this.addNewLine();
      }
    },
    deleteRow(rowId) {
      let index = 0;
      for (let row of this.schema) {
        if (row.id === rowId) {
          this.schema.splice(index, index);
          delete this.errors[index];
          break;
        }
        index += 1;
      }
      this.validateAll();
    },
    getRowErrors(row, rowIndex) {
      const isLastRow = rowIndex === this.schema.length - 1;
      const isPreLastRow = rowIndex === this.schema.length - 2;
      const isEmpty = !row.price && !row.to;
      const isLastNonEmptyRow =
        ((this.isLastRowEmpty && isPreLastRow) || isLastRow) && !isEmpty;

      const errors = {};
      if (!row.price) {
        errors["price"] = true;
      }
      if (!row.to && !isLastNonEmptyRow) {
        errors["to"] = true;
      }
      if (row.to && row.to < row.from) {
        errors["to"] = true;
      }
      return errors;
    },
    addNewLine() {
      const index = this.schema.length ? this.schema.length + 1 : 0;
      const previousLine = this.schema[this.schema.length - 1];
      this.schema.push({
        from: previousLine ? computed(() => previousLine?.to + 1) : 1,
        to: null,
        price: null,
        id: index,
      });
      this.validateRow(this.schema[index], index);
    },
    convertSchema() { },
  },
};
</script>
