<template>
  <div>
    <div class="mb-6 mt-6">
      <SearchInput
        v-model="searchKeyword"
        @input="onSearchInput"
        v-on:keyup="onSearchInput"
      />
    </div>
    <div class="overflow-x-auto bg-white shadow rounded-md mb-6">
      <div class="align-middle inline-block w-full">
        <div class="sm:rounded-lg">
          <table class="divide-gray-300 w-full text-left table-auto">
            <thead class="border-b-2">
              <tr>
                <th
                  @click="
                    fetchEmailCheckList(
                      this.$props.id,
                      this.urlParams,
                      (name = 'Email')
                    )
                  "
                  scope="col"
                  class="flex px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  <a class="inline-flex hover:text-gray-900 cursor-pointer">
                    Email
                    <span class="my-auto"> <SelectorIcon class="h-4" /> </span
                  ></a>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  Score (OUT OF 10)
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  Provider
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  Recommendation
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase"
                >
                  Deliverability Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider uppercase w-1/6"
                >
                  Threat Level
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in resultsData" :key="item.id" class="row">
                <td class="px-6 py-5 text-sm text-gray-500 w-1/4">
                  {{ item.email }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ item.score }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ item.provider }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ item.recommendation }}
                </td>
                <td
                  class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/6"
                >
                  {{ item.status }}
                </td>
                <td
                  class="px-6 py-5 whitespace-nowrap text-sm text-gray-500 w-1/6"
                >
                  {{ item.threat_level }}
                </td>
              </tr>
            </tbody>
          </table>

          <nav
            class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg"
            aria-label="Pagination"
          >
            <div class="flex-1 py-4">
              <div class="text-sm text-left text-gray-500 mr-4 italic">
                <span
                  >Your lists will be automatically deleted in
                  {{ settings?.AUTO_DELETE_DAYS || 14 }} days by default.
                </span>
                <router-link
                  to="/contact"
                  class="whitespace-nowrap underline hover:text-gray-400"
                  >Contact us</router-link
                >
                to request an extension or reduction.
              </div>
            </div>
            <div class="hidden sm:block mr-4">
              <p class="text-sm text-right text-gray-700">
                {{ " " }}
                <span class="font-medium">{{ currentPage }}</span>
                {{ " " }}
                of
                {{ " " }}
                <span class="font-medium">{{ totalPages }}</span>
                {{ " " }}
              </p>
            </div>
            <div class="flex justify-between sm:justify-end">
              <button
                @click="goToPage(currentPage - 1)"
                :disabled="currentPage === 1"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <button
                @click="goToPage(currentPage + 1)"
                :disabled="currentPage === totalPages"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/common/SearchInput";
import { SelectorIcon } from "@heroicons/vue/solid";
export default {
  name: "EmailList",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchInput,
    SelectorIcon,
  },
  data() {
    return {
      resultsData: [],
      urlParams: { page: 1, ordering: "-email", size: 10, search: "" },
      searchKeyword: "",
      currentPage: 1,
      totalPages: 1,
    };
  },
  computed: {
    paginatedResults() {
      const startIndex = (this.currentPage - 1) * this.urlParams.size;
      const endIndex = startIndex + this.urlParams.size;
      return this.results.slice(startIndex, endIndex);
    },
    hasPagination() {
      return this.totalPages >= 1;
    },
  },
  mounted() {
    this.fetchEmailCheckList(this.$props.id, this.urlParams);
  },
  methods: {
    onSearchInput() {
      this.urlParams.search = this.searchKeyword;
      this.fetchEmailCheckList(this.$props.id, this.urlParams);
    },
    async fetchEmailCheckList(id, urlParams, name) {
      if (name == "Email") {
        this.urlParams.ordering =
          this.urlParams.ordering === "-email" ? "email" : "-email";
      }
      const response = await this.$alfredService.realtimeEmailPreview(
        id,
        urlParams
      );
      if (response) {
        this.resultsData = response.results.map((value) => {
          return {
            ...value,
            reason: this.humanize(value["deliverability_reason"]),
            status: this.humanize(value["deliverability_status"]),
            threat_level: this.humanize(value["threat_status"]),
            rating: this.getRating(value["score"]),
            recommendation: this.getRecommendation(value["score"]),
          };
        });
        this.totalPages = Math.ceil(response.count / this.urlParams.size);
      }
    },

    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.urlParams.page = pageNumber;
        this.fetchEmailCheckList(this.$props.id, this.urlParams);
        this.currentPage = pageNumber;
      }
    },

    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },
    getRating(score) {
      if (score == 10) {
        return "Excellent";
      } else if (score == 9 || score == 8) {
        return "Good";
      } else if (score == 7) {
        return "Fair";
      } else if (score == 6 || score == 5 || score == 4) {
        return "Poor";
      } else {
        return "Bad";
      }
    },
    getRecommendation(score) {
      if (score >= 7) {
        return "Safe to send";
      } else if (score >= 4) {
        return "Send with Caution";
      } else {
        return "Do not Send";
      }
    },
  },
};
</script>
<style scoped>
.row {
  flex-grow: 1;
  flex-basis: 0;
}
</style>
