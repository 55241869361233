<template>
  <div class="flex sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8">
    <div class="flex flex-col text-sm text-left sm:w-1/4 w-full">
      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Created</span>
        <span class="text-gray-500 whitespace-nowrap">{{ created_at }}</span>
      </div>
      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Created With</span>
        <span class="text-gray-500 whitespace-nowrap">{{ created_with }}</span>
      </div>
      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Quantity</span>
        <span class="text-gray-500 whitespace-nowrap">{{ qty }}</span>
      </div>
    </div>
    <div class="flex items-center m-2">
      <div class="pr-3 flex" v-if="!isGenerating">
        <button @click="showDashboard()"
          class="button-submit border px-4 py-2.5 border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-100 focus:outline-none">
          View Dashboard
        </button>
        <button class="ml-5 text-red-500 focus:outline-none text-sm" @click="fileDetails">
          <img class="  h-6 w-auto" :src="require('@/assets/images/pdf.png')" alt="Alfred Knows" />PDF
        </button>
      </div>
    </div>
  </div>
  <div class="sm:flex sm:items-start justify-center" v-if="isDownload">
    <PulseLoader color="#ff5a5f" />
  </div>
  <div class="tab rounded-t-md p-8 mt-10 mb-10 hidden-print">
    <nav class="float-left">
      <a v-for="tab in tabs" :key="tab.name" :class="[
        tab.current
          ? 'current'
          : 'border-transparent hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap mr-5 pb-4 px-1 border-b-2 text-md cursor-pointer',
      ]" @click="changeTab(tab.name)">
        {{ tab.name }}
      </a>
    </nav>
  </div>
  <div v-for="tab in tabs" :key="tab.name" v-show="activeTab === tab.name">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="(value, key) in resultsData[tab.name]" :key="key"
        class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-md font-medium text-gray-500 truncate">{{ key }}</dt>
        <dd class="mt-1 text-2xl font-semibold text-green-600">
          <span class="mt-1 text-sm font-medium text-gray-700">
            <span :class="[
              'mt-1',
              'text-2xl',
              'font-semibold',
              colorClassMap[key] || 'text-yellow-400',
            ]">{{ Math.round((value / qty) * 100) }}%</span>
            ({{ value }})</span>
        </dd>
      </div>
    </dl>
  </div>

  <div class="summary_canvas sm:flex-row flex-col justify-between items-center w-full bg-white shadow rounded-md p-8 canvas_div_pdf"
    id="content"    
    hidden>
    <div class="flex flex-col text-sm text-left sm:w-1/4 w-full pb-6">

      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Created: &nbsp;&nbsp;</span>
        <span class="text-gray-500 whitespace-nowrap">{{ created_at }}</span>
      </div>
      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Created With: &nbsp;&nbsp;</span>
        <span class="text-gray-500 whitespace-nowrap">{{ created_with }}</span>
      </div>
      <div class="grid grid-cols-2 mb-2">
        <span class="font-medium text-gray-600 uppercase">Quantity: &nbsp;&nbsp;</span>
        <span class="text-gray-500 whitespace-nowrap">{{ qty }}</span>
      </div>
    </div>

    <div class="py-5 p-4">
      <div v-for="tab in tabs" :key="tab.name" class="text-gray-600 text-xl align-left pb-10">
        <h3 class="text-lg leading-6 font-bold text-gray-600">
          {{ tab.name }}
        </h3>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div v-for="(value, key) in resultsData[tab.name]" :key="key"
            class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500">
              {{ key }}
            </dt>
            <div></div>
            <dd class="mt-1 text-2xl font-semibold text-green-600">
              <span class="mt-1 text-sm font-medium text-gray-700">
                <span :class="[
                  'mt-1',
              'text-2xl',
              'font-semibold',
              colorClassMap[key] || 'text-yellow-400',
                ]">{{ Math.round((value / qty) * 100) }}%</span>
                ({{ value }})</span>
            </dd>
          </div>
        </dl>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader";
import jsPDF from "jspdf";
import $ from "jquery";
import html2canvas from "html2canvas";

export default {
  name: "EmailCheckDetails",
  components: {
    PulseLoader
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultsData: ref([]),
      created_at: ref(""),
      qty: "",
      created_with: ref(""),
      isDownload: ref(false),
      isGenerating: ref(false),
      data: ref([]),
      paginationData: reactive({}),
      urlParams: reactive({ page: 1, ordering: "-created_at", size: 10 }),
      tabs: [
        { name: "Objective", current: true },
        { name: "Score", current: false },
        { name: "Provider", current: false },
        { name: "Recommendation", current: false },
        { name: "Deliverability Status", current: false },
        { name: "Threat Status", current: false },
        { name: "Deliverability Reason", current: false },
      ],
      activeTab: "Objective",
    };
  },

  mounted() {
    this.fetchEmailCheckSummary(this.$props.id);
  },

  computed: {
    colorClassMap() {
      return {
        "Best Deliverability": "text-green-600",
        "Maximum Reach": "text-yellow-400",
        "Safe to send": "text-green-600",
        "Send with Caution": "text-yellow-400",
        "Do not Send": "text-red-600",
        Deliverable: "text-green-600",
        "Possibly Deliverable": "text-yellow-400",
        Undeliverable: "text-red-600",
        "Unknown Deliverability": "text-gray-400",
        Low: "text-green-400",
        Moderate: "text-yellow-400",
        High: "text-red-600",
        Undetermined: "text-gray-400",
        "10-9": "text-green-600",
        "7-8": "text-green-400",
        "6 ": "text-yellow-400",
        "4-5": "text-red-400",
        "1-3": "text-red-600",      
        "Accepted Email": "text-green-600",
        "Catch All": "text-yellow-400",
        Disposable: "text-yellow-400",
        "DNS Error": "text-yellow-400",
        "Unavailable SMTP": "text-yellow-400",
        "Role Based": "text-yellow-400",
        Suspicious: "text-yellow-400",
        "Invalid Email": "text-red-600",
        "Invalid Domain": "text-red-600",
        "Rejected Email": "text-red-600",
        Dangerous: "text-red-600",
        Malicious: "text-red-600",
        Unknown: "text-gray-400",       
        "Mailbox Disabled": "text-gray-400",
        "Mailbox Full": "text-gray-400",   
      };
    },
  },

  methods: {
    async fetchEmailCheckSummary(id) {
      const response = await this.$alfredService.realtimeEmailSummary(
        id,
        this.urlParams
      );

      if (response) {
        this.created_at = response.created_at;
        this.qty = response.emails_count;
        this.created_with = response.created_with;
        this.resultsData = this.transformData(response);
        this.resultsData.Objective = this.getObjectiveCounts(
          this.resultsData.Score);
        this.resultsData.Recommendation = this.getRecommendationCounts(
          this.resultsData.Score
        );        
        this.resultsData.Score = this.formatScores(this.resultsData.Score);
        this.resultsData.Provider = this.formatProvider(this.resultsData.Provider);
        for (const tab in this.resultsData) {
          if (tab !== "Provider") {
        this.resultsData[tab] = this.sortResultsData(this.resultsData[tab]);
          }
      }
      }
    },
    formatProvider(providerData) {
    const transformedProviderData = {};
    const PROVIDER_TRANSFORM_MAP = {
                icloud: "Apple",
                yahoo: "AOL/Yahoo",
                aol: "AOL/Yahoo",
                hotmail: "Hotmail/Outlook",
                outlook: "Hotmail/Outlook",
                live: "Hotmail/Outlook",
                amazonaws: "Amazon",
            };
    for (const [provider, count] of Object.entries(providerData)) {
      const transformedProvider = PROVIDER_TRANSFORM_MAP[provider.toLowerCase()] || provider;
      
      if (transformedProviderData[transformedProvider]) {
        transformedProviderData[transformedProvider] += count;
      } else {
        transformedProviderData[transformedProvider] = count;
      }
    }
    return transformedProviderData;
  },
    sortResultsData(data) {
    const orderedKeys = Object.keys(this.colorClassMap);
    const sortedData = {};
    for (const key of orderedKeys) {
      if (data[key] !== undefined) {
        sortedData[key] = data[key];
      }
    }
    return sortedData;
  },
    transformData(data) {
      if (typeof data !== "object" || data === null) {
        return data;
      }

      if (Array.isArray(data)) {
        return data.map(this.transformData);
      }

      const transformedObj = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          let transformedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase());

          transformedKey = transformedKey
            .replaceAll("Smtp", "SMTP")
            .replaceAll("Dns", "DNS");
          transformedObj[transformedKey] = this.transformData(data[key]);
        }
      }

      return transformedObj;
    },
    getObjectiveCounts(scoreData) {
      let best_deliv = 0;
      let max_reach = 0;
      for (const score in scoreData) {
        if (Object.prototype.hasOwnProperty.call(scoreData, score)) {
          const count = scoreData[score];
          const intScore = parseInt(score);
          if (intScore >= 7) {
            best_deliv += count;
          }
          if (intScore >= 6) {
            max_reach += count;
          }
        }
      }
      return {
        "Best Deliverability": best_deliv,
        "Maximum Reach": max_reach,
      };

    },
    getRecommendationCounts(scoreData) {
      let safeToSendCount = 0;
      let sendWithCautionCount = 0;
      let doNotSendCount = 0;

      for (const score in scoreData) {
        if (Object.prototype.hasOwnProperty.call(scoreData, score)) {
          const count = scoreData[score];
          const intScore = parseInt(score);

          if (intScore >= 7) {
            safeToSendCount += count;
          } else if (intScore >= 4) {
            sendWithCautionCount += count;
          } else {
            doNotSendCount += count;
          }
        }
      }

      return {
        "Safe to send": safeToSendCount,
        "Send with Caution": sendWithCautionCount,
        "Do not Send": doNotSendCount,
      };
    },
    formatScores(scoresDict) {
      const formattedScores = {};

      for (const [score, count] of Object.entries(scoresDict)) {
        const numericScore = parseInt(score);
        if (numericScore >= 9) {
          formattedScores["10-9"] = count;
        } else if (numericScore >= 7) {
          formattedScores["7-8"] = formattedScores["7-8"] || 0;
          formattedScores["7-8"] += count;
        } else if (numericScore == 6) {
          formattedScores["6 "] = count;
        } else if (numericScore >= 4) {
          formattedScores["4-5"] = formattedScores["4-5"] || 0;
          formattedScores["4-5"] += count;
        } else {
          formattedScores["1-3"] = formattedScores["1-3"] || 0;
          formattedScores["1-3"] += count;
        }
      }
      const reversedScores = {};
      const keys = Object.keys(formattedScores);
      for (let i = keys.length - 1; i >= 0; i--) {
        const key = keys[i];
        reversedScores[key] = formattedScores[key];
      }
      return reversedScores;
    },
    getColorClass(key) {
      const colorClasses = {
        "Safe to send": "text-green-600",
        "Send with Caution": "text-yellow-400",
        "Do not Send": "text-red-600",
      };
      const normalizedKey = key.replace(/\s+/g, "").toLowerCase();
      return colorClasses[normalizedKey] || "";
    },
    changeTab(tabName) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === tabName;
      });
      this.activeTab = tabName;
    },
    async showDashboard() {
      await this.$router.push({
        name: "Dashboard",
      });
    },
    fileDetails() {
      this.isDownload = true;
      this.isGenerating = true;
      $("#content").removeAttr("hidden");
      const name = this.created_at.toString();
      setTimeout(() => {
        html2canvas($(".canvas_div_pdf")[0], {
          allowTaint: true,
          scale: 2,
        }).then((canvas) => {
          var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);

          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
          pdf.save(name + ".pdf");
        });
        $("#content").attr("hidden", "true");
        this.isDownload = false;
        this.isGenerating = false;
      }, "3000");
    },
  },
};
</script>

<style scoped>
.new-page-with-tabs {
  /* Add styles for the container of the tabs and their content */
}

.tabs {
  /* Add styles for the tab buttons container */
}

.tab button:hover {
}

.tab .active-tab {
}

.current {
  border-color: var(--main-color);
  color: var(--main-color);
}
.summary_canvas {
  position: absolute;
  top: 80em;
}
</style>
