<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :hasCloseBtn="false">
    <template v-slot:title>Update Billing Information</template>
    <template v-slot:content>
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="name" class="block text-sm font-medium text-gray-700">
            Bill To
          </label>
          <div class="mt-1">
            <input id="name" name="name" autocomplete="name" type="text" class="form-field form-field-no-icon sm:w-full"
              v-model="data.name" @input="
                formActions.clearFieldError(
                  'name'
                )
              "/>
            <p class="text-red-500 text-sm mt-1">
              {{ errors["name"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label for="country">Country</label>
          <div class="mt-1">
            <select id="country" name="country" autocomplete="country" v-model="data.address.country"
              class="form-field-no-icon sm:w-full"
              @change="selectState()"
              @input="
                formActions.clearFieldError(
                  'address.country'
                )
              ">
              <option v-for="country in countryCodes" :key="country.code" :value="country.code">
                {{ country.country }}
              </option>
            </select>
            <p class="text-red-500 text-sm mt-1">
            {{ errors["address.country"] }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="address_line_1" class="block text-sm font-medium text-gray-700">
            Address Line 1
          </label>
          <div class="mt-1">
            <input type="text" name="address_line_1" id="address_line_1" class="form-field-no-icon sm:w-full"
              v-model="data.address.address_line_1" 
              @input="
                formActions.clearFieldError(
                  'address.address_line_1'
                )
              "
              />
              <p class="text-red-500 text-sm mt-1">
              {{ errors["address.address_line_1"] }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="address_line_2"> Address Line 2 </label>
          <div class="mt-1">
            <input type="text" name="address_line_2" id="address_line_2" class="form-field-no-icon sm:w-full"
              v-model="data.address.address_line_2" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="city"> City </label>
          <div class="mt-1">
            <input type="text" name="city" id="city" class="form-field-no-icon sm:w-full" v-model="data.address.city"
            @input="
                formActions.clearFieldError(
                  'address.city'
                )
              " />
            <p class="text-red-500 text-sm mt-1">
            {{ errors["address.city"] }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-6" v-if="stateShow">
          <label for="state">State/Province/Region</label>
          <div class="mt-1">
            <select id="state" name="state" autocomplete="state" v-model="data.address.state"
              class="form-field-no-icon sm:w-full"
              @input="
                formActions.clearFieldError(
                  'address.state'
                )
              ">
              <option v-for="states in state" :key="states.code" :value="states.code">
                {{ states.name }}
              </option>
            </select>
            <p class="text-red-500 text-sm mt-1">
            {{ errors["address.state"] }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="postal-code"> Postal Code </label>
          <div class="mt-1">
            <input id="postal-code" name="postal-code" autocomplete="postal-code" v-model="data.address.postal_code"
            @input="
                formActions.clearFieldError(
                  'address.postal_code'
                )
              "
              type="text" class="form-field-no-icon sm:w-full" />
              <p class="text-red-500 text-sm mt-1">
              {{ errors["address.postal_code"] }}
              </p>
            </div>
        </div>

        <div class="sm:col-span-3">
          <label for="vat_type"> VAT Type </label>
          <div class="mt-1">
            <select id="vat_type" name="vat_type" autocomplete="vat_type" v-model="vat_type_temp" @change="resetValue()"
              class="form-field-no-icon sm:w-full">
              <option v-for="(vat_types, description) in taxIdDescriptions" :key="vat_types" :value="vat_types">
                {{ description }}
              </option>
            </select>
            <p class="text-red-500 text-sm mt-1">
              {{ humanize(VAT_type_error) }}
            </p>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="vat_value">VAT Value</label>
          <div class="mt-1">
            <div class="flex">
            <input id="vat_value" name="vat_value" autocomplete="vat_value" v-model="vat_value_temp" type="text"
            :placeholder="getPlaceholder()" class="form-field-no-icon sm:w-full" />
              <button
                @click="deleteVATinfo()"
                v-if="data.vat_type"
                :disabled="pendingUpdate"
                class="text-gray-600 text-sm hover:text-gray-500 button-icon pl-3"
              >
                <XIcon class="h-5" />
              </button>
              </div>
            <p class="text-red-500 text-sm mt-1">
              {{ humanize(VAT_value_error) }}
            </p>
          </div>
          
        </div>

      </div>
    </template>
    <template v-slot:buttons>
      <button type="button" class="button-submit" :disabled="pendingUpdate" @click="onSubmit">
        Submit
      </button>
      <button type="button" class="button-cancel mr-2" @click="closeModal" ref="cancelButtonRef">
        Cancel
      </button>
    </template>
  </DialogContainer>
</template>
<script>

import { reactive, ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import countryCodes from "@/constants/countryCodes.json";
import { required } from "@/utils/validators";
import stripeElementsMixin from "@/mixins/stripeElementsMixin";
import { XIcon } from "@heroicons/vue/solid";

export default {
  name: "BillingDetailsModal",
  mixins: [formMixin,stripeElementsMixin],
  components: {
    DialogContainer,
    XIcon,
  },
  setup() {
    const isOpen = ref(false);
    const pendingUpdate = ref(false);
    const stripeElements = reactive({});
    const tax_id = ref("");
    const datas = ref({});
    const { errors, formActions } = useFormErrors();
    const data = reactive({
      name: "",
      address: {
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: ""
      },
      vat_type: "",
      vat_value: ""
    });

    return {
      isOpen,
      data,
      countryCodes,
      pendingUpdate,
      tax_id,
      taxIdDescriptions: {
        "Andorra (AD NRT)": "ad_nrt",
        "Argentina (AR CUIT)": "ar_cuit",
        "Australia (AU ABN)": "au_abn",
        "Australia (AU ARN)": "au_arn",
        "Austria (AT VAT)": "eu_vat1",
        "Bahrain (BH VAT)": "bh_vat",
        "Belgium (BE VAT)": "eu_vat2",
        "Bolivia (BO TIN)": "bo_tin",
        "Brazil (BR CNPJ)": "br_cnpj",
        "Brazil (BR CPF)": "br_cpf",
        "Bulgaria (BG UIC)": "bg_uic",
        "Bulgaria (BG VAT)": "eu_vat3",
        "Canada (CA BN)": "ca_bn",
        "Canada (CA GST/HST)": "ca_gst_hst",
        "Canada (CA PST-BC)": "ca_pst_bc",
        "Canada (CA PST-MB)": "ca_pst_mb",
        "Canada (CA PST-SK)": "ca_pst_sk",
        "Canada (CA QST)": "ca_qst",
        "Chile (CL TIN)": "cl_tin",
        "China (CN TIN)": "cn_tin",
        "Colombia (CO NIT)": "co_nit",
        "Costa Rica (CR TIN)": "cr_tin",
        "Croatia (HR VAT)": "eu_vat4",
        "Cyprus (CY VAT)": "eu_vat5",
        "Czech Republic (CZ VAT)": "eu_vat6",
        "Denmark (DK VAT)": "eu_vat7",
        "Dominican Republic (DO RCN)": "do_rcn",
        "Ecuador (EC RUC)": "ec_ruc",
        "Egypt (EG TIN)": "eg_tin",
        "El Salvador (SV NIT)": "sv_nit",
        "Estonia (EE VAT)": "eu_vat8",
        "European Union (EU OSS VAT)": "eu_oss_vat",
        "Finland (FI VAT)": "eu_vat9",
        "France (FR VAT)": "eu_vat10",
        "Georgia (GE VAT)": "ge_vat",
        "Germany (DE STN)": "de_stn",
        "Germany (DE VAT)": "eu_vat11",
        "Greece (GR VAT)": "eu_vat12",
        "Hong Kong (HK BR)": "hk_br",
        "Hungary (HU VAT)": "eu_vat13",
        "Hungary (HU TIN)": "hu_tin",
        "Iceland (IS VAT)": "is_vat",
        "India (IN GST)": "in_gst",
        "Indonesia (ID NPWP)": "id_npwp",
        "Ireland (IE VAT)": "eu_vat14",
        "Israel (IL VAT)": "il_vat",
        "Italy (IT VAT)": "eu_vat15",
        "Japan (JP CN)": "jp_cn",
        "Japan (JP RN)": "jp_rn",
        "Japan (JP TRN)": "jp_trn",
        "Kazakhstan (KZ BIN)": "kz_bin",
        "Kenya (KE PIN)": "ke_pin",
        "Latvia (LV VAT)": "eu_vat16",
        "Liechtenstein (LI UID)": "li_uid",
        "Lithuania (LT VAT)": "eu_vat17",
        "Luxembourg (LU VAT)": "eu_vat18",
        "Malaysia (MY FRP)": "my_frp",
        "Malaysia (MY ITN)": "my_itn",
        "Malaysia (MY SST)": "my_sst",
        "Malta (MT VAT)": "eu_vat19",
        "Mexico (MX RFC)": "mx_rfc",
        "Netherlands (NL VAT)": "eu_vat20",
        "New Zealand (NZ GST)": "nz_gst",
        "Nigeria (NG TIN)": "ng_tin",
        "Norway (NO VAT)": "no_vat",
        "Norway (NO VOEC)": "no_voec",
        "Oman (OM VAT)": "om_vat",
        "Peru (PE RUC)": "pe_ruc",
        "Philippines (PH TIN)": "ph_tin",
        "Poland (PL VAT)": "eu_vat21",
        "Portugal (PT VAT)": "eu_vat22",
        "Romania (RO VAT)": "eu_vat23",
        "Romania (RO TIN)": "ro_tin",
        "Russia (RU INN)": "ru_inn",
        "Russia (RU KPP)": "ru_kpp",
        "Saudi Arabia (SA VAT)": "sa_vat",
        "Serbia (RS PIB)": "rs_pib",
        "Singapore (SG GST)": "sg_gst",
        "Singapore (SG UEN)": "sg_uen",
        "Slovakia (SK VAT)": "eu_vat24",
        "Slovenia (SI VAT)": "eu_vat25",
        "Slovenia (SI TIN)": "si_tin",
        "South Africa (ZA VAT)": "za_vat",
        "South Korea (KR BRN)": "kr_brn",
        "Spain (ES CIF)": "es_cif",
        "Spain (ES VAT)": "eu_vat26",
        "Sweden (SE VAT)": "eu_vat27",
        "Switzerland (CH UID)": "ch_uid",
        "Switzerland (CH VAT)": "ch_vat",
        "Taiwan (TW VAT)": "tw_vat",
        "Thailand (TH VAT)": "th_vat",
        "Turkey (TR TIN)": "tr_tin",
        "Ukraine (UA VAT)": "ua_vat",
        "United Arab Emirates (AE TRN)": "ae_trn",
        "United Kingdom (XI VAT)": "eu_vat28",
        "United Kingdom (GB VAT)": "gb_vat",
        "United States (US EIN)": "us_ein",
        "Uruguay (UY RUC)": "uy_ruc",
        "Venezuela (VE RIF)": "ve_rif",
        "Vietnam (VN TIN)": "vn_tin"
      },
      vat_placeholders: {
      "ad_nrt": "A-123456-Z",
      "ar_cuit": "12-3456789-01",
      "au_abn": "12345678912",
      "au_arn": "123456789123",
      "eu_vat1": "ATU12345678",
      "bh_vat": "123456789012345",
      "eu_vat2": "BE0123456789",
      "bo_tin": "123456789",
      "br_cnpj": "01.234.456/5432-10",
      "br_cpf": "123.456.789-87",
      "bg_uic": "123456789",
      "eu_vat3": "BG0123456789",
      "ca_bn": "123456789",
      "ca_gst_hst": "123456789RT0002",
      "ca_pst_bc": "PST-1234-5678",
      "ca_pst_mb": "123456-7",
      "ca_pst_sk": "1234567",
      "ca_qst": "1234567890TQ1234",
      "cl_tin": "12.345.678-K",
      "cn_tin": "123456789012345678",
      "co_nit": "123.456.789-0",
      "cr_tin": "1-234-567890",
      "eu_vat4": "HR12345678912",
      "eu_vat5": "CY12345678Z",
      "eu_vat6": "CZ1234567890",
      "eu_vat7": "DK12345678",
      "do_rcn": "123-4567890-1",
      "ec_ruc": "1234567890001",
      "eg_tin": "123456789",
      "sv_nit": "1234-567890-123-4",
      "eu_vat8": "EE123456789",
      "eu_oss_vat": "EU123456789",
      "eu_vat9": "FI12345678",
      "eu_vat10": "FRAB123456789",
      "ge_vat": "123456789",
      "de_stn": "1234567890",
      "eu_vat11": "DE123456789",
      "eu_vat12": "EL123456789",
      "hk_br": "12345678",
      "eu_vat13": "HU12345678",
      "hu_tin": "12345678-1-23",
      "is_vat": "123456",
      "in_gst": "12ABCDE3456FGZH",
      "id_npwp": "12.345.678.9-012.345",
      "eu_vat14": "IE1234567AB",
      "il_vat": "000012345",
      "eu_vat15": "IT12345678912",
      "jp_cn": "1234567891234",
      "jp_rn": "12345",
      "jp_trn": "T1234567891234",
      "kz_bin": "123456789012",
      "ke_pin": "P000111111A",
      "eu_vat16": "LV12345678912",
      "li_uid": "CHE123456789",
      "eu_vat17": "LT123456789123",
      "eu_vat18": "LU12345678",
      "my_frp": "12345678",
      "my_itn": "C 1234567890",
      "my_sst": "A12-3456-78912345",
      "eu_vat19": "MT12345678",
      "mx_rfc": "ABC010203AB9",
      "eu_vat20": "NL123456789B12",
      "nz_gst": "123456789",
      "ng_tin": "12345678-0001",
      "no_vat": "123456789MVA",
      "no_voec": "1234567",
      "om_vat": "OM1234567890",
      "pe_ruc": "12345678901",
      "ph_tin": "123456789012",
      "eu_vat21": "PL1234567890",
      "eu_vat22": "PT123456789",
      "eu_vat23": "RO1234567891",
      "ro_tin": "1234567890123",
      "ru_inn": "1234567891",
      "ru_kpp": "123456789",
      "sa_vat": "123456789012345",
      "rs_pib": "123456789",
      "sg_gst": "M12345678X",
      "sg_uen": "123456789F",
      "eu_vat24": "SK1234567891",
      "eu_vat25": "SI12345678",
      "si_tin": "12345678",
      "za_vat": "4123456789",
      "kr_brn": "123-45-67890",
      "es_cif": "A12345678",
      "eu_vat26": "ESA1234567Z",
      "eu_vat27": "SE123456789123",
      "ch_uid": "CHE-123.456.789 HR",
      "ch_vat": "CHE-123.456.789 MWST",
      "tw_vat": "12345678",
      "th_vat": "1234567891234",
      "tr_tin": "0123456789",
      "ua_vat": "123456789",
      "ae_trn": "123456789012345",
      "eu_vat28": "XI123456789",
      "gb_vat": "GB123456789",
      "us_ein": "12-3456789",
      "uy_ruc": "123456789012",
      "ve_rif": "A-12345678-9",
      "vn_tin": "1234567890"
      },
      VAT_value_error: ref(""),
      VAT_type_error: ref(""),
      deleteVAT: ref(false),
      vat_value_temp: ref(""),
      vat_type_temp: ref(""),
      state: ref([]),
      stateShow: ref(false),
      datas,
      errors,
      formActions,
      stripeElements
    };
  },
  methods: {
    selectState(check){
      let selectedCountry = countryCodes.find(country => country.code === this.data.address.country);
      if (selectedCountry.states) {
        this.state = selectedCountry.states;
        this.stateShow = true;
      }else{
        this.state = [];
        this.stateShow = false;
      }
      if(!check){
        this.data.address.state = "";
      }
  },
  getPlaceholder(){
    if(this.vat_type_temp){
      return this.vat_placeholders[this.vat_type_temp]
    }
  },
    validate() {
      const baseValidators = {
        "name": [required],
        "address.address_line_1": [required],
        "address.city": [required],
        "address.postal_code": [required],
        "address.country": [required],
      };

      const extendedValidators = {
        ...baseValidators,
        "address.state": [required],
      };

      const validators = this.stateShow ? extendedValidators : baseValidators;

      this.formActions.validateForm(this.data, validators);
      for (let key of Object.keys(this.stripeElements)) {
        const stripeElement = this.stripeElements[key];
        this.validateStripeField(key, stripeElement);
      }
      return !Object.keys(this.errors).length;
    },
    validateStripeField(name, value) {
      if (value._empty) {
        this.formActions.setFieldError(name, "This field is required.");
        return false;
      }
      return true;
    },
    openModal(datas) {
      this.datas = datas;
      this.data.name = datas?.name;
      this.data.address.address_line_1 = datas?.address?.line1;
      this.data.address.address_line_2 = datas?.address?.line2;
      this.data.address.city = datas?.address?.city;
      this.data.address.state = datas?.address?.state;
      this.data.address.postal_code = datas?.address?.postal_code;
      this.data.address.country = datas?.address?.country;
      this.data.vat_type = datas?.vat_details?.data[0]?.type;
      this.data.vat_value = datas?.vat_details?.data[0]?.value;
      if(this.data.vat_type == "eu_vat"){
       let country = datas?.vat_details?.data[0]?.country + " VAT";
       for (const [key, value] of Object.entries(this.taxIdDescriptions)) {
          if(key.includes(country)){
            this.data.vat_type = value;
            break;
          }
        }
      }
      this.vat_value_temp = this.data.vat_value;
      this.vat_type_temp = this.data.vat_type ? this.data.vat_type : "";
      this.tax_id = datas?.vat_details?.data[0]?.id;
      if(this.data.address.state){
        this.stateShow = true;
        this.selectState(true);
      }else{
        this.data.address.state = "";
      }
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.formActions.clearFormErrors();
      this.state = [];
      this.stateShow = false;
      this.data.address.country = "";
      this.data.address.state = "";
    },
    async onSubmit() {
      if (this.pendingUpdate) {
        return;
      }
      this.pendingUpdate = true;
      if (!this.validate()) {
          this.pendingUpdate = false;
          return;
        }
      try {
        await this.updateBillingDetails();
      } finally {
        window.setTimeout(() => {
          this.pendingUpdate = false;
        }, 1000);
      }
    },
    checkEU_VAT(){
      if(this.vat_type_temp.includes("eu_vat")){
            const selectedKey = Object.keys(this.taxIdDescriptions).find(key => this.taxIdDescriptions[key] === this.vat_type_temp);
            if(selectedKey.includes("Greece")){
              if(!this.vat_value_temp.includes("EL")){
                this.VAT_value_error = "Invalid VAT value";
                return false;
              }
            }else{
              const countryCode = selectedKey.match(/\(([A-Z]{2})/)[1];
              if(!this.vat_value_temp.includes(countryCode)){
                this.VAT_value_error = "Invalid VAT value";
                return false;
              }
            }
          }
      return true
    },
    resetValue(){
      this.vat_value_temp = "";
      this.data.vat_value = "";
    },
    async updateBillingDetails() {
      try {
        this.VAT_value_error = "";
        this.VAT_type_error = "";
        if (this.vat_value_temp == this.datas?.vat_details?.data[0]?.value) {
          delete this.data.vat_type;
          delete this.data.vat_value;
        }else{
          this.data.vat_value = this.vat_value_temp;
          this.data.vat_type = this.vat_type_temp.replace(/\d+$/, '');
        }
        if(this.vat_type_temp && !this.vat_value_temp){
          this.VAT_value_error = "Enter VAT value";
          return;
        }else if(!this.vat_type_temp && this.vat_value_temp){
          this.VAT_type_error = "Choose VAT type";
          return;
        }else if(this.vat_type_temp && this.vat_value_temp){
          if(!this.checkEU_VAT()){
            return;
          }
        }
        const response = await this.$alfredService.updateBillingAddress(this.data);
        if (response) {
          if (this.data.vat_value || this.deleteVAT) {
            this.removeTax();
          }
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Your billing address has been successfully added!",
            },
          });
          this.isOpen = false;
          eventBus.trigger("update-cards", {});
        }
      } catch (e) {
        this.VAT_value_error = e?.response?.data?.detail;
      }
    },
    deleteVATinfo(){
      delete this.data.vat_type;
      delete this.data.vat_value;
      this.vat_type_temp = "";
      this.vat_value_temp = "";
      this.deleteVAT = true;
    },
    removeTax() {
      this.$alfredService.deleteTax(this.tax_id);
    },
    humanize(text) {
      if (text) {
        return text.replace(/([a-zA-Z]*)_([a-zA-Z]+)/g, function (match, p1, p2) {
          return p1.toUpperCase() + " " + p2.toUpperCase();
        });
      }

    },
  },
};
</script>
