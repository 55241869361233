export function flattenDict(dictToFlatten) {
  function flatten(dict, parent) {
    let keys = [];
    let values = [];

    for (let key in dict) {
      if (typeof dict[key] === "object" && !Array.isArray(dict[key])) {
        const result = flatten(dict[key], parent ? parent + "_" + key : key);
        keys = keys.concat(result.keys);
        values = values.concat(result.values);
      } else {
        keys.push(parent ? parent + "." + key : key);
        values.push(dict[key]);
      }
    }

    return {
      keys: keys,
      values: values,
    };
  }

  let result = flatten(dictToFlatten);
  let flatDict = {};

  for (let i = 0, end = result.keys.length; i < end; i++) {
    flatDict[result.keys[i]] = result.values[i];
  }

  return flatDict;
}
