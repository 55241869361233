<template>
  <div class="text-center pb-0 px-4 sm:px-6 lg:px-8">
    <div class="mb-6 flex flex-row-reverse">
      <SearchInput v-model="keyword" @input="fetchUsersOnSearch" />
    </div>
    <div class="overflow-x-auto rounded-lg bg-white shadow">
      <div class="align-middle inline-block w-full">
        <table class="divide-gray-300 w-full text-left table-auto">
          <thead class="border-b-2">
            <tr>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <FilterableHeaderCell
                  title="Email"
                  param="email"
                  @switchOrder="onSwitchOrder"
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="users.length > 0">
            <tr v-for="user in users" :key="user.id">
              <td
                class="py-5 px-6 text-sm text-gray-500 hover:bg-gray-50 cursor-pointer"
                @click="goToUser(user.id)"
              >
                {{ user.email }}
                <span
                  v-if="user.is_parent"
                  class="px-2 text-blue-800 bg-blue-100 rounded-full border-gray-200"
                  >Parent user</span
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="py-5 px-6 text-sm text-gray-500">No data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import SearchInput from "@/components/common/SearchInput";

export default {
  name: "LinkedAccounts",
  components: {
    FilterableHeaderCell,
    SearchInput,
  },
  props: {
    userId: Number,
    default: null,
  },
  async beforeMount() {
    await this.fetchUsers();
  },
  setup() {
    return {
      users: ref([]),
      keyword: ref(""),
    };
  },
  methods: {
    async fetchUsers() {
      const users = await this.$alfredService.linkedAccountList(
        this.userId,
        this.keyword
      );
      const { results } = users;
      this.users = results;
    },
    async fetchUsersOnSearch() {
      await this.fetchUsers();
    },
    async goToUser(user_id) {
      await this.$router.push({
        name: "UserDetails",
        params: { userId: user_id },
      });
      this.$emit("goToUser", user_id);
    },
  },
};
</script>
