<template>
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 w-72 mx-8 my-4 h-48 flex flex-col"
    >
      <div class="px-4 py-6 sm:p-6">
        <div class="flex flex-row justify-between items-center">
          <span>Recurring Payment</span>
          <button
          @click="onDelete"
          class="text-red-600 hover:text-red-500 button-icon"
        >
          <XIcon class="h-5" />
        </button>
        </div>
        <span class="text-sm flex">{{data?.type_of_recurring}}</span> 
        <div class="mt-5 flex flex-row flex-grow  items-center">
          <span>{{ formatN(data?.credits_to_purchase) }}</span><span class="text-sm justify-between pl-2">Credits to Purchase</span> 
        </div> 
        
      </div>
      <button @click="onUpdate" class="button-submit py-4 mt-0 rounded-none">
        <a class="text-base">Update</a>
      </button>
    </div>
  </template>
  <script>
  import { ref } from "vue";
  import useConfirmationModal from "@/api/confirmationModal";
  import formMixin from "@/mixins/formMixin";
  import eventBus from "@/utils/eventBus";
  import { XIcon } from "@heroicons/vue/solid";
  import humanize from "humanize";
  export default {
    name: "RecurringPaymentDetails",
    mixins: [formMixin],
    components: {
    XIcon,
    },
    inject: ["CreateRecurringPayment"],
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return {
        pendingUpdate: ref(false),
      };
    },
    methods: {
      async onUpdate() {
        this.CreateRecurringPayment.value.openModal(this.data);
      },
      formatN(count) {
      return humanize.numberFormat(count, 0);
      },
      async onDelete() {
        const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure want to remove this recurring payment?",
        message: "This action will stop future automatic payments.",
        onSubmit: async () => {
          this.$alfredService.RecurringPaymentDelete();
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: " Successfully removed!",
            },
          });
          eventBus.trigger("update-cards", {});
        },
      });
      },
    },
  };
  </script>
  