<template>
  <div class="mt-6 w-full">
    <label for="discount-code"> Discount Code </label>
    <div class="flex flex-col sm:flex-row">
      <input
        id="discount-code"
        class="form-field-no-icon flex-grow mt-1 sm:w-10/12"
        v-model="discountCode"
      />
      <button
        class="button-general bg-gray-200 hover:bg-gray-300 border-gray-400 text-gray-500 sm:ml-3 mt-1 whitespace-nowrap"
        :disabled="!discountCode"
        @click="applyDiscount"
      >
        Apply Discount
      </button>
    </div>
    <p v-if="errors.discount_code" class="text-red-500 text-sm mt-1">
      {{ errors.discount_code }}
    </p>
    <p v-if="modelValue.value" class="mt-1 text-sm">
      {{ modelValue.value }}% discount applied. Nicely done!
    </p>
  </div>
</template>

<script>
import { ref } from "vue";
import formMixin from "@/mixins/formMixin";
import useFormErrors from "@/api/formErrors";

export default {
  name: "DiscountInput",
  mixins: [formMixin],
  props: {
    modelValue: {
      type: Object,
    },
  },
  setup() {
    const { errors, formActions } = useFormErrors();
    const discountCode = ref(null);
    return { discountCode, errors, formActions };
  },
  methods: {
    async applyDiscount() {
      const discount =
        (await this.handleSubmissionErrors(
          async () =>
            await this.$alfredService.checkDiscountCode(this.discountCode),
          { formActions: this.formActions }
        )) || {};
      if (Object.keys(discount).length > 0) {
        this.formActions.clearFieldError("discount_code");
      }
      this.$emit("update:modelValue", discount);
    },
  },
};
</script>
