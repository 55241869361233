<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :hasCloseBtn="false">
    <template v-slot:title>Add Recurring Payment</template>

    <template v-slot:content>
      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="recurring_type" class="block text-sm font-medium text-gray-700">
            Recurrence Type
          </label>
          <div class="mt-1">
            <select class="form-field-no-icon sm:w-full" v-model="type_of_recurring">
              <option v-for="reccuring in recurring_options" :key="reccuring" :value="reccuring">
                {{ reccuring }}
              </option>
            </select>
            <p class="text-red-500 text-sm mt-1">
              {{ errors["type_of_recurring"] }}
            </p>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label for="recurring_payment" class="block text-sm font-medium text-gray-700">
            Payment Method
          </label>
          <div>
            <select class="form-field-no-icon sm:w-full" v-model="payment_method_id">
              <option v-for="method in paymentMethods" :key="method.id" :value="method.id">
                Card ending with {{ method.card.last4 }}
              </option>
            </select>
          </div>
          <p class="text-red-500 text-sm mt-1">
            {{ errors.payment_method_id }}
          </p>
        </div>
        <div v-if="is_time_period" class="sm:col-span-6">
          <label for="recurring_time_period" class="block text-sm font-medium text-gray-700">
            Auto-Purchase Interval (Days)
          </label>

          <!--NEW DESIGN DAYS-->
          <div class="sm:col-span-6">
            <div class="flex">
              <!-- First Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('days', 7)"
                  :class="{ 'selected-box': selectedBoxDays === 1, 'unselected-box': selectedBoxDays !== 1 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">7</p>

                </div>
              </div>
              <!-- Second Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('days', 30)"
                  :class="{ 'selected-box': selectedBoxDays === 2, 'unselected-box': selectedBoxDays !== 2 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">30</p>

                </div>
              </div>
              <!-- third Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('days', 60)"
                  :class="{ 'selected-box': selectedBoxDays === 3, 'unselected-box': selectedBoxDays !== 3 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">60</p>

                </div>
              </div>
              <!-- fourth Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('days', 90)"
                  :class="{ 'selected-box': selectedBoxDays === 4, 'unselected-box': selectedBoxDays !== 4 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">90</p>

                </div>
              </div>
            </div>
          </div>
          <!--END NEW DESIGN-->
          <p class="text-red-500 text-sm mt-1">
            {{ errors.time_period }}
          </p>
        </div>

        <div v-else class="sm:col-span-6">
          <label for="recurring_credit_limit" class="block text-sm font-medium text-gray-700">
            Auto-Purchase When Credit Balance Falls Below
          </label>
          <!--NEW DESIGN CREDIT LIMIT-->
          <div class="sm:col-span-6">
            <div class="flex">
              <!-- First Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('credits', 1000)"
                  :class="{ 'selected-box': selectedBoxCredits === 1, 'unselected-box': selectedBoxCredits !== 1 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">1,000</p>

                </div>
              </div>
              <!-- Second Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('credits', 10000)"
                  :class="{ 'selected-box': selectedBoxCredits === 2, 'unselected-box': selectedBoxCredits !== 2 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">10,000</p>

                </div>
              </div>
              <!-- third Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('credits', 100000)"
                  :class="{ 'selected-box': selectedBoxCredits === 3, 'unselected-box': selectedBoxCredits !== 3 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">100,000</p>

                </div>
              </div>
              <!-- fourth Box -->
              <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                <div @click="selectBox('credits', 1000000)"
                  :class="{ 'selected-box': selectedBoxCredits === 4, 'unselected-box': selectedBoxCredits !== 4 }"
                  class="box-item rounded-lg items-center justify-center flex text-center">
                  <p class="font-semi-bold">1,000,000</p>

                </div>
              </div>
            </div>
          </div>
          <!--END NEW DESIGN-->
          <p class="text-red-500 text-sm mt-1">
            {{ errors.credit_limit }}
          </p>
        </div>
        <div class="sm:col-span-6">
          <label for="recurring_credits" class="block text-sm font-medium text-gray-700">
            Credits to Purchase
          </label>
          <div>
            <!--NEW DESIGN CREDIT PURCHASE-->
            <div class="sm:col-span-6">
              <div class="flex">
                <!-- First Box -->
                <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                  <div @click="used_box > 1 ? null : selectBox('purchase', 1000)"
                    :class="{ 'selected-box': selectedBoxPurchase === 1, 'unselected-box': selectedBoxPurchase !== 1, 'used-box': used_box > 1 }"
                    class="box-item rounded-lg items-center justify-center flex text-center">
                    <p class="font-semi-bold">1,000</p>

                  </div>
                </div>
                <!-- Second Box -->
                <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                  <div @click="used_box > 2 ? null : selectBox('purchase', 10000)"
                    :class="{ 'selected-box': selectedBoxPurchase === 2, 'unselected-box': selectedBoxPurchase !== 2, 'used-box': used_box > 2 }"
                    class="box-item rounded-lg items-center justify-center flex text-center">
                    <p class="font-semi-bold">10,000</p>

                  </div>
                </div>
                <!-- third Box -->
                <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                  <div @click="used_box > 3 ? null : selectBox('purchase', 100000)"
                    :class="{ 'selected-box': selectedBoxPurchase === 3, 'unselected-box': selectedBoxPurchase !== 3, 'used-box': used_box > 3 }"
                    class="box-item rounded-lg items-center justify-center flex text-center">
                    <p class="font-semi-bold">100,000</p>

                  </div>
                </div>
                <!-- fourth Box -->
                <div class="item-center pt-2 justify-center px-2 flex-auto md:w-56">
                  <div @click="used_box > 4 ? null : selectBox('purchase', 1000000)"
                    :class="{ 'selected-box': selectedBoxPurchase === 4, 'unselected-box': selectedBoxPurchase !== 4, 'used-box': used_box > 4 }"
                    class="box-item rounded-lg items-center justify-center flex text-center">
                    <p class="font-semi-bold">1,000,000</p>

                  </div>
                </div>
              </div>
            </div>
            <!--END NEW DESIGN-->
            <p class="text-red-500 text-sm mt-1">
              {{ errors.credits_to_purchase }}
            </p>
            <p class="block text-sm font-bold mt-2">
              Price: ${{ formattedPrice }}
            </p>
          </div>
        </div>

      </div>
    </template>
    <template v-slot:buttons>
      <button type="submit" class="button-submit" @click="Onsubmit">
        Submit
      </button>
      <button type="button" class="button-cancel mr-2" @click="closeModal" ref="cancelButtonRef">
        Cancel
      </button>
    </template>
  </DialogContainer>
</template>
<script>
import { reactive, ref } from "vue";
import DialogContainer from "@/components/common/DialogContainer";
import formMixin from "@/mixins/formMixin";
import stripeElementsMixin from "@/mixins/stripeElementsMixin";
import eventBus from "@/utils/eventBus";
import useFormErrors from "@/api/formErrors";
import { mapGetters } from "vuex";
import useConfirmationModal from "@/api/confirmationModal";
import humanize from "humanize";


export default {
  name: "CreateRecurringPayment",
  mixins: [formMixin, stripeElementsMixin],
  components: {
    DialogContainer,
  },
  computed: {
    ...mapGetters("auth", ["userData"]),
    formattedPrice() {
      return humanize.numberFormat(this.price, 2);
    },
  },
  setup() {
    const wrapperStyles = reactive({});
    const stripeElements = reactive({});

    const { errors, formActions } = useFormErrors();
    const isOpen = ref(false);
    // const pendingUpdate = ref(false);
    const paymentMethods = ref(null);
    const paymentMethod = ref(null);
    const selectedBoxDays = ref(0);
    const selectedBoxCredits = ref(0);
    const selectedBoxPurchase = ref(0);

    const selectedBoxDicts = ref({
      "time_period": {
        7: 1,
        30: 2,
        60: 3,
        90: 4
      },
      "purchase_or_credit_limit": {
        1000: 1,
        10000: 2,
        100000: 3,
        1000000: 4
      }
    })
    return {
      isOpen,
      errors,
      formActions,
      wrapperStyles,
      stripeElements,
      // pendingUpdate,
      paymentMethods,
      paymentMethod,

      type_of_recurring: ref("Time-Based"),
      payment_method_id: ref(""),
      time_period: ref(null),
      credit_limit: ref(null),
      user: ref(null),
      is_time_period: ref(true),
      credits_to_purchase: ref(null),
      recurring_options: ref(["Time-Based", "Credit-Based"]),
      price: ref(0),
      is_update: ref(false),
      is_admin: ref(false),
      selectedBoxDays,
      selectedBoxCredits,
      selectedBoxPurchase,
      selectedBoxDicts,
      used_box: ref(0)
    };
  },
  watch: {
    type_of_recurring() {
      this.is_time_period = this.type_of_recurring === "Credit-Based" ? false : true;
      this.formActions.clearFormErrors();
      if (this.is_time_period) {
        this.used_box = null;
      }
    },
    time_period(newVal) {
      if (newVal) {
        this.errors.time_period = "";
      }
    },
    credit_limit(newVal) {
      if (newVal) {
        this.used_box = this.selectedBoxDicts['purchase_or_credit_limit'][newVal];
        this.errors.credit_limit = "";
        if(newVal > this.credits_to_purchase){
          this.credits_to_purchase_null();
        }
      }
    },
    credits_to_purchase(newVal) {
      if (newVal) {
        this.errors.credits_to_purchase = "";
        this.getPrice(newVal).then((resp) => {
          this.price = resp ? resp : 0;
        });
      }
    },
    payment_method_id(newVal) {
      if (newVal === '') {
        this.errors.payment_method_id = "This field cannot be blank.";
      } else {
        this.errors.payment_method_id = "";
      }
    },
  },
  beforeMount() {
    this.fetchPaymentMethods();
  },
  methods: {
    openModal(data) {
      if (data) {
        this.type_of_recurring = data.type_of_recurring;
        this.payment_method_id = data.payment_method_id;
        this.time_period = data.time_period;
        this.credit_limit = data.credit_limit;
        this.credits_to_purchase = data.credits_to_purchase
        this.price = data.price;
        this.is_time_period = this.type_of_recurring === "Time-Based"
        this.is_update = true;
        this.is_admin = data.is_admin;
        this.selectBox('credits', this.credit_limit);
        this.selectBox('days', this.time_period);
        this.selectBox('purchase', this.credits_to_purchase)
      }
      else {
        this.type_of_recurring = "Time-Based";
        this.payment_method_id = null;
        this.time_period = null;
        this.credit_limit = null;
        this.credits_to_purchase = null
        this.price = 0;
        this.is_update = false;
        this.is_time_period = true;
        this.is_admin = false;
        this.used_box = null;
        this.selectedBoxDays = null
        this.selectedBoxCredits = null
        this.selectedBoxPurchase = null
      }
      // this.is_time_period = true ? data?.time_period : false;
      this.isOpen = true;
      this.formActions.clearFormErrors();
    },
    closeModal() {
      this.isOpen = false;
    },
    credits_to_purchase_null() {
      this.credits_to_purchase = null;
      this.selectedBoxPurchase = null;
    },
    async fetchPaymentMethods() {
      try {
        const paymentMethods = await this.$store.dispatch("fetching/FETCH", {
          apiCall: async () => await this.$alfredService.fetchPaymentMethods(),
          handleErrors: false
        });
        this.paymentMethods = paymentMethods?.data || [];
        if (this.paymentMethods.length) {
          this.paymentMethod =
            this.paymentMethods[this.paymentMethods.length - 1];
        }
      }
      catch (error) {
        console.error("Failed to fetch payment methods:", error);
      }
    },
    OnchangeRecurring(value) {
      this.is_time_period = value === "credit_limit" ? false : true;
    },
    async Onsubmit() {
      if (!this.validateForm()) return;
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        onSubmit: () => this.submitForm(),
      });
    },
    async submitForm() {
      let payload = {
        type_of_recurring: this.type_of_recurring,
        payment_method_id: this.payment_method_id,
        credits_to_purchase: Number(this.credits_to_purchase),
      };
      if (this.is_time_period) {
        payload.time_period = this.time_period;
      } else {
        payload.credit_limit = this.credit_limit;
      }
      try {
        let response;
        if (this.is_update) {
          response = await this.$store.dispatch("fetching/FETCH", {
            apiCall: async () => await this.$alfredService.RecurringPaymentEdit(payload),
            handleErrors: false
          });
        } else {
          response = await this.$store.dispatch("fetching/FETCH", {
            apiCall: async () => await this.$alfredService.RecurringPaymentAdd(payload),
            handleErrors: false
          });
        }
        if (response) {
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: this.is_update ? "Recurring payment setting successfully updated" : "Recurring payment setting successfully added!"
            },
          });
          eventBus.trigger("update-cards", {});
        }
      }
      catch (error) {
        console.error("API call failed:", error);
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Unexpected error has occurred!",
            type: "error",
          },
        });
      }
    },
    async getPrice(credits) {
      try {
        const response = await this.$alfredService.verifyCreditPack({
          credits: credits,
          discount_code: this.discount?.name,
        });
        if (response) {
          this.errors.credits_to_purchase = ""
          return response.price;
        }
      } catch (error) {
        this.errors.credits_to_purchase = error.response.data.credits[0]
        return null;
      }
    },
    validateForm() {
      // this.formActions.clearFormErrors();
      let valid = true;
      this.errors.credits_to_purchase = this.errors.credits_to_purchase || '';
      if (this.errors.credits_to_purchase !== '') {
        valid = false;
      }
      if (!this.credits_to_purchase) {
        this.errors.credits_to_purchase = "This field cannot be blank"
        valid = false;
      }
      if (!this.payment_method_id) {
        this.errors.payment_method_id = "This field cannot be blank";
        valid = false;
      }
      if (this.is_time_period) {
        if (!this.time_period) {
          this.errors.time_period = "This field cannot be blank";
          valid = false;
        }
      } else {
        if (!this.credit_limit) {
          this.errors.credit_limit = "This field cannot be blank";
          valid = false;
        }
      }
      return valid;
    },
    selectBox(type, value) {
      const boxDict = this.selectedBoxDicts[type === 'days' ? 'time_period' : 'purchase_or_credit_limit'];
      if (type === 'days') {
        this.selectedBoxDays = boxDict[value];
        this.time_period = value;
      } else if (type === 'purchase') {
        this.selectedBoxPurchase = boxDict[value];
        this.credits_to_purchase = value;
      } else if (type === 'credits') {
        this.selectedBoxCredits = boxDict[value];
        this.credit_limit = value;
      }
    }
  },
};
</script>
<style scoped>
.box-item {
  background-color: #ffff;
  padding: 5px;
  cursor: pointer;
  position: relative;
  height: 2.5rem;
  width: 5.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* .box-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
} */

.selected-box {
  background-color: #fee2e2;
  border: 1px solid #fd7e7e !important;
}

.box-color {
  background-color: #c9c9c9;
}

.selected-color {
  background-color: #ffc4c4 !important;
}

.used-box {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-field-no-icon:disabled {
  opacity: initial;
}
</style>