<template>
  <div class="text-center pb-0 px-4 sm:px-6 lg:px-8">
    <div class="mb-6 flex flex-row-reverse">
      <SearchInput v-model="searchKeyword" @input="fetchUsersOnSearch" />
    </div>
    <div class="overflow-x-auto rounded-lg bg-white shadow">
      <div class="align-middle inline-block w-full">
        <table class="divide-gray-300 w-full text-left table-auto">
          <thead class="border-b-2">
            <tr>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <FilterableHeaderCell
                  title="Email"
                  param="email"
                  @switchOrder="onSwitchOrder"
                />
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <FilterableHeaderCell
                  title="Name"
                  param="name"
                  @switchOrder="onSwitchOrder"
                />
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <FilterableHeaderCell
                  title="Created At"
                  param="created_at"
                  @switchOrder="onSwitchOrder"
                />
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <FilterableHeaderCell
                  title="Deleted At"
                  param="deleted_at"
                  @switchOrder="onSwitchOrder"
                />
              </th>
              <th
                scope="col"
                class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td v-if="user.deleted_at"
                class="py-5 px-6 text-sm text-gray-500"
              >
                {{ user.email }}
              </td>
              <td v-else
                @click="toUserDetails(user)"
                class="py-5 px-6 text-sm text-gray-500 hover:bg-gray-50 cursor-pointer"
              >
                {{ user.email }}
              </td>
              <td v-if="user.deleted_at"
                class="py-5 px-6 text-sm text-gray-500"
              >
                {{ user.name }}
              </td>
              <td v-else
                @click="toUserDetails(user)"
                class="py-5 px-6 text-sm text-gray-500 hover:bg-gray-50 cursor-pointer"
              >
                {{ user.name }}
              </td>
              <td
                class="py-5 px-6 text-sm text-gray-500"
              >
                {{ formattedDate(user.created_at) }}
              </td>
              <td
                class="py-5 px-6 text-sm text-gray-500"
              >
                {{ formattedDate(user.deleted_at) }}
              </td>
              <td
                class="py-5 px-6 text-sm text-gray-500"
              >
                {{ updateStatus(user.verified_email_at, user.deleted_at) }}
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
          ref="paginator"
          :onPageChange="onPageChange"
          :data="paginationData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Paginator from "@/components/common/Paginator";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import SearchInput from "@/components/common/SearchInput";

export default {
  name: "UserList",
  components: {
    Paginator,
    FilterableHeaderCell,
    SearchInput,
  },
  async beforeMount() {
    await this.fetchUsers();
  },
  setup() {
    return {
      users: ref([]),
      paginationData: ref([]),
      searchKeyword: ref(""),
      ordering: ref("-created_at"),
      page: ref(1),
    };
  },
  methods: {
    formattedDate(timezone) {
      if(timezone){
        return new Date(timezone).toLocaleString("en-US");
      }else{
        return null;
      }
    },
    updateStatus(verified, deleted){
      if(deleted){
        return "Deleted"
      }else if (verified){
        return "Verified"
      }else{
        return "Not Verified"
      }
    },
    async fetchUsers() {
      let urlParams = {
        ordering: this.ordering,
        page: this.page,
        search: this.searchKeyword,
      };

      const users = await this.$alfredService.getUsers(urlParams);
      const { results, ...paginationData } = users;
      this.users = results;
      this.paginationData = paginationData;
    },
    async fetchUsersOnSearch() {
      await this.fetchUsers();
      this.page = 1;
      this.$refs.paginator.page = 1;
    },
    async onPageChange(page) {
      this.page = page;
      await this.fetchUsers();
    },
    async onSwitchOrder(ordering) {
      this.ordering = !ordering ? "-created_at" : ordering;
      await this.fetchUsers();
      this.$refs.paginator.setPage(1);
    },
    async toUserDetails({ id }) {
      await this.$router.push({
        name: "UserDetails",
        params: { userId: id },
      });
    },
  },
};
</script>
