import eventBus from "@/utils/eventBus";

export async function FETCH(
  { commit, state },
  { apiCall, fallBackTo = null, handleErrors = true }
) {
  await commit("SET_PENDING_FETCHES", state.pendingFetches + 1);
  try {
    const response = await apiCall();
    if (state.pendingFetches < 2) {
      await commit("SET_HAS_FETCHED", true);
    }
    return response;
  } catch (e) {
    if (!handleErrors) {
      throw e;
    }
    const routerPushTarget = fallBackTo || {
      name: "Dashboard",
    };
    eventBus.trigger("router-push", routerPushTarget);
   if (e?.response?.data?.code=="token_not_valid") {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          title: "Error",
          text: "Your session has timed out. Please login again.",
          type: "error",
        },
      });
    } else if (e?.response?.data?.detail) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          title: "Error",
          text: e.response.data["detail"],
          type: "error",
        },
      });
    } else if (e?.response?.status) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          text: "Unexpected error has occurred!",
          type: "error",
        },
      });
      throw e;
    }
  } finally {
    await commit("SET_PENDING_FETCHES", state.pendingFetches - 1);
  }
}

export async function RESET_STATE({ commit }) {
  await commit("SET_PENDING_FETCHES", 0);
  await commit("SET_HAS_FETCHED", false);
}
