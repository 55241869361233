export function settings(state) {
  return state.settings || {};
}

export function showUploadModal(state) {
  return state.showUploadModal;
}

export function adminStatsDate(state) {
  return state.admin_stats_date;
}