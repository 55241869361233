<template>
  <JobsModal ref="JobsModal" />
  <div
    :class="`
      sm:flex-nowrap
      w-full
      justify-center
      sm:justify-between
      items-center
      bg-white
      rounded-md
      ${isExtended ? 'p-8' : 'p-2'}
    `"
  >
    <h2 class="text-xl text-center sm:text-left text-gray-900 mb-2">
      List Evaluation
    </h2>

    <div class="flex justify-between xl:flex-row flex-col items-center">
      <div class="mt-8 md:text-left w-full">
        <div class="py-2 md:grid md:grid-cols-6">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Name
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate">
            {{ fileName }}
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Quantity
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate">
            {{ quantityText }}
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6" v-if="!isThreatEvaluation">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Bounce Estimate
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate">
            {{ undeliverablePercentage }}%
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6" v-if="!isThreatEvaluation">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Threat Estimate
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate">
            {{ threatPercentage }}%
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6" v-if="isThreatEvaluation">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Threat Estimate
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate">
            {{ threatEvaluationPercentage }}%
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Status
          </dt>
          <dd
            v-if="isCompleted & !isThreatEvaluation"
            class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate"
          >
            Evaluation Completed
          </dd>
          <dd
            v-else-if="isCompleted & isThreatEvaluation"
            class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 truncate"
          >
           Threat Evaluation Completed
          </dd>
          <dd
            v-else
            class="flex flex-col justify-center mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4"
          >
            <ProgressBar :percentage="percentageTested" />
          </dd>
        </div>
        <div class="py-2 md:grid md:grid-cols-6">
          <dt class="text-sm font-medium text-gray-900 md:col-span-2 uppercase">
            Recommendation
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 md:col-span-4 italic">
            {{ recommendation }}
          </dd>
        </div>
      </div>

      <img
        v-if="!this.isHalfCompleted"
        :src="require('@/assets/images/dashboard/pending.png')"
        alt="Pending"
      />
      <img
        v-else-if="!this.isDirty"
        :src="require('@/assets/images/dashboard/clean.png')"
        alt="Clean"
      />
      <img
        v-else
        :src="require('@/assets/images/dashboard/dirty.png')"
        alt="Dirty"
      />
    </div>

    <div v-if="!isAdmin" class="mt-5 block sm:flex justify-between flex-wrap hidden-print">
      <div>
        <div class="flex flex-row justify-center">
          <button
            @click="toTheList()"
            :disabled="isCleanJobLoading"
            class="button-submit m-0 px-6 py-3"
          >
            Hygiene List
          </button>
        </div>
      </div>
      <div>
        <!-- <div class="ml-3 flex flex-row justify-center">
          <button
            @click="threat_assessment"            
            class="button-submit m-0 px-6 py-3"
          >
           Threat Assessment
          </button>
        </div> -->
      </div>
      <div
        class="flex-1 flex flex-row align-center justify-center sm:justify-end mt-5 sm:mt-0 items-center mx-2 hidden-print"
      >
        <button class="button-icon h-8 has-tooltip">
          <SolidQuestionMarkCircleIcon class="h-6" />
          <Tooltip v-if="isCompleted">
            The list was checked but not yet cleaned.
          </Tooltip>
          <Tooltip v-else>
            Our free list health check is an estimation based on a segment of
            your list. We do not guarantee any specific results based on this
            assessment.
          </Tooltip>
        </button>
        <button
          :disabled="!isCompleted"
          @click="windowPrint"
          class="button-icon h-6"
        >
          <PrinterIcon class="h-6" />
        </button>
        <button
          :disabled="!isCompleted"
          @click="deleteJob"
          class="button-icon h-6"
        >
          <TrashIcon class="h-6" />
        </button>
        <button
          v-if="isExtended"
          class="button-icon h-8 has-tooltip"
          @click="onClose"
        >
          <XCircleIcon class="h-6" />
          <Tooltip> Close window and upload new file. </Tooltip>
        </button>
      </div>
    </div>
  </div>

  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'2xl'">
    <template v-slot:title>Success</template>
    <template v-slot:content>
      <div class="flex flex-col sm:flex-row items-center">
        <div>
          <p>We'll send you an email when your list has been cleaned.</p>
          <p class="mt-6">
            Depending on the size of your list this could take a few minutes to
            a few hours.
          </p>
        </div>
        <img
          :src="require('@/assets/images/dashboard/levelup.png')"
          alt="Completed"
          class="sm:ml-8"
        />
      </div>
      <div class="flex justify-center">
        <button
          type="button"
          @click="onClose"
          class="button-submit mt-10 sm:mt-5 sm:mb-5"
        >
          Go To Dashboard
        </button>
      </div>
    </template>
  </DialogContainer>

</template>

<script>
import {
  PrinterIcon,
  QuestionMarkCircleIcon as SolidQuestionMarkCircleIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";
import humanize from "humanize";
import useConfirmationModal from "@/api/confirmationModal";
import Tooltip from "@/components/basic/common/Tooltip";
import ProgressBar from "@/components/basic/common/ProgressBar";
import eventBus from "@/utils/eventBus";
import { ref } from "@vue/reactivity";
import formMixin from "@/mixins/formMixin";
import DialogContainer from "@/components/common/DialogContainer";
import JobsModal from "@/components/basic/common/modals/JobsModal";

export default {
  name: "HealthDetails",
  mixins: [formMixin],
  components: {
    SolidQuestionMarkCircleIcon,
    PrinterIcon,
    TrashIcon,
    XCircleIcon,
    Tooltip,
    ProgressBar,
    DialogContainer,
    JobsModal
  },
  props: {
    isExtended: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    jobData: {
      type: Object,
    },
  },
  setup() {
    const isCleanJobLoading = ref(false);
    const isOpen = ref(false);
    const price = ref("")
    return { isCleanJobLoading,isOpen,price,JobsModal: ref() };
  },
  computed: {
    isDirty() {
      return (
        this.isHalfCompleted &&
        (Number(this.threatPercentage) >= 20 ||
          Number(this.undeliverablePercentage) >= 3)
      );
    },
    recommendation() {
      if (!this.isHalfCompleted) {
        return "Pending";
      }
      if (this.isDirty) {
        return "This list should be hygiened prior to mailing.";
      } else {
        return "This looks good! Cleaning is not required.";
      }
    },
    totalCount() {
      return this.$props.jobData?.emails_count || 0;
    },
    sampledCount() {
      return (
        this.$props.jobData?.result?.evaluation_job?.deliverability?.sampled ||
        0
      );
    },
    isThreatEvaluation() {
      if (this.$props.jobData?.actions.includes('threat_evaluation')){
        return true
      }else {
        return false
      }
    },
    count() {
      return (
        this.$props.jobData?.result?.evaluation_job?.deliverability?.analysed
          ?.count || 0
      );
    },
    undeliverableCount() {
      const analysed =
        this.$props.jobData?.result?.evaluation_job?.deliverability?.analysed;
      return Number(analysed?.undeliverable) || 0;
    },
    undeliverablePercentage() {
      if (!this.count) {
        return 0;
      }
      return humanize.numberFormat(
        (this.undeliverableCount * 100) / this.count,
        0
      );
    },
    percentageTested() {
      if (this.$props.jobData?.emails_count === undefined) {
        return 0;
      }
      if (this.$props.jobData?.actions.includes('threat_evaluation')){
        return 100;
      }else{
        return Math.floor((this.count * 100) / this.sampledCount) || 0;
      }
      
    },

    quantityText() {
      if (this.$props.jobData?.emails_count === undefined) {
        return "N/A";
      }
      return `${humanize.numberFormat(this.totalCount, 0)} (${
        this.percentageTested
      }% tested)`;
    },
    riskyCount() {
      const analysed =
        this.$props.jobData?.result?.evaluation_job?.deliverability?.analysed;
      const disposable = Number(analysed?.disposable) || 0;
      const acceptAll = Number(analysed?.acceptAll) || 0;
      return disposable + acceptAll;
    },
    threatPercentage() {
      if (!this.count) {
        return 0;
      }
      const threatMatchedCount =
        Number(
          this.$props.jobData?.result?.evaluation_job?.threat?.matched_count
        ) || 0;
      return humanize.numberFormat(
        (threatMatchedCount * 100) / this.totalCount,
        0
      );
    },
    threatEvaluationPercentage() {
      const threatMatchedCount =
        Number(
          this.$props.jobData?.result?.threat_evaluation_job?.threat?.matched_count
        ) || 0;
      return humanize.numberFormat(
        (threatMatchedCount * 100) / this.totalCount,
        0
      );
    },
    riskyPercentage() {
      if (!this.count) {
        return 0;
      }
      return humanize.numberFormat((this.riskyCount * 100) / this.count, 0);
    },
    fileName() {
      return this.$props.jobData?.name || "N/A";
    },
    isCompleted() {
      return this.$props.jobData?.status === "COMPLETED";
    },
    isHalfCompleted() {
      if (this.percentageTested >= 50 || this.isCompleted) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    toTheList() {
      let job = this.$props.jobData;
      job = this.processJobs(job)
      this.JobsModal.openModal({ job });
    },
    processJobs(job) {      
        const isEvaluation = job.actions.includes("evaluation");
        const isThreat = job.actions.includes("threat_assessment");
        const isDeliv = job.actions.includes("deliverability");
        return {
          id: job.id,
          input_file: job.upload_id,
          upload_id: job.upload_id,
          basicData: {
            createdAt: this.formattedDate(job.created),
            emailsCount: this.totalEmails(job.emails_count),
          },
          statusData: {
            displayName: "Evaluated"
          },
          hasResultFile: !!job.result_file,
          isToBeCleaned: isEvaluation,
          isThreatAssessed: isThreat,
          isToBeRemoved: ["COMPLETED", "ERROR", "DELETED"].includes(job.status),
          result: job.result,
          isDeliv: isDeliv
        };   
    },
    formattedDate(timezone) {
      return new Date(timezone).toLocaleString("en-US");
    },
    totalEmails(count) {
      return humanize.numberFormat(count, 0);
    },
    cleanThisList() {
      this.isCleanJobLoading = true;
      eventBus.trigger("clean-the-list", {
        job: this.$props.jobData,
        toggleModal: !this.$props.isExtended,
        callback: () => (this.isCleanJobLoading = false),
      });
    },
    async threat_assessment() {
      this.price = Math.ceil(parseInt(this.$props.jobData?.emails_count) * 0.4);
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to threat assess this list?",
        message: `It is going to cost <strong>${this.price} Credits</strong>.`,
        onSubmit: () => this.threat_assessment_func()
      });
    },
    async threat_assessment_func() {     
      const response = await this.handleSubmissionErrors(
        async () =>
          this.$alfredServiceV2.createJob({
            input_file:{
              id: this.$props.jobData.input_file.id.replace(/-/g, '')
            },
            actions: ["threat_assessment"],
          }),
        { throwExceptions: true }
      );
      if (response) {
        this.openModal();
      }
    },
    windowPrint() {
      window.print();
    },
    async deleteJob() {
      if (!this.$props.isExtended) {
        eventBus.trigger("close-details");
      }
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to delete this file?",
        message: "This action cannot be undone.",
        onSubmit: async () => {
          await this.$alfredService.deleteJob(this.$props.jobData.id);
          await this.$router.push({
            name: "Dashboard",
          });
        },
        onClose: () => {
          if (!this.$props.isExtended) {
            eventBus.trigger("open-details", this.$props.jobData);
          }
        },
      });
    },
    onClose() {
      this.$router.push({
        name: "Dashboard",
      });
    },
  },
};
</script>
