<template>
  <div class="py-5">
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select
        id="tabs"
        name="tabs"
        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md cursor-pointer"
      >
        <option
          v-for="(menuitem, index) in data"
          :key="index"
          v-show="menuitem.visible"
        >
          {{ menuitem.title }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <a
            v-for="(menuitem, index) in data"
            :key="index"
            @click="selectTab(index)"
            class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
            :class="{
              'border-red-500 text-red-600': selectedIndex == index,
              'border-transparent text-gray-500 cursor-pointer hover:text-gray-700 hover:border-gray-300':
                selectedIndex != index,
            }"
            v-show="menuitem.visible"
          >
            {{ menuitem.title }}
          </a>
        </nav>
      </div>
    </div>
    <div
      v-for="(dashbordItem, indexi) in data"
      :key="indexi"
      v-show="selectedIndex == indexi && data[indexi].visible"
    >
      <h3 class="text-lg leading-6 font-medium text-gray-900"></h3>
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div
          v-for="(item, index) in data[indexi].data"
          :key="index"
          class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
        >
          <dt class="text-md font-medium text-gray-500 truncate">
            {{ data[indexi].labels[index].text }}
          </dt>
          <dd
            :class="`mt-1 text-2xl font-semibold ${data[indexi].labels[index].color_class}`"
            v-if="indexi !== 0"
          >
            {{ formatValue(item, indexi) }}
            <span class="mt-1 text-sm font-medium text-gray-700"
              >{{ formatNumber(item) }}
            </span>
          </dd>
          <dd
            :class="`mt-1 text-2xl font-semibold ${data[indexi].labels[index].color_class}`"
            v-if="indexi == 0"
          >
            {{ formatValueObjective(item) }}
            <span class="mt-1 text-sm font-medium text-gray-700"
              >{{ formatNumber(item) }}
            </span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import humanize from "humanize";
export default {
  name: "TabContent",
  data() {
    return {
      selectedIndex: 0,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.arraySort();
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
    },
    formatValue(value, index) {
      const percentage =
        Math.round((value * 100) / this.totalValue(index) || 0) + "%";

      return percentage;
    },
    formatValueObjective(value) {
      const percentage = Math.round((value * 100) / this.count || 0) + "%";

      return percentage;
    },
    totalValue(index) {
      return (
        this.data &&
        this.data[index]?.data?.reduce((acc, curr) => acc + curr, 0)
      );
    },
    formatNumber(count) {
      return ` (${humanize.numberFormat(count, 0)})`;
    },
    arraySort() {
      console.log("datttaa", this.data.data);
    },
  },
};
</script>
