import eventBus from "@/utils/eventBus";

const modalMixin = {
  beforeMount() {
    eventBus.on(this.modalEventName, this.openModal);
  },
  beforeUnmount() {
    eventBus.off(this.modalEventName, this.openModal);
  },
};

export default modalMixin;
