export function SET_USER_DATA(state, payload) {
  state.userData = payload;
}

export function SET_ACCESS_TOKEN(state, { accessToken }) {
  state.accessToken = accessToken;
}

export function SET_REFRESH_TOKEN(state, { refreshToken }) {
  state.refreshToken = refreshToken;
}

export function SET_EXPIRATION_TIME(state, { expirationTime }) {
  state.expirationTime = expirationTime;
}
