import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/auth";
import fetching from "@/store/fetching";
import settings from "@/store/settings";
import user from "@/store/admin/user";
import Cookies from "js-cookie";

const initialState = {
  fetching: { ...fetching.state },
  settings: { ...settings.state },
  user: { ...user.state },
};

/* eslint-disable no-new */
const store = createStore({
  plugins: [
    createPersistedState({
      paths: ["auth"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 7, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  modules: {
    auth,
    fetching,
    settings,
    user,
  },
  mutations: {
    RESET(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
});

export default store;
