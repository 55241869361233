export async function FETCH_SETTINGS({ commit }) {
  const response = await this.$alfredService.fetchPublicSettings();
  commit("SET_SETTINGS", response);
}

export async function SET_SHOW_UPLOAD_MODAL({ commit }, value) {
  commit("SET_SHOW_UPLOAD_MODAL", value);
}

export async function SET_ADMIN_STATS_DATE({ commit }, value) {
  commit("SET_ADMIN_STATS_DATE", value);
}