export default function () {
  return {
    settings: {
      AUTO_DELETE_DAYS: 14,
      FREE_CREDITS: 250,
    },
    showUploadModal: false,
    admin_stats_date: {
      start_date: "",
      end_date: ""
    }
  };
}
