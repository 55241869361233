<template>
  <Container>
    <template v-slot:header>
      <span class="font-medium text-xl"
        >{{ user.email }}:
        <span name="user-balance">
          {{ totalEmails(user.balance) }} Credits</span
        >
      </span>
    </template>
    <template v-slot:content>
      <div class="bg-white shadow rounded-lg mb-6">
        <div class="border-b border-gray-200 pt-6 sm:mx-6">
          <div class="sm:hidden sm:mx-16">
            <label for="current-tab" class="sr-only">Select a tab</label>
            <select
              id="current-tab"
              name="current-tab"
              @change="changeTab($event.target.value)"
              class="form-field-no-icon shadow-sm text-sm w-full"
            >
              <option
                v-for="tab in tabs"
                :key="tab.name"
                :value="tab.name"
                :selected="tab.current"
              >
                {{ tab.name }}
              </option>
            </select>
          </div>
          <div class="hidden sm:block">
            <nav class="-mb-px flex space-x-8">
              <a
                @click="changeTab(tab.name)"
                v-for="tab in Object.values(tabs)"
                :key="tab.name"
                :class="[
                  tab.current
                    ? 'current'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                ]"
                :aria-current="tab.current ? 'page' : undefined"
              >
                {{ tab.name }}
              </a>
            </nav>
          </div>
        </div>
        <div class="mt-6">
          <section v-show="tabs.usage.current">
            <UsageTable
              :data="data"
              :paginationData="paginationData"
              :singleUser="true"
              :margin="`sm:ml-6`"
              @stepper="winStepper"
            />
          </section>
          <section v-if="tabs.pricing.current">
            <div class="mt-4 max-w-lg flex flex-col items-start pb-6 px-6">
              <span class="block text-sm font-medium text-gray-700">
                Set credit quantity and price thresholds
              </span>
              <PricingAdminTable :userId="userId" />
            </div>
          </section>
          <section v-if="tabs.summary.current">
            <div class="mt-4 max-w-lg flex flex-col items-start pb-6 px-6">
              <span class="block text-sm font-medium text-gray-700"> </span>
              <UsageSummary :userId="userId" />
            </div>
          </section>
          <div
            v-if="tabs.userCreditsBalance.current"
            class="pb-6 px-6 sm:max-w-lg"
          >
            <UserCreditsBalanceAdminForm :userId="userId" />
          </div>
          <div v-if="tabs.autoDelete.current" class="pb-6 px-6 sm:max-w-lg">
            <AutoDeleteAdminForm :userId="userId" />
          </div>
          <div v-if="tabs.partner_setting.current" class="pb-6 px-6">
            <PartnerSettings :userId="userId"/>
          </div>
          <div v-show="tabs.recurring_payment.current" class="pb-6 px-6">
            <RecurringPaymentAdmin :userId="userId"/>
          </div>
          <div v-if="tabs.linked_accounts.current" class="pb-6 px-6">
            <LinkedAccounts :userId="userId" @goToUser=goToUser />
          </div>
          <div v-if="tabs.password_reset.current" class="pb-6 px-6">
            <form @submit.prevent="resetCall" class="space-y-6" action="#">
            <div>
              <button type="submit" class="button-submit m-0">
                Initiate Password Reset
              </button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import { mapGetters } from "vuex";
import { ref, reactive } from "vue";
import PricingAdminTable from "@/components/admin/common/PricingAdminTable";
import PartnerSettings from "@/components/admin/common/PartnerSettings";
import RecurringPaymentAdmin from "@/components/admin/common/RecurringPaymentAdmin";
import LinkedAccounts from "@/components/admin/common/LinkedAccounts";
import UsageSummary from "@/components/admin/common/UsageSummaryAdminForm";
import AutoDeleteAdminForm from "@/components/admin/common/AutoDeleteAdminForm";
import UserCreditsBalanceAdminForm from "@/components/admin/common/UserCreditsBalanceAdminForm";
import UsageTable from "@/components/common/usage-page/UsageTable";
import eventBus from "@/utils/eventBus";
import humanize from "humanize";
import formMixin from "@/mixins/formMixin";

export default {
  name: "UserDetails",
  mixins: [formMixin],
  props: {
    userId: Number,
  },
  components: {
    Container,
    UsageTable,
    UsageSummary,
    PricingAdminTable,
    AutoDeleteAdminForm,
    UserCreditsBalanceAdminForm,
    PartnerSettings,
    RecurringPaymentAdmin,
    LinkedAccounts
  },
  setup() {
    return {
      paginationData: ref({}),
      data: ref([]),
      myPage: ref(1),
      tabs: reactive({
        usage: { name: "Usage", current: true },
        summary: { name: "Usage Summary", current: false },
        pricing: { name: "Pricing", current: false },
        userCreditsBalance: { name: "Free credits", current: false },
        autoDelete: { name: "Auto-delete", current: false },
        partner_setting: { name: "Partner Settings", current: false },
        recurring_payment: { name: "Recurring Payment", current: false},
        linked_accounts: { name: "Linked Accounts", current: false },
        password_reset: { name: "Reset Password", current: false },
      }),
    };
  },
  computed: {
    ...mapGetters({ user: "user/getUser" }),
  },
  async beforeMount() {
    await this.$store.dispatch("user/FETCH_USER", { userId: this.userId });
    eventBus.on("update-usage", this.fetchJobs);
    await this.fetchJobs();
  },
  beforeUnmount() {
    eventBus.off("update-usage", this.fetchJobs);
  },
  methods: {
    async resetCall() {
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.resetPassword({ email: this.user.email }),
        {}
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Request has been sent. Check your user's email to confirm the process.",
            type: "success",
          },
        });
      }
    },
    winStepper(winData) {
      if (winData.myPage) {
        this.myPage = winData.myPage;
      }
    },
    async fetchJobs(urlParams = {}) {
      urlParams = {
        ...urlParams,
        created_by__id: this.userId,
        page: this.myPage,
      };
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.fetchAdminJobs(urlParams),
      });
      if (response) {
        this.data = response.results || [];
        delete response.results;
        this.paginationData = response;
        this.myPage = 1;
      }
    },
    totalEmails(count) {
      return humanize.numberFormat(count, 0);
    },
    changeTab(currentName) {
      Object.values(this.tabs).forEach(
        (tab) => (tab.current = tab.name === currentName)
      );
    },
    async goToUser(){
    await this.$store.dispatch("user/FETCH_USER", { userId: this.userId });
    eventBus.on("update-usage", this.fetchJobs);
    await this.fetchJobs();
    this.changeTab("Usage");
    }
  },
};
</script>
<style scoped>
.current {
  border-color: var(--main-color);
  color: var(--main-color);
}
</style>
