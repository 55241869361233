<template>
  <div v-if="data?.length" class="overflow-x-auto rounded-lg mt-5">
    <CreditLimitPopup
      :userId="selectedUserId"
      :subuserCredits="selectedUserCredits"
      :allowBillingAccess="allowBilling"
      v-if="creditPopup"
      @close="Closepopup"
    />
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="columnName in columnNames"
              :key="columnName"
              scope="col"
              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left"
            >
              {{ columnName }}
            </th>
          </tr>
        </thead>

        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in data" :key="index">
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ row.name }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ row.email }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ formattedDate(row.created_at) }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ updateStatus(row.verified_email_at, row.deleted_at) }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <div class="flex w-full" v-if="row.deleted_at === null">
                <p
                  class="whitespace-nowrap"
                  style="
                    min-width: 110px;
                    max-width: 110px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{
                    row.credits !== null
                      ? formatN(row.credits)
                      : "Same as parent"
                  }}
                </p>

                <div class="flex items-center justify-center">
                  <button
                    @click="Openpopup(row.id, row.credits, row.allow_billing)"
                    class="button-icon"
                    aria-label="Edit Credits"
                    title="Set credit limit"
                  >
                    <PencilIcon class="h-5" />
                  </button>
                </div>
              </div>
            </td>

            <td class="px-6 py-5 text-sm text-gray-500 text-left trash-cell">
              <div>
                <button
                  v-if="
                    updateStatus(row.verified_email_at, row.deleted_at) ===
                    'Deleted'
                  "
                  class="button-icon text-red-400 hover:underline"
                  @click="reactivateUser(row.email)"
                >
                  Reactivate&nbsp;Account
                </button>
                <div class="flex" v-else>
                  <button
                    class="button-icon text-red-400 hover:underline"
                    @click="ShowPasswordModal(row.email)"
                  >
                    Reset&nbsp;Password
                  </button>
                  <button
                    @click="onDeleteUser(row.email)"
                    class="button-icon pl-3"
                  >
                    <TrashIcon class="h-6" />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6">
          <div
            class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg"
          >
            <h3
              id="headlessui-dialog-title-12"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              <div class="pb-3">Reset password for {{ email }}</div>
            </h3>
            <div class="sm:col-span-6">
              <div class="pb-3">
                <label for="password"> Password </label>
                <div class="mt-1">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    v-model="password"
                    autocomplete="current-password"
                    required=""
                    class="form-field-no-icon w-full"
                  />
                  <p class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
                </div>
              </div>

              <div class="pb-3">
                <label for="password"> Confirm Password </label>
                <div class="mt-1">
                  <Field
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    v-model="confirmPassword"
                    autocomplete="current-password"
                    required=""
                    class="form-field-no-icon w-full"
                  />
                  <p class="text-red-500 text-sm mt-1">
                    {{ errors.confirm_password }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b"
            >
              <button
                type="submit"
                class="button-submit sm:ml-3"
                @click="submit"
              >
                Reset
              </button>
              <input
                type="button"
                class="button-cancel"
                value="Cancel"
                @click="showPopup = false"
              />
            </div>
          </div>
        </Form>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref } from "vue";
import { TrashIcon } from "@heroicons/vue/outline";
import { PencilIcon } from "@heroicons/vue/solid";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import formMixin from "@/mixins/formMixin";
import { Field, Form } from "vee-validate";
import CreditLimitPopup from "./modals/EditCreditPopup.vue";
import humanize from "humanize";

export default {
  name: "AddUserTable",
  components: {
    TrashIcon,
    PencilIcon,
    Field,
    Form,
    CreditLimitPopup,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    eventBus.trigger("update-subuser-data");
  },
  setup() {
    const columnNames = ref([
      "Name",
      "Email",
      "Created",
      "Status",
      "Credits",
      "Action",
    ]);
    const allowBilling = ref(false);

    return {
      columnNames,
      showPopup: ref(false),
      email: ref(""),
      password: "",
      confirmPassword: "",
      creditPopup: ref(false),
      selectedUserId: ref(null),
      selectedUserCredits: ref(null),
      allowBilling,
    };
  },
  methods: {
    async onSubmit(values, actions) {
      if (this.email) {
        values.email = this.email;
      }
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.resetSubUserPassword(values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully changed password.",
            type: "success",
          },
        });
        this.password = "";
        this.confirmPassword = "";
        this.showPopup = false;
      }
    },
    async onDeleteUser(email) {
      const email_ = encodeURIComponent(email);
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to delete this user?",
        onSubmit: async () => {
          this.$alfredService.delete_user(email_);
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "User successfully deleted!",
            },
          });
          eventBus.trigger("update");
          eventBus.trigger("update-subuser-data");
        },
      });
    },
    formattedDate(timezone) {
      return new Date(timezone).toLocaleString("en-US");
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    openUploadListModal() {
      this.uploadListModal.openModal();
    },
    updateStatus(verified, deleted) {
      if (deleted) {
        return "Deleted";
      } else if (verified) {
        return "Verified";
      } else {
        return "Not Verified";
      }
    },
    async reactivateUser(email) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to reactivate this user?",
        onSubmit: async () => {
          this.$alfredService.reactivate_user(email);
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "User successfully reactivated!",
            },
          });
          eventBus.trigger("update-subuser-data");
        },
      });
    },
    ShowPasswordModal(email) {
      this.email = email;
      this.showPopup = true;
    },
    Openpopup(id, credits, allowbill) {
      this.selectedUserId = id;
      this.selectedUserCredits = credits;
      this.allowBilling = allowbill;
      this.creditPopup = true;
    },
    Closepopup() {
      this.creditPopup = false;
    },
  },
};
</script>
<style scoped>
.input-cell {
  min-width: 200px;
}
</style>
