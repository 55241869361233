<template>
  <div v-if="thresholds?.length">
    <label for="amount"> Credit Pack </label>
    <div class="mt-1 sm:flex flex-col">
      <template
        v-for="(threshold, index) in thresholds"
        :key="threshold.credits"
      >
        <div>
          <input
            type="checkbox"
            class="mr-2 form-checkbox h-4 w-4 text-gray-700"
            @click="onSelectCheckbox(index)"
            :disabled="disabled(index, threshold)"
            :checked="selectedIdx === index"
          />
          <span
            type="text"
            :class="`sm:flex-1 sm:text-sm border-transparent text-gray-700 p-1 ${
              isNotConsidered(index, threshold.credits) ? 'opacity-50' : ''
            }`"
          >
            {{
              `$${formatPrice(threshold.price)} (${formatCredits(
                threshold.credits
              )} credits)`
            }}
            <template v-if="index === 0 && requiredCredits?.credits">
              {{ ` exact amount needed` }}
            </template>
            <template v-else>
              {{ threshold.savings ? ` SAVE ${threshold.savings}%` : "" }}
            </template></span
          >
        </div>
      </template>
    </div>
  </div>
  <div v-else class="flex items-center justify-center w-full h-40">
    <PulseLoader color="#ff5a5f" />
  </div>
</template>
<script>
import { ref } from "vue";
import humanize from "humanize";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import PulseLoader from "vue-spinner/src/PulseLoader";

export default {
  name: "ThresholdCheckboxList",
  mixins: [formatNumberMixin],
  components: {
    PulseLoader,
  },
  props: {
    thresholds: {
      type: Array,
      required: true,
    },
    requiredCredits: {
      type: Object,
      default: null,
    },
    userCredits: {
      type: Number,
      required: true,
    },
    selectionObligatory: {
      type: Boolean,
      default: false,
    },
    minimalPurchase: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    return {
      selectedIdx: ref(-1),
    };
  },
  mounted() {
    this.selectedIdx = this.$props.selectionObligatory ? 0 : -1;
  },
  watch: {
    thresholds: {
      deep: true,
      handler() {
        if (this.selectedIdx !== -1) {
          this.$emit("onChosen", this.thresholds[this.selectedIdx]);
        }
      },
    },
  },
  methods: {
    onSelectCheckbox(idx) {
      this.selectedIdx = this.selectedIdx === idx ? -1 : idx;
      if (this.selectedIdx === -1) {
        this.$emit("onChosen", null);
      } else {
        this.$emit("onChosen", this.thresholds[this.selectedIdx]);
      }
    },
    formatPrice(price) {
      return humanize.numberFormat(price, 2);
    },
    formatCredits(price) {
      return humanize.numberFormat(price, 0);
    },
    disabled(idx, { credits }) {
      return (
        (this.selectionObligatory && this.selectedIdx === idx) ||
        this.isNotConsidered(idx, credits)
      );
    },
    isNotConsidered(idx, credits) {
      return (
        this.selectedIdx !== idx &&
        idx !== 0 &&
        this.requiredCredits &&
        credits <= this.requiredCredits.credits
      );
    },
    resetSelection() {
      this.selectedIdx = -1;
    },
  },
};
</script>
<style scoped>
input[type="checkbox"]:focus {
  --tw-ring-color: var(--main-color);
}
</style>
