<template>
    <DialogContainer :isOpen="isOpen" :onClose="onClose" :maxWidth="'2xl'">
      <template v-slot:title>NOTE</template>
      <template v-slot:content>
        <div class="flex flex-col sm:flex-row items-center">
          <div>
            <p>Please note that free email addresses get only {{credits}} credits to start.</p>
            <p class="mt-6">
                If you'd like to take advantage of the full 250 free credits, please sign up with a work address.
            </p>
          </div>
        </div>
        <div class="flex justify-center">
            <button
            type="button"
            @click="onClose"
            class="button-cancel mt-10 sm:mt-5 sm:mb-5"
          >
            Go back
          </button>
          <button
            type="button"
            @click="onProceed"
            class="button-submit mt-10 sm:mt-5 sm:mb-5 ml-3"
          >
            Proceed
          </button>
        </div>
      </template>
    </DialogContainer>
  </template>
  <script>
  import { ref } from "vue";
  import DialogContainer from "@/components/common/DialogContainer";
  import modalMixin from "@/mixins/modalMixin";
  
  export default {
    name: "DomainControlPopup",
    mixins: [modalMixin],
    components: {
      DialogContainer,
    },
    props: {
    credits: {
      type: Number,
      required: true,
    }
    },
    setup() {
      const isOpen = ref(false);
      const modalEventName = "show-domain-control";
  
      return {
        isOpen,
        modalEventName,
      };
    },
    methods: {
      openModal() {
        this.isOpen = true;
      },
      onProceed(){
        this.$emit('on-proceed', true);
        this.isOpen = false;
      },
      onClose() {
        this.isOpen = false;
      },
    },
  };
  </script>
  