import moment from "moment";
import { JWT_EXPIRATION_SEC } from "@/config";
import eventBus from "@/utils/eventBus";

export async function LOGIN({ commit, dispatch }, { email, password }) {
  try {
    const response = await this.$alfredService.login({
      email,
      password,
    });
    await commit("SET_EXPIRATION_TIME", {
      expirationTime: moment().add(JWT_EXPIRATION_SEC, "seconds"),
    });
    await commit("SET_ACCESS_TOKEN", {
      accessToken: response["access"],
    });
    await commit("SET_REFRESH_TOKEN", {
      refreshToken: response["refresh"],
    });
    dispatch("FETCH_USER_DATA");
    return true;
  } catch (e) {
    if (e?.response?.data?.detail) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          title: "Error",
          text: e.response.data["detail"],
          type: "error",
        },
      });
      await dispatch("LOGOUT");
      return false;
    } else {
      await dispatch("LOGOUT");
      throw e;
    }
  }
}

export async function FETCH_USER_DATA({ commit, dispatch }) {
  try {
    const response = await this.$alfredService.getUser();
    commit("SET_USER_DATA", response);
  } catch (e) {
    await dispatch("LOGOUT");
    throw e;
  }
}

export async function REFRESH_TOKEN({ commit, state, dispatch }) {
  try {
    const response = await this.$alfredService.refreshToken({
      refresh: state.refreshToken,
    });
    await commit("SET_EXPIRATION_TIME", {
      expirationTime: moment().add(JWT_EXPIRATION_SEC, "seconds"),
    });
    await commit("SET_ACCESS_TOKEN", {
      accessToken: response.access,
    });
  } catch (e) {
    await dispatch("LOGOUT");
    throw e;
  }
}

export async function LOGOUT({ commit }) {
  await commit("SET_EXPIRATION_TIME", {
    expirationTime: null,
  });
  await commit("SET_ACCESS_TOKEN", {
    accessToken: null,
  });
  await commit("SET_REFRESH_TOKEN", {
    accessToken: null,
  });
  await commit("SET_USER_DATA", null);
  await commit("RESET", {}, { root: true });
  eventBus.trigger("logout", {});
}
