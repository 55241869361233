<template>
  <DialogContainer :isOpen="isOpen" :onClose="closeModal" :maxWidth="'lg'">
    <template v-slot:title>
      <!-- div class="pb-2" v-if="enabled">
        Download-
        <div class="truncate max-w-xs front-xs font-semibold">
          {{ job.input_file.name }}
        </div>
      </div> -->
      <div class="pb-1">
        Which type of emails addresses would you like to download?
      </div>
    </template>

    <template v-slot:content>
      <!-- <div v-if="!enabled" class="">
        <div class="border-solid border-t border-inherit leading-relaxed"></div>
        <fieldset>
          <legend class="sr-only">Notifications</legend>
          <div class="divide-y divide-gray-200">
            <div class="relative flex items-start py-4">
              <div class="min-w-0 flex-1 text-sm">
                <label for="all" class="font-bold text-gray-600">All</label>
                <p id="comments-all" class="text-gray-400">
                  Includes all classifications, scores, and threat levels.
                </p>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-all"
                  aria-describedby="comments-all"
                  @click="uncheckOthers()"
                  checked="allSelected"
                  name="comments"
                  type="checkbox"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300 rounded"
                />
              </div>
            </div>
            <div class="relative flex items-start py-3">
              <div class="min-w-0 flex-1 text-sm">
                <label for="safetosend" class="font-bold text-gray-600"
                  >Safe to send</label
                >
                <p id="safetosend-description" class="text-gray-400">
                  Deliverable with low threat level
                </p>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-safetosend"
                  @click="clicktoUncheck()"
                  aria-describedby="safetosend-description"
                  name="candidates"
                  type="checkbox"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300 rounded"
                />
              </div>
            </div>
            <div class="relative flex items-start py-3">
              <div class="min-w-0 flex-1 text-sm">
                <label for="sendwithcaution" class="font-bold text-gray-600"
                  >Send with caution</label
                >
                <p id="sendwithcaution-description" class="text-gray-500">
                  Catch-all, disposable, mailbox full, unknown, or moderate
                  threat level
                </p>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-sendwithcaution"
                  @click="clicktoUncheck()"
                  aria-describedby="sendwithcaution-description"
                  name="offers"
                  type="checkbox"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300 rounded"
                />
              </div>
            </div>
            <div class="relative flex items-start py-3">
              <div class="min-w-0 flex-1 text-sm">
                <label for="donotsend" class="font-bold text-gray-600"
                  >Do not send</label
                >
                <p id="donotsend-description" class="text-gray-400">
                  Undeliverable, mailbox disabled, or malicious
                </p>
              </div>
              <div class="ml-3 flex items-center h-5">
                <input
                  id="cb-donotsend"
                  aria-describedby="donotsend-description"
                  @click="clicktoUncheck()"
                  name="offers"
                  type="checkbox"
                  class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300 rounded"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div> -->

      <div
        v-if="!enabled"
        class="sm:max-h-80 border-t pt-5 border-b border-inherit"
      >
        <fieldset>
          <legend class="sr-only">Notifications</legend>

          <div class="relative flex items-start py-2">
            <div class="ml-3 flex items-center h-5">
              <input
                id="cb-all"
                aria-describedby="comments-all"
                checked="allSelected"
                name="comments"
                type="radio"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm px-3">
              <label for="all" class="font-bold text-gray-600 pb-1">All</label>
              <p
                id="comments-all"
                class="text-gray-400 text-sm leading-relaxed text-justify"
              >
                Includes all classifications, scores, and threat levels.
              </p>
            </div>
          </div>
          <div class="relative flex items-start py-2">
            <div class="ml-3 flex items-center h-5">
              <input
                id="cb-bestDeliverability"
                aria-describedby="comments-all"
                name="comments"
                type="radio"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm px-3">
              <label for="all" class="font-bold text-gray-600 pb-1"
                >Best Deliverability</label
              >
              <p
                id="comments-all"
                class="text-gray-400 text-sm leading-relaxed text-justify"
              >
                Download the
                <span class="font-bold text-black" v-if="bestDeliveryCount > 1"
                  >{{ bestDeliveryCount }} emails</span
                >
                <span class="font-bold text-black" v-if="bestDeliveryCount == 1"
                  >{{ bestDeliveryCount }} email</span
                >

                that Alfred determined to have the highest deliverability and
                lowest threat.
              </p>
            </div>
          </div>
          <div class="relative flex items-start py-2">
            <div class="ml-3 flex items-center h-5">
              <input
                id="cb-maximumReach"
                aria-describedby="safetosend-description"
                name="comments"
                type="radio"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-red-300"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm px-3 pb-5">
              <label for="safetosend" class="font-bold text-gray-600 pb-1"
                >Maximum Reach
                <span class="inline-flex font-normal flex-wrap">
                  <span
                    class="text-xs px-2 text-green-800 bg-green-100 rounded-full border-gray-200"
                    >Most Popular</span
                  >
                </span>
              </label>

              <p
                id="safetosend-description"
                class="text-gray-400 text-sm leading-relaxed text-justify"
              >
                Download the
                <span class="font-bold text-black" v-if="maximumReachCount > 1"
                  >{{ maximumReachCount }} emails</span
                >
                <span class="font-bold text-black" v-if="maximumReachCount == 1"
                  >{{ maximumReachCount }} email</span
                >
                that Alfred determined to be likely deliverable with
                low-to-moderate threat levels (this may include unknowns and
                catch-alls). Undeliverable addresses, disposable domains, and
                high-risk contacts are excluded.
              </p>
            </div>
          </div>
        </fieldset>
      </div>

      <div
        v-if="enabled"
        class="overflow-auto sm:max-h-80 overflow-y-auto border-t pt-4 border-b border-inherit"
      >
        <fieldset class="">
          <legend class="text-sm font-bold text-gray-600">Status</legend>
          <div
            v-for="(label, labelIdx) in status"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickStatuses(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="border-solid border-t border-inherit"></div>
        <br />
        <fieldset>
          <legend class="text-sm font-bold text-gray-600">Rating</legend>
          <div
            v-for="(label, labelIdx) in rating"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickRatings(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="border-solid border-t border-inherit"></div>
        <br />
        <fieldset>
          <legend class="text-sm font-bold text-gray-600">Score</legend>
          <div
            v-for="(label, labelIdx) in score_values"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickScore(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="border-solid border-t border-inherit"></div>
        <br />
        <fieldset>
          <legend class="text-sm font-bold text-gray-600">Threat level</legend>
          <div
            v-for="(label, labelIdx) in threatlevel"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickThreat(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
        <div class="border-solid border-t border-inherit"></div>
        <br />

        <fieldset>
          <legend class="text-sm font-bold text-gray-600">Reason</legend>
          <div
            v-for="(label, labelIdx) in reason"
            :key="labelIdx"
            class="relative flex items-start py-2 space-x-3"
          >
            <div class="ml-3 flex items-center h-5">
              <input
                :id="`label-${label.name}`"
                :name="`label-${label.name}`"
                type="checkbox"
                @click="clickReason(label.id, label.name)"
                class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
              />
            </div>
            <div class="min-w-0 flex-1 text-sm">
              <label
                :for="`label-${label.id}`"
                class="font-medium text-gray-400 select-none"
                >{{ humanize(label.name) }}</label
              >
            </div>
          </div>
        </fieldset>
      </div>
      <div class="mt-4 flex flow-root">
        <div class="float-left">
          <!-- <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col p-3">
              <SwitchLabel
                as="span"
                class="text-sm font-medium text-gray-400"
                passive
                >Expert Mode</SwitchLabel
              >
            </span>
            <Switch
              @click="arrayClear"
              v-model="enabled"
              :class="[
                enabled ? 'bg-gray-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
              ]"
            >
              <span class="sr-only">Use setting</span>
              <span
                :class="[
                  enabled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                ]"
              >
                <span
                  :class="[
                    enabled
                      ? 'opacity-0 ease-out duration-100'
                      : 'opacity-100 ease-in duration-200',
                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                  ]"
                  aria-hidden="true"
                >
                  <svg
                    class="h-3 w-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span
                  :class="[
                    enabled
                      ? 'opacity-100 ease-in duration-200'
                      : 'opacity-0 ease-out duration-100',
                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                  ]"
                  aria-hidden="true"
                >
                  <svg
                    class="h-3 w-3 text-red-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                    />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup> -->
        </div>

        <div class="float-right space-x-4">
          <button
            @click="closeModal"
            class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-400 bg-gray hover:bg-gray-100 focus:outline-none"
          >
            Cancel
          </button>

          <button @click="downloadFile" type="button" class="button-submit m-0">
            Download
          </button>
        </div>
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import DialogContainer from "@/components/common/DialogContainer";
import { reactive, ref } from "vue";
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import eventBus from "@/utils/eventBus";

export default {
  mixins: [modalMixin],
  components: {
    DialogContainer,
  },
  setup() {
    const modalEventName = "show-expert";
    const isOpen = ref(false);
    const enabled = ref(false);
    const allSelected = ref(true);
    const job = reactive({});
    const statuses = ref([]);
    const score_rating = ref([]);
    const threat_levels = ref([]);
    const reasons = ref([]);
    const score_array = ref([]);
    const bestDeliveryCount = ref(0);
    const maximumReachCount = ref(0);
    const status = [
      { id: 1, name: "deliverable" },
      { id: 2, name: "possibly_deliverable" },
      { id: 3, name: "undeliverable" },
      { id: 4, name: "unknown_deliverability" },
    ];
    const threatlevel = [
      { id: 1, name: "low" },
      { id: 2, name: "moderate" },
      { id: 3, name: "high" },
      { id: 4, name: "undetermined" },
    ];
    const rating = [
      { id: 1, name: "excellent" },
      { id: 2, name: "good" },
      { id: 3, name: "fair" },
      { id: 4, name: "poor" },
      { id: 5, name: "bad" },
    ];
    const reason = [
      { id: 1, name: "accepted_email" },
      { id: 2, name: "catch_all" },
      { id: 3, name: "disposable" },
      { id: 4, name: "invalid_email" },
      { id: 5, name: "invalid_domain" },
      { id: 6, name: "rejected_email" },
      { id: 7, name: "dns_error" },
      { id: 8, name: "unavailable_smtp" },
      { id: 9, name: "unknown" },
      { id: 10, name: "role_based" },
      { id: 11, name: "mailbox_disabled" },
      { id: 12, name: "mailbox_full" },
      { id: 13, name: "suspicious" },
      { id: 14, name: "dangerous" },
      { id: 15, name: "malicious" },
    ];
    const score_values = [
      { id: 10, name: "10" },
      { id: 9, name: "9" },
      { id: 8, name: "8" },
      { id: 7, name: "7" },
      { id: 6, name: "6" },
      { id: 5, name: "5" },
      { id: 4, name: "4" },
      { id: 3, name: "3" },
      { id: 2, name: "2" },
      { id: 1, name: "1" },
    ];

    return {
      job,
      isOpen,
      enabled,
      status,
      threatlevel,
      reason,
      rating,
      score_values,
      statuses,
      score_rating,
      threat_levels,
      reasons,
      score_array,
      modalEventName,
      allSelected,
      bestDeliveryCount,
      maximumReachCount,
    };
  },
  computed: {
    ...mapGetters("auth", ["accessToken"]),
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      this.enabled = false;
      this.allSelected = true;
      this.statuses = [];
      this.score_rating = [];
      this.threat_levels = [];
      this.reasons = [];
    },
    arrayClear() {
      this.statuses = [];
      this.score_rating = [];
      this.threat_levels = [];
      this.reasons = [];
    },

    openModal({ job }) {
      this.job = job;
      this.isOpen = true;
      if (
        this.job.result &&
        this.job.result.verification_job &&
        this.job.result.verification_job.scores &&
        this.job.result.verification_job.scores.length > 0
      ) {
        this.bestDeliveryCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 6)
          .reduce((a, b) => a + b.email_count, 0);
        this.maximumReachCount = this.job.result.verification_job.scores
          .filter((x) => x.score > 5)
          .reduce((a, b) => a + b.email_count, 0);
      } else {
        this.bestDeliveryCount = 0;
        this.maximumReachCount = 0;
      }
    },

    humanize(words) {
      const separateWords = words.toLowerCase().split("_");
      for (let i = 0; i < separateWords.length; i++) {
        separateWords[i] =
          separateWords[i].charAt(0).toUpperCase() +
          separateWords[i].substring(1);
      }
      return separateWords
        .join(" ")
        .replaceAll("Smtp", "SMTP")
        .replaceAll("Dns", "DNS");
    },

    saveDownloadFile(fileDownload) {
      const url = window.URL.createObjectURL(new Blob([fileDownload]));
      const link = document.createElement("a");
      link.href = url;
      const namePos = this.job.input_file.name.indexOf(".");
      const name = this.job.input_file.name.substr(0, namePos);
      const datePos = this.job.input_file.created_at.indexOf("T");
      const createdDate = this.job.input_file.created_at.substr(0, datePos);
      const fileName = name + "_results_" + createdDate + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    clicktoUncheck() {
      let inputs = document.getElementById("cb-all");
      inputs.checked = false;
      this.allSelected = false;
    },
    uncheckOthers() {
      let input_safetosend = document.getElementById("cb-safetosend");
      let input_sendwithcaution = document.getElementById("cb-sendwithcaution");
      let input_donotsend = document.getElementById("cb-donotsend");
      input_safetosend.checked = false;
      input_sendwithcaution.checked = false;
      input_donotsend.checked = false;
    },

    clickStatuses(id, label) {
      let status_id = document.getElementById("label-" + label);
      if (status_id.checked) {
        this.statuses.push(label);
      } else {
        const index = this.statuses.findIndex((x) => x == label);
        if (index != -1) {
          this.statuses.splice(index, 1);
        }
      }
    },
    clickThreat(id, label) {
      let threat_id = document.getElementById("label-" + label);
      if (threat_id.checked) {
        this.threat_levels.push(label);
      } else {
        const index = this.threat_levels.findIndex((x) => x == label);
        if (index != -1) {
          this.threat_levels.splice(index, 1);
        }
      }
    },
    clickReason(id, label) {
      let reason_id = document.getElementById("label-" + label);
      if (reason_id.checked) {
        this.reasons.push(label);
      } else {
        const index = this.reasons.findIndex((x) => x == label);
        if (index != -1) {
          this.reasons.splice(index, 1);
        }
      }
    },
    clickScore(id, label) {
      let score_id = document.getElementById("label-" + label);
      if (score_id.checked) {
        this.score_rating.push(id);
      } else {
        const index = this.score_rating.findIndex((x) => x == id);
        if (index != -1) {
          this.score_rating.splice(index, 1);
        }
      }
    },
    clickRatings(id_rate, label_rate) {
      let rating_id = document.getElementById("label-" + label_rate);
      if (rating_id.checked) {
        if (label_rate === "excellent") {
          this.score_rating.push(10);
        } else if (label_rate === "good") {
          this.score_rating.push(9, 8);
        } else if (label_rate === "fair") {
          this.score_rating.push(7);
        } else if (label_rate === "poor") {
          this.score_rating.push(4, 5, 6);
        } else if (label_rate === "bad") {
          this.score_rating.push(1, 2, 3);
        }
      } else {
        let tempScore = Object.assign([], this.score_rating);
        if (label_rate === "excellent") {
          const index = this.score_rating.findIndex((x) => x == 10);
          if (index != -1) {
            this.score_rating.splice(index, 1);
          }
        } else if (label_rate === "good") {
          tempScore.forEach((item) => {
            if (item == 9 || item == 8) {
              const scoreindex = this.score_rating.findIndex((x) => x == item);
              if (scoreindex != -1) {
                this.score_rating.splice(scoreindex, 1);
              }
            }
          });
        } else if (label_rate === "fair") {
          const index = this.score_rating.findIndex((x) => x == 7);
          if (index != -1) {
            this.score_rating.splice(index, 1);
          }
        } else if (label_rate === "poor") {
          tempScore.forEach((item) => {
            if (item == 4 || item == 5 || item == 6) {
              const scoreindex = this.score_rating.findIndex((x) => x == item);
              if (scoreindex != -1) {
                this.score_rating.splice(scoreindex, 1);
              }
            }
          });
        } else if (label_rate === "bad") {
          tempScore.forEach((item) => {
            if (item == 1 || item == 2 || item == 3) {
              const scoreindex = this.score_rating.findIndex((x) => x == item);
              if (scoreindex != -1) {
                this.score_rating.splice(scoreindex, 1);
              }
            }
          });
        }
      }
    },
    async exportKlaviyoList() {
      let scores = [];
      let input_bestDeliverability = document.getElementById(
        "cb-bestDeliverability"
      );
      let input_maximumReach = document.getElementById("cb-maximumReach");
      if (!input_bestDeliverability.checked && !input_maximumReach.checked) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "Please select atleast one option before downloading.",
            type: "warning",
          },
        });
        return;
      }
      if (input_bestDeliverability.checked) {
        scores.push(7, 8, 9, 10);
      }
      if (input_maximumReach.checked) {
        scores.push(6, 7, 8, 9, 10);
      }

      const fileDownload = await this.$alfredService.klaviyoExport({
        list_id: "",
        job_id: this.job.id,
        scores,
      });
      if (fileDownload) {
        this.saveDownloadFile(fileDownload);
      }
    },

    async downloadFile() {
      /* if (!this.enabled) {
        let scores = [];
        let input_all = document.getElementById("cb-all");
        let input_safetosend = document.getElementById("cb-safetosend");
        let input_sendwithcaution =
          document.getElementById("cb-sendwithcaution");
        let input_donotsend = document.getElementById("cb-donotsend");

        if (
          !input_all.checked &&
          !input_safetosend.checked &&
          !input_sendwithcaution.checked &&
          !input_donotsend.checked
        ) {
          await eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: "Please select atleast one option before downloading.",
              type: "warning",
            },
          });
          return;
        }

        // if (input_all.checked) {
        //   scores.push(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
        // }
        if (input_safetosend.checked) {
          scores.push(7, 8, 9, 10);
        }
        if (input_sendwithcaution.checked) {
          scores.push(4, 5, 6);
        }
        if (input_donotsend.checked) {
          scores.push(1, 2, 3);
        }

        let url = `/api/v1/jobs/${this.job.id}/download`;
        this.$cookies.set("auth_token", this.accessToken, 60);
        if (scores) {
          const searchParams = new URLSearchParams();
          scores.forEach((score) => searchParams.append("score", score));
          url = `${url}?${searchParams.toString()}`;
        }
        window.location = url;
        this.isOpen = false;

        // const fileDownload = await this.$alfredService.expertDownload(
        //   this.job.id,
        //   {
        //     scores: scores,
        //   }
        // );
        // if (fileDownload) {
        //   this.saveDownloadFile(fileDownload);
        // }
      } else {
        if (
          this.score_rating.length == 0 &&
          this.statuses.length == 0 &&
          this.threat_levels == 0 &&
          this.reasons == 0
        ) {
          await eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: "Please select atleast one option before downloading.",
              type: "warning",
            },
          });
          return;
        }
        let payload = {};
        if (this.score_rating && this.score_rating.length > 0) {
          payload.scores = this.score_rating;
        }
        if (this.statuses && this.statuses.length > 0) {
          payload.statuses = this.statuses;
        }
        if (this.threat_levels && this.threat_levels.length > 0) {
          payload.threat_levels = this.threat_levels;
        }
        if (this.reasons && this.reasons.length > 0) {
          payload.reasons = this.reasons;
        }

        const fileDownload = await this.$alfredService.expertDownload(
          this.job.id,
          payload
        );
        if (fileDownload) {
          this.saveDownloadFile(fileDownload);
        }
      } */

      //expert mode with best deliverability and maximum reach options

      if (!this.enabled) {
        let scores = [];
        let input_all = document.getElementById("cb-all");
        let best_deliverability = document.getElementById(
          "cb-bestDeliverability"
        );

        if (best_deliverability.checked) {
          if (this.job.basicData) {
            let emailNumber = this.job.basicData.emailsCount.replace(",", "");
            this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
          } else {
            let emailNumber = this.job.emails_count;
            this.emailsCount = parseInt(emailNumber) - this.bestDeliveryCount;
          }
          this.input_bestDeliverability = true;
        }
        let maximum_reach = document.getElementById("cb-maximumReach");
        if (maximum_reach.checked) {
          if (this.job.basicData) {
            let emailNumber = this.job.basicData.emailsCount.replace(",", "");
            this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
          } else {
            let emailNumber = this.job.emails_count;
            this.emailsCount = parseInt(emailNumber) - this.maximumReachCount;
          }
          this.input_maximumReach = true;
        }
        if (
          !this.input_bestDeliverability &&
          !this.input_maximumReach &&
          !input_all
        ) {
          await eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: "Please select atleast one option before Exporting.",
              type: "warning",
            },
          });
          return;
        }

        if (this.input_bestDeliverability && this.input_maximumReach) {
          scores.push(6, 7, 8, 9, 10);
        } else if (this.input_bestDeliverability && !this.input_maximumReach) {
          scores.push(7, 8, 9, 10);
        } else if (!this.input_bestDeliverability && this.input_maximumReach) {
          scores.push(10, 9, 8, 7, 6);
        }

        let url = `/api/v1/jobs/${this.job.id}/download`;
        this.$cookies.set("auth_token", this.accessToken, 60);
        if (scores) {
          const searchParams = new URLSearchParams();
          scores.forEach((score) => searchParams.append("score", score));
          url = `${url}?${searchParams.toString()}`;
        }
        window.location = url;
        this.isOpen = false;

        // const fileDownload = await this.$alfredService.expertDownload(
        //   this.job.id,
        //   {
        //     scores: scores,
        //   }
        // );
        // if (fileDownload) {
        //   this.saveDownloadFile(fileDownload);
        // }
      } else {
        if (
          this.score_rating.length == 0 &&
          this.statuses.length == 0 &&
          this.threat_levels == 0 &&
          this.reasons == 0
        ) {
          await eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              text: "Please select atleast one option before downloading.",
              type: "warning",
            },
          });
          return;
        }
        let payload = {};
        if (this.score_rating && this.score_rating.length > 0) {
          payload.scores = this.score_rating;
        }
        if (this.statuses && this.statuses.length > 0) {
          payload.statuses = this.statuses;
        }
        if (this.threat_levels && this.threat_levels.length > 0) {
          payload.threat_levels = this.threat_levels;
        }
        if (this.reasons && this.reasons.length > 0) {
          payload.reasons = this.reasons;
        }

        const fileDownload = await this.$alfredService.expertDownload(
          this.job.id,
          payload
        );
        if (fileDownload) {
          this.saveDownloadFile(fileDownload);
        }
      }
    },
  },
};
</script>
