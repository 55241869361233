<template>
  <div class="flex flex-col items-start">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="mt-5">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="divide-gray-300 table-fixed w-full">
              <thead class="border-b-2">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    From
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    To
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price per Credit
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pricingRange, rangeIdx) in schema"
                  :key="pricingRange.id"
                  class="border-b-2"
                >
                  <td
                    class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <NumberInput v-model="pricingRange.from" :disabled="true" />
                  </td>
                  <td
                    class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    <NumberInput
                      v-model="pricingRange.to"
                      @input="onRowInput(rangeIdx)"
                      :classes="
                        errors[rangeIdx]?.to ? errorClasses : defaultClasses
                      "
                    />
                  </td>
                  <td
                    class="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-900 price"
                  >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div
                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                      >
                        <span class="text-gray-500 sm:text-sm"> $ </span>
                      </div>
                      <div class="pl-7">
                        <NumberInput
                          v-model="pricingRange.price"
                          :decimals="6"
                          @input="onRowInput(rangeIdx)"
                          :classes="`form-field-no-icon w-full
                        ${errors[rangeIdx]?.price ? errorClasses : ''}`"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      @click="onSubmit"
      :disabled="hasErrors"
      class="button-submit mt-5"
    >
      Confirm
    </button>
  </div>
</template>
<script>
import { computed, ref, reactive } from "vue";
import { mapGetters } from "vuex";
import NumberInput from "@/components/common/NumberInput";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";

export default {
  name: "PricingAdminTable",
  mixins: [formMixin],
  props: {
    userId: Number,
    default: null,
  },
  components: {
    NumberInput,
  },
  setup() {
    const errorClasses =
      " form-field-no-icon w-full border-red-300 bg-red-50 focus:ring-red-500 focus:border-red-500";
    const schema = ref([]);
    const errors = reactive({});
    return {
      schema,
      errorClasses,
      errors,
    };
  },
  async beforeMount() {
    await this.getPricingSchema();
    await this.convertSchema();
    if (this.userId) {
      await this.$store.dispatch("user/FETCH_USER", { userId: this.userId });
    }
  },
  computed: {
    isLastRowEmpty() {
      if (!this.schema.length) {
        return false;
      }
      const lastRow = this.schema[this.schema.length - 1];
      return !lastRow.price && !lastRow.to;
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    ...mapGetters({ user: "user/getUser" }),
  },
  methods: {
    async getPricingSchema() {
      const { value } = await this.getPricing();
      const pricingSchema = value || [];
      this.errors = Object.assign(this.errors, {});
      this.setSchema(pricingSchema);
      this.$forceUpdate();
    },
    async getPricing() {
      try {
        return await this.getPricingRequest({ user: this.userId || null });
      } catch (_) {
        const pricing = await this.$alfredService.getPricing();
        return { value: pricing.pricing_table };
      }
    },
    async getPricingRequest(payload) {
      return await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.getSetting("PRICING", payload),
        handleErrors: false,
      });
    },
    setSchema(pricingSchema) {
      this.schema = pricingSchema.reduce(
        (previousValue, currentValue, index, array) => {
          const nextIndex = index + 1;
          previousValue.push({
            from: currentValue["from"] + 1,
            to: array[nextIndex] ? array[nextIndex]["from"] : null,
            price: currentValue["price"],
            id: index,
          });
          return previousValue;
        },
        []
      );
      this.schema.forEach((row, rowIndex) => {
        const previousLine = this.schema[rowIndex - 1];
        row.from = previousLine ? computed(() => previousLine?.to + 1) : 1;
      });
      if (this.schema.length === 0) {
        this.addNewLine();
      } else {
        this.validateAll();
      }
    },
    async onSubmit() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        message: "This action will overwrite all settings in the system",
        onSubmit: () => this.handleChangeSetting(),
      });
    },
    async handleChangeSetting() {
      const schema = this.schema.map((row) => {
        return {
          from: row["from"] - 1,
          price: row["price"],
        };
      });
      const response = await this.handleSubmissionErrors(
        () =>
          this.$alfredService.changeSetting({
            key: "PRICING",
            value: schema,
            user: this.userId,
          }),
        {}
      );
      if (response) {
        const pricingSchema = response?.value || [];
        this.setSchema(pricingSchema);
        eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            type: "success",
            text: "Pricing successfully updated!",
          },
        });
      }
    },
    onRowInput(rowIndex) {
      this.$nextTick(() => {
        this.validateRow(this.schema[rowIndex], rowIndex);
        let index = rowIndex + 1;
        while (index >= 0) {
          if (!this.schema[index]) {
            index -= 1;
            continue;
          }
          const currentIndex = index;
          this.validateRow(this.schema[currentIndex], currentIndex);
          index -= 1;
        }
      });
    },
    validateAll() {
      this.schema.forEach((row, rowIndex) => {
        this.validateRow(row, rowIndex);
      });
    },
    validateRow(row, rowIndex) {
      if (!row) {
        return;
      }
      const isLastRow = rowIndex === this.schema.length - 1;

      if (isLastRow && this.isLastRowEmpty) {
        const previousRow = this.schema[rowIndex - 1];
        if (previousRow && !previousRow.to) {
          this.deleteRow(row.id);
          return;
        }
      }

      const errors = this.getRowErrors(row, rowIndex);
      const hasErrors = Object.values(errors).reduce(
        (prevValue, currentValue) => {
          return prevValue || currentValue;
        },
        false
      );
      if (hasErrors) {
        Object.assign(this.errors, this.errors, {
          [rowIndex]: errors,
        });
      } else {
        delete this.errors[rowIndex];
      }
      const isLastLine = rowIndex === this.schema.length - 1;
      if (isLastLine && !hasErrors && row.to) {
        this.addNewLine();
      }
    },
    deleteRow(rowId) {
      let index = 0;
      for (let row of this.schema) {
        if (row.id === rowId) {
          this.schema.splice(index, index);
          delete this.errors[index];
          break;
        }
        index += 1;
      }
      this.validateAll();
    },
    getRowErrors(row, rowIndex) {
      const isLastRow = rowIndex === this.schema.length - 1;
      const isPreLastRow = rowIndex === this.schema.length - 2;
      const isEmpty = !row.price && !row.to;
      const isLastNonEmptyRow =
        ((this.isLastRowEmpty && isPreLastRow) || isLastRow) && !isEmpty;

      const errors = {};
      if (!row.price) {
        errors["price"] = true;
      }
      if (!row.to && !isLastNonEmptyRow) {
        errors["to"] = true;
      }
      if (row.to && row.to < row.from) {
        errors["to"] = true;
      }
      return errors;
    },
    addNewLine() {
      const index = this.schema.length ? this.schema.length + 1 : 0;
      const previousLine = this.schema[this.schema.length - 1];
      this.schema.push({
        from: previousLine ? computed(() => previousLine?.to + 1) : 1,
        to: null,
        price: null,
        id: index,
      });
      this.validateRow(this.schema[index], index);
    },
    convertSchema() {},
  },
};
</script>
