<template>
  <div>
    <LegalModal />
    <Notifications />
    <div v-if="isLoggedIn">
      <ConfirmationModal />
      <CleanTheList />
      <EvaluatedDetailsModal />
      <BuyCreditsModal />
      <CheckoutModal />
      <SelectCreditsModal />
      <SuccessModal />
      <DiscountCodeModal />
    </div>
    <div class="flex flex-col justify-between h-screen">
      <div
        class="flex flex-col flex-grow"
        :class="showSpinner ? 'h-screen' : ''"
      >
        <Navbar v-if="showMenu & !hideNav" />
        <div
          v-if="showSpinner"
          class="flex flex-grow justify-center items-center"
        >
          <PulseLoader color="#ff5a5f" />
        </div>
        <div v-show="!showSpinner">
          <router-view />
        </div>
      </div>
      <component
        v-show="!showSpinner"
        :is="footerComponent"
        v-if="!hideFooter"
      />
    </div>
  </div>
</template>
<script>
import "@/assets/css/tailwind.css";
import "@/assets/css/custom.css";
import Navbar from "@/components/common/navigation/Navbar.vue";
import Notifications from "@/components/common/Notifications.vue";
import eventBus from "@/utils/eventBus";
import { mapGetters } from "vuex";
import Footer from "@/components/basic/common/Footer";
import PulseLoader from "vue-spinner/src/PulseLoader";
import { ref } from "@vue/reactivity";
import ConfirmationModal from "@/components/common/modals/ConfirmationModal";
import BuyCreditsModal from "@/components/basic/billing-page/modals/BuyCreditsModal";
import CheckoutModal from "@/components/basic/common/modals/CheckoutModal";
import CleanTheList from "@/components/basic/dashboard-page/CleanTheList";
import SelectCreditsModal from "@/components/basic/dashboard-page/modals/SelectCreditsModal";
import SuccessModal from "@/components/basic/dashboard-page/modals/SuccessModal";
import DiscountCodeModal from "@/components/admin/discount-codes-page/modals/DiscountCodeModal";
import LegalModal from "@/components/common/modals/LegalModal";
import EvaluatedDetailsModal from "@/components/basic/dashboard-page/modals/EvaluatedDetailsModal";
import intercomMixin from "@/mixins/intercomMixin";
import freshchatMixin from "@/mixins/freshchatMixin";
import tapfiliateMixin from "@/mixins/tapfiliateMixin";

export default {
  name: "App",
  components: {
    EvaluatedDetailsModal,
    SuccessModal,
    Navbar,
    Notifications,
    Footer,
    PulseLoader,
    ConfirmationModal,
    BuyCreditsModal,
    CheckoutModal,
    CleanTheList,
    SelectCreditsModal,
    DiscountCodeModal,
    LegalModal,
  },
  mixins: [intercomMixin, freshchatMixin, tapfiliateMixin],
  async beforeMount() {
    eventBus.on("notify", this.onNotify);
    eventBus.on("logout", this.onLogout);
    eventBus.on("router-push", this.onRouterPush);
    this.fetchUserData();
    this.fetchSettings();
    this.timeout = window.setTimeout(this.onUpdate, 10000);
  },
  beforeUnmount() {
    eventBus.off("notify", this.onNotify);
    eventBus.off("logout", this.onLogout);
    eventBus.off("router-push", this.onRouterPush);
    window.clearTimeout(this.timeout);
    this.timeout = null;
  },
  async mounted() {
    this.setupIntercom(this.userData, this.isLoggedIn);
    this.setupFreshchat(this.userData, this.isLoggedIn);
    this.setuptapfiliateReferral(this.userData, this.isLoggedIn);
  },
  setup() {
    return {
      timeout: ref(null),
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "isStaff", "userData"]),
    ...mapGetters("fetching", ["showSpinner"]),
    hideFooter() {
      return this.$route.meta?.hideMenu;
    },
    hideNav() {
      return this.$route.meta?.hideNav;
    },
    showMenu() {
      return this.isLoggedIn && this.$route.fullPath !== "/login";
    },
    footerComponent() {
      return this.$route.meta?.footerComponent === null ? null : Footer;
    },
  },
  methods: {
    onNotify({ notification, timeout = 10000 }) {
      this.$notify(notification, timeout);
    },
    onUpdate() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
        eventBus.trigger("update", {});
        this.fetchUserData();
        this.timeout = window.setTimeout(this.onUpdate, 60000);
      }
    },
    fetchUserData() {
      if (this.isLoggedIn) {
        this.$store.dispatch("auth/FETCH_USER_DATA");
      }
    },
    onLogout() {
      window.Intercom("shutdown");
      this.$router.push({
        name: "Login",
      });
      this.setupIntercom(this.userData, this.isLoggedIn);
    },
    onRouterPush(to) {
      this.$router.push(to);
    },
    fetchSettings() {
      this.$store.dispatch("settings/FETCH_SETTINGS");
    },
  },
};
</script>
