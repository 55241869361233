<template>
  <div class="overflow-x-auto rounded-lg">
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="columnName in columnNames"
              :key="columnName"
              scope="col"
              :class="`
                px-1
                py-5
                text-left
                text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider ${padding}
                `"
            >
              {{ columnName }}
            </th>
            <th v-for="col in missingColumnNo" :key="col"></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <slot></slot>
          <tr v-for="(row, index) in data" :key="index" :data-test="index">
            <template v-for="(columnValue, index) in Object.values(row)">
              <td
                :key="`${index}1`"
                :class="`px-1 py-2 sm:py-4 ${padding} text-sm font-medium text-gray-500`"
                :rowspan="columnValue.span"
                v-if="columnValue instanceof Object"
              >
                {{ columnValue.value }}
              </td>
              <td
                v-else
                :key="`${index}2`"
                :class="`px-1 py-2 sm:py-4 ${padding} text-sm font-medium text-gray-500`"
              >
                {{ columnValue }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlossaryTable",
  props: {
    data: {
      type: Array,
      required: true,
    },
    columnNames: {
      type: Array,
      required: true,
    },
  },
  components: {},
  computed: {
    missingColumnNo() {
      return Object.keys(this.data[0]).length - this.columnNames.length;
    },
    padding() {
      return Object.keys(this.data[0]).length > 5 ? "px-4" : "px-4 sm:px-6";
    },
  },
};
</script>
