<template>
  <div v-if="data?.length" class="overflow-x-auto rounded-lg mt-5">
    <div class="align-middle inline-block w-full">
      <table class="divide-gray-300 w-full text-left table-auto">
        <thead class="border-b-2">
          <tr data-test="header-row">
            <th
              v-for="columnName in columnNames"
              :key="columnName"
              scope="col"
              class="px-6 py-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left"
            >
              {{ columnName }}
            </th>
          </tr>
        </thead>

        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in data" :key="index">
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ row.domain }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ row.credits }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left input-cell">
              <p>{{ row.is_blocked }}</p>
            </td>
            <td class="px-6 py-5 text-sm text-gray-500 text-left trash-cell">
              <div class="flex">
                <button
                  @click="
                    Openpopup(row.id, row.credits, row.domain, row.is_blocked)
                  "
                  class="button-icon"
                  aria-label="Edit"
                  title="Edit domain"
                >
                  <PencilIcon class="h-5" />
                </button>
                <button
                  @click="onDeleteDomain(row.id)"
                  class="button-icon pl-3"
                >
                  <TrashIcon class="h-6" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showPopup" class="popup-container">
        <Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6">
          <div
            class="popup inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:max-h-md w-full rounded-lg"
          >
            <h3
              id="headlessui-dialog-title-12"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              <div class="pb-3">Update Domain</div>
            </h3>
            <div class="sm:col-span-6">
              <div class="pb-3">
                <label for="domain"> Domain </label>
                <div class="mt-1">
                  <Field
                    id="domain"
                    name="domain"
                    type="domain"
                    v-model="domain"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                  <p class="text-red-500 text-sm mt-1">{{ capitalize(errors.domain) }}</p>
                </div>
              </div>

              <div class="pb-3">
                <label for="credits"> Credits </label>
                <div class="mt-1">
                  <Field
                    id="credits"
                    name="credits"
                    type="number"
                    v-model="credits"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                    :disabled="credit_disabled"
                  />
                  <p class="text-red-500 text-sm mt-1">
                    {{ errors.credits }}
                  </p>
                </div>
              </div>
              <div class="flex">
                <input
                  type="checkbox"
                  v-model="is_blocked"
                  :checked="is_blocked"
                  class="mt-4 mr-1 rounded"
                />
                <label class="mt-4 mr-4">Block domain</label>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b"
            >
              <button
                type="submit"
                class="button-submit sm:ml-3"
                @click="submit"
              >
                Update
              </button>
              <button
                type="button"
                class="button-cancel ml-0 sm:ml-3"
                @click="Closepopup()"
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref } from "vue";
import { TrashIcon } from "@heroicons/vue/outline";
import { PencilIcon } from "@heroicons/vue/solid";
import eventBus from "@/utils/eventBus";
import useConfirmationModal from "@/api/confirmationModal";
import formMixin from "@/mixins/formMixin";
import { Field, Form } from "vee-validate";
import humanize from "humanize";

export default {
  name: "DomainControlTable",
  components: {
    TrashIcon,
    PencilIcon,
    Field,
    Form,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    eventBus.trigger("update-domain-control");
  },
  setup() {
    const columnNames = ref(["Domain", "Credits", "Blocked", "Action"]);

    return {
      columnNames,
      id: ref(""),
      domain: ref(""),
      credits: null,
      is_blocked: ref(false),
      showPopup: ref(false),
      credit_disabled: ref(false)
    };
  },
  watch: {
    is_blocked(value) {
      if(value){
        this.credit_disabled=true;
        this.credits = null;
      }else{
        this.credit_disabled=false;
      }
    }
  },
  methods: {
    async onSubmit(values, actions) {
      values["is_blocked"] = this.is_blocked;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.DomainControlEdit(this.id, values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully edited.",
            type: "success",
          },
        });
        this.domain = "";
        this.credits = "";
        this.is_blocked = false;
        this.showPopup = false;
        eventBus.trigger("update-domain-control");
      }
    },
    async onDeleteDomain(id) {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to remove this?",
        onSubmit: async () => {
          this.$alfredService.DomainControlDelete(id);
          eventBus.trigger("notify", {
            notification: {
              group: "top-right",
              type: "success",
              text: "Domain successfully removed!",
            },
          });
          eventBus.trigger("update");
          eventBus.trigger("update-domain-control");
        },
      });
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    capitalize(string){
      if(string){
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    Openpopup(id, credits, domain, is_blocked) {
      this.id = id;
      this.credits = credits;
      this.domain = domain;
      this.is_blocked = is_blocked;
      this.showPopup = true;
    },
    Closepopup() {
      this.showPopup = false;
    },
  },
};
</script>
<style scoped>
.input-cell {
  min-width: 200px;
}
</style>
