<template>

<section class="flex flex-col mt-6 mb-8">
        <span
      class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center"
    >
      <span
        class="flex-grow flex flex-col sm:flex-row text-gray-600 items-center"
      >
        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto">EMAIL JOB</span>
          <select
            class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
            v-model="filters.emailJob"
          >
            <option v-for="jobItem in emailJob" :key="jobItem" :value="jobItem">
              {{ jobItem }}
            </option>
          </select>
        </span>

        <span class="flex text-gray-600 items-center" v-if="!payload.realtime">
          <span class="w-full sm:w-auto pl-4">SERVICE</span>
          <select
            class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
            v-model="filters.actions"
          >
          <option :value="''">ANY</option>
            <option v-for="service in services" :key="service" :value="service">
              {{ formatString(service) }}
            </option>
          </select>
        </span>

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">TYPE</span>
          <select
            class="form-field-no-icon sm:ml-2 w-full sm:w-48 mb-2 sm:mb-0 uppercase"
            v-model="filters.type"
          >
          <option :value="''">ANY</option>
            <option v-for="type in types" :key="type" :value="type">
              {{ type }}
            </option>
          </select>
        </span>
        
        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">Start Date</span>
          <input
      type="date"
      class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
      v-model="filters.start_date"
      @change="onChange()"
    />
        </span>

        <span class="flex text-gray-600 items-center">
          <span class="w-full sm:w-auto pl-4">End Date</span>
          <input  
      type="date"
      class="ml-2 flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
      v-model="filters.end_date"
      @change="onChange()"
    />
        </span>
   
      </span>
  </span>
  </section>
<div class="align-middle bg-white inline-block w-full">
        <table class="divide-gray-300 w-full text-left table-auto">
          <thead class="border-b-2">
            <tr>
              <th
                v-for="columnName in columnNames"
                :key="columnName.name"
                scope="col"
                :class="`
                px-6
                py-5
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
                ${columnName.name === 'Date' ? 'w-1/4' : ''}
              `"
              >
              <template v-if="columnName.name !== 'Jobs Count' && payload.realtime"
                  >
                  <FilterableHeaderCell
                    :title="columnName.name"
                    :param="columnName.param"
                    @switchOrder="onSwitchOrders(columnName.param)"
                  />
                </template>
                <template v-if="!payload.realtime"
                  >
                  <FilterableHeaderCell
                    :title="columnName.name"
                    :param="columnName.param"
                    @switchOrder="onSwitchOrders(columnName.param)"
                  />
                </template>

              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="data?.length">
              <tr v-for="(row, index) in data" :key="index">
          
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ row.created_by__email }}
                </td>
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ formatN(row.email_count) }}
                </td>   
                <td class="px-6 py-5 text-sm text-gray-500">
                  {{ formatN(row.count) }}
                </td>               
              </tr>
            </template>
            <template v-else>
              <div class="px-6 py-5 text-sm text-gray-500 text-left">
                No data
              </div>
            </template>
          </tbody>
        </table>
      </div>

</template>

<script>
import { ref, reactive } from "vue";
import apiMixin from "@/mixins/apiMixin";
import FilterableHeaderCell from "@/components/common/FilterableHeaderCell";
import { mapGetters } from "vuex";
import humanize from "humanize";

export default {
  name: "AdminTopUser",
  mixins: [apiMixin],
  components: {
    FilterableHeaderCell
  },
  data() {
      return {
        emailJob: ref(["Batch", "Real-time"]),
        currentEmailJob: "Batch",
        types: ref(["UI", "API"]),
        services: ref(["evaluation", "threat_evaluation", "verification", "deliverability", "threat_assessment"]),
        columnNames:ref([
      { name: "Email", param: "created_by__email" },
      { name: "Emails Count", param: "email_count" },
      { name: "Jobs Count", param: "count" }
    ]),
    filters: reactive({
      type: "",
      emailJob: "Batch",
      actions: "",
      start_date: "",
      end_date: "",
    }),
        data: ref([]),
        payload: 
          {
            realtime: false,
            created_with: "",
            actions: "",
            start_date: "",
            end_date: "",
            ordering: "-email_count"
          },
        
        tabs: [
          { name: "Overall", current: true },
          { name: "Top 10 Users", current: false },
        ],
        activeTab: "Overall"
      };
    },
    computed: {
    ...mapGetters({adminStatsDate: "settings/adminStatsDate"})
  },
    watch: {
     'filters.emailJob': function(newVal,) {
      this.payload.realtime = newVal==="Batch" ? false : true;
      this.payload.ordering = "-email_count"
      this.fetchUserStats();
    },
    'filters.type': function(newVal,) {
      this.payload.created_with = newVal
      this.fetchUserStats();
    },
    'filters.actions': function(newVal,) {
      this.payload.actions = newVal
      this.fetchUserStats();
    },
    'filters.start_date': function(newVal,) {
      this.payload.start_date = newVal
      this.fetchUserStats();
    },
    'filters.end_date': function(newVal,) {
      this.payload.end_date = newVal
      this.fetchUserStats();
    },
  },
    mounted() {
    this.filters.end_date = this.adminStatsDate.end_date;
    this.filters.start_date = this.adminStatsDate.start_date;
    this.fetchUserStats()
  },
  methods: {
    async onSwitchOrders(name) {
      let order =
        this.payload.ordering == name ? `-${name}` : name;
      this.payload.ordering = order;
      this.fetchUserStats();
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async fetchUserStats() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () =>
          await this.$alfredService.UserStatsTopUser(this.payload),
      });
      if (response) {
        this.data = response
      }
    },
    onChange(){
      let admin_stats_date = {
      start_date: this.filters.start_date,
      end_date: this.filters.end_date,
    }
    this.$emit('getDate', admin_stats_date);
    this.$store.dispatch('settings/SET_ADMIN_STATS_DATE', admin_stats_date);
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    formatString(str) {
      if (str){
        return str
          .replace(/['[\]]/g, "")
          .replace(/_/g, " ")
          .toUpperCase();  
      }else{
       return "Real-Time"
      }
      
    }
  },
};
</script>
<style scoped>
select {
  background-image: none;
  border-color: #ffffff00;
}
.date_inp{
  padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-width: 1px;
    border-radius: 0.375rem;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>