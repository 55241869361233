<template>
  <Container>
    <template v-slot:header>Domain Control</template>
    <template v-slot:content>
      <section class="bg-white shadow rounded-lg pt-8 mb-6 px-4 sm:px-6">
        <h2 class="text-center sm:text-left text-lg text-gray-900 mb-4">
          Domain Settings
        </h2>
        <Form @submit="onSubmit" v-slot="{ errors }" class="space-y-6">
          <tbody class="bg-white divide-y divide-gray-200">
            <tr class="flex">
              <div class="px-6 py-1 text-sm text-gray-500 text-left input-cell">
                <td>
                  <Field
                    id="domain"
                    name="domain"
                    type="text"
                    v-model="domain"
                    placeholder="Domain"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">{{ capitalize(errors.domain) }}</p>
              </div>
              <div class="px-6 py-1 text-sm text-gray-500 text-left input-cell">
                <td>
                  <Field
                    id="credits"
                    name="credits"
                    type="number"
                    v-model="credits"
                    placeholder="Credits"
                    class="form-field-no-icon w-full"
                    @input="clearError()"
                    :disabled="credit_disabled"
                  />
                </td>
                <p class="text-red-500 text-sm mt-1">{{ errors.credits }}</p>
              </div>
              <div class="flex">
                <input
                  type="checkbox"
                  v-model="is_blocked"
                  :checked="is_blocked"
                  class="mt-4 mr-1 rounded"
                />
                <label class="mt-4 mr-4">Block domain</label>
              </div>
              <button
                type="submit"
                class="button-submit mt-1 h-9"
                @click="submit"
                :disabled="onLoad"
              >
                Add
              </button>
            </tr>
          </tbody>
        </Form>
        <DomainControlTable :data="domainData" />
      </section>
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import DomainControlTable from "@/components/admin/domain-control/DomainControlTable";
import apiMixin from "@/mixins/apiMixin";
import formMixin from "@/mixins/formMixin";
import eventBus from "@/utils/eventBus";
import { ref } from "vue";
import { Field, Form } from "vee-validate";

export default {
  name: "DomainControl",
  mixins: [apiMixin, formMixin],
  components: {
    Container,
    DomainControlTable,
    Field,
    Form,
  },
  beforeMount() {
    eventBus.on("update-domain-control", this.fetchDomainControl);
  },
  beforeUnmount() {
    eventBus.off("update-domain-control", this.fetchDomainControl);
  },
  watch: {
    is_blocked(value) {
      if(value){
        this.credit_disabled=true;
        this.credits = null;
        this.onLoad = false;
      }else{
        this.credit_disabled=false;
        this.onLoad = false;
      }
    }
  },
  setup() {
    const domainData = ref([]);

    return {
      domainData,
      domain: "",
      credits: null,
      is_blocked: ref(false),
      onLoad: ref(false),
      credit_disabled: ref(false)
    };
  },
  methods: {
    async onSubmit(values, actions) {
      this.onLoad = true;
      values["is_blocked"] = this.is_blocked;
      const response = await this.handleSubmissionErrors(
        () => this.$alfredService.DomainControlAdd(values),
        { formActions: actions }
      );
      if (response) {
        await eventBus.trigger("notify", {
          notification: {
            group: "top-right",
            text: "You have successfully added!",
            type: "success",
          },
        });
        this.domain = "";
        this.credits = "";
        this.is_blocked = false;
        this.onLoad = false;
        this.fetchDomainControl();
      }
    },
    clearError() {
      this.onLoad = false;
    },
    capitalize(string){
      if(string){
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    async fetchDomainControl() {
      const response = await this.$store.dispatch("fetching/FETCH", {
        apiCall: async () => await this.$alfredService.DomainControlGet(),
      });
      this.domainData = response ? response : [];
    },
  },
};
</script>
<style>
.trash-cell {
  width: 100px;
}
</style>
