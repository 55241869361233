import axios from "axios";
import store from "@/store";

export class BaseJWTService {
  constructor({ apiUrl }) {
    this.api = axios.create({
      baseURL: apiUrl,
      timeout: 120000,
    });
    this.api.interceptors.request.use(
      async (config) => {
        // Do something before request is sent
        return await this.interceptRequest(config);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // eslint-disable-next-line no-unused-vars
  shouldRefresh(config) {
    return store.getters["auth/hasToRefreshToken"];
  }

  async interceptRequest(config) {
    if (this.shouldRefresh(config)) {
      await store.dispatch("auth/REFRESH_TOKEN");
    }
    const accessToken = store.getters["auth/accessToken"];
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  }
}
