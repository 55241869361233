<template>
  <Container>
    <template v-slot:header>Users</template>
    <template v-slot:content>
      <UserList />
    </template>
  </Container>
</template>

<script>
import Container from "@/components/common/Container";
import UserList from "@/components/admin/user-page/UserList";

export default {
  name: "UserAdminView",
  components: {
    Container,
    UserList,
  },
};
</script>
