<template>
  <div>
    <footer
      class="footer hidden-print text-xs text-center font-medium text-gray-400 mb-4 px-4 sm:px-6 lg:px-8 mt-0"
      aria-labelledby="footerHeading"
    >
      © {{ new Date().getFullYear() }} Alfred is a trademark of
      <a
        href="https://www.emailindustries.com/"
        class="inline-flex hover:text-gray-500 underline"
        >Email Industries</a
      >. All rights reserved.
      <span
        @click="showLegalModal"
        class="hover:text-gray-500 cursor-pointer underline"
        >Terms & Privacy
      </span>
    </footer>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";

export default {
  methods: {
    showLegalModal() {
      eventBus.trigger("show-legal");
    },
  },
};
</script>
