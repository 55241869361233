<template>
  <div class="min-h-screen bg-gray-50 p-2">
    <div class="flex justify-center sm:justify-start px-3 py-3">
      <router-link to="/">
        <img
          class="h-10"
          :src="require('@/assets/images/logo.png')"
          alt="Alfred Knows"
        />
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 h-screen"
    >
    <h1 class="font-bold pt-2 pb-2 text-xl">Almost There!</h1>
    <h2 class="font-bold pt-2 pb-4 text-xl reslink">Please check your inbox to confirm your account</h2>
    <img
          class="turtle-height"
          :src="require('@/assets/images/register_confirmation.png')"
          alt="Alfred Knows"
        />
    </div>
  </div>
</template>
<script>
</script>
<style scoped>
.reslink{
    color: #ff5a5f;
}
.turtle-height{
    height: 15rem;
}
.h-screen {
    height: 90vh !important;
}
</style>