<template>
  <transition name="fade">
    <div
      class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 text-gray-500"
    >
      <div class="bg-white p-6 rounded-lg">
        <h2 class="text-gray-900 text-lg font-semibold mb-4 float-left">Set credit limit</h2>

        <div class="mb-4 flex w-full">
          <span class="text-sm font-medium w-1/2 flex items-center"
            >Organization credits</span
          >
          <input
            type="text"
            :value="formatN(organizationCredits)"
            readonly
            class="w-1/2 border border-gray-300 rounded form-field-no-icon"
          />
        </div>

        <div class="mb-4 flex w-full">
          <span class="text-sm font-medium w-1/2 rounded flex items-center"
            >User credits</span
          >
          <input
            type="text"
            :value="subuserCreditsDisplay"
            readonly
            class="w-1/2 border border-gray-300 rounded form-field-no-icon"
          />
        </div>

        <div class="mb-4 flex items-center">
          <input
            type="text"
            placeholder="Enter credit limit"
            v-model="creditInput"
            @input="validateCreditInput"
            :disabled="isMaxSelected"
            class="w-full p-2 border border-gray-300 rounded form-field-no-icon"
          />
          <div class="flex space-x-2 ml-2">
            <button
              @click="togglePercentage"
              :class="{
                'bg-red-500 text-white': isPercentageSelected,
                'bg-gray-200': !isPercentageSelected,
              }"
              class="px-4 py-2 rounded"
            >
              %
            </button>
            <button
              @click="toggleMax"
              :class="{
                'bg-red-500 text-white': isMaxSelected,
                'bg-gray-200': !isMaxSelected,
              }"
              class="px-4 py-2 rounded"
            >
              Max
            </button>
          </div>
        </div>

        <div class="mb-4 overflow-hidden h-5">
          <div>
            <span v-if="errorMessage" class="text-red-500 text-sm float-left">{{
              errorMessage
            }}</span>
          </div>

          <div
            v-if="
              displayCreditValue !== null &&
              displayCreditValue !== '' &&
              displayCreditValue > 0
            "
          >
            <p class="text-sm text-gray-700 float-left">
              {{ formatN(displayCreditValue) }} Credits will be transferred to
              this user.
            </p>
          </div>
        </div>

        <div class="flex items-center mb-4">
          <input
            type="checkbox"
            v-model="localallowBillingAccess"
            @change="handleCheckboxChange"
            class="mr-2 h-4 w-4 text-red-900 border-gray-300 rounded"
          />
          <label class="text-sm font-medium">Allow billing access</label>
        </div>

        <div class="flex justify-end bg-gray-50 gap-x-5 p-3 rounded-b">
          <button class="px-4 py-2 button-cancel" @click="$emit('close')">
            Cancel
          </button>
          <button
            class="px-4 py-2 button-submit"
            @click="SubmitConfirm"
            :disabled="this.errorMessage !== ''"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { mapGetters } from "vuex";
import humanize from "humanize";
import useConfirmationModal from "@/api/confirmationModal";

export default {
  name: "CreditLimitPopup",
  props: {
    userId: {
      type: Number,
      required: true,
    },
    subuserCredits: {
      type: Number,
      required: true,
    },
    allowBillingAccess: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userCredits: null,
      creditInput: null,
      errorMessage: "",
      isPercentageSelected: false,
      isMaxSelected: false,
      finalCreditValue: null,
      localallowBillingAccess: this.allowBillingAccess,
      isChecked:false
    };
  },
  computed: {
    organizationCredits() {
      let organizationCredits = this.userData.organization.credits;
      return organizationCredits;
    },
    subuserCreditsDisplay() {
      const displayValue= this.subuserCredits === null? "Same as Parent": this.subuserCredits;
      return typeof displayValue === "number"
      ? this.formatN(displayValue)
      : displayValue;
    },
    calculatedCredits() {
      const numericValue = parseFloat(this.creditInput);
      if (
        this.isPercentageSelected &&
        !isNaN(numericValue) &&
        numericValue >= 0 &&
        numericValue <= 100
      ) {
        return Math.round((this.organizationCredits * numericValue) / 100);
      }
      return null;
    },
    displayCreditValue() {
      if (this.isPercentageSelected) {
        let displayCredit =
          this.calculatedCredits !== null ? this.calculatedCredits : null;
        return displayCredit;
      } else if (this.isMaxSelected) {
        return this.organizationCredits;
      } else {
        if (this.creditInput < this.organizationCredits) {
          return this.creditInput;
        }
      }
      return null;
    },
    ...mapGetters({
      userData: "auth/userData",
    }),
  },
  methods: {
    updateFinalCreditValue() {
      const numericValue = parseFloat(this.creditInput);

      if (this.isPercentageSelected) {
        if (
          numericValue === null ||
          isNaN(numericValue) ||
          this.creditInput === ""
        ) {
          this.errorMessage = "";
          this.finalCreditValue = null;
        } else if (numericValue >= 0 && numericValue <= 100) {
          this.finalCreditValue = this.calculatedCredits;
          this.errorMessage = "";
        } else {
          this.errorMessage = "Enter a value between 0 and 100.";
          this.finalCreditValue = null;
        }
      } else if (this.isMaxSelected) {
        this.finalCreditValue = this.organizationCredits;
        this.errorMessage = "";
      } else {
        if (
          numericValue === null ||
          isNaN(numericValue) ||
          this.creditInput === ""
        ) {
          this.errorMessage = "";
          this.finalCreditValue = null;
        } else if (
          numericValue > 0 &&
          numericValue < this.organizationCredits
        ) {
          this.finalCreditValue = this.creditInput;
          this.errorMessage = "";
        } else if (numericValue > this.organizationCredits) {
          this.errorMessage = "Insufficient organization credits";
        } else {
          this.errorMessage = "Credit value cannot be 0";
          this.finalCreditValue = null;
        }
      }
    },
    formatN(count) {
      return humanize.numberFormat(count, 0);
    },
    togglePercentage() {
      this.isPercentageSelected = !this.isPercentageSelected;

      if (this.isPercentageSelected) {
        this.isMaxSelected = false;
        this.creditInput = null;
        this.updateFinalCreditValue();
      } else {
        this.creditInput = null;
        this.finalCreditValue = null;
        this.errorMessage = "";
      }
    },
    toggleMax() {
      this.isMaxSelected = !this.isMaxSelected;
      this.isPercentageSelected = false;
      if (this.isMaxSelected) {
        this.creditInput = this.organizationCredits;
        this.finalCreditValue = this.organizationCredits;
        this.errorMessage = "";
      } else {
        this.creditInput = null;
        this.finalCreditValue = null;
        this.errorMessage = "";
      }
    },
    handleCheckboxChange(){
       this.isChecked= !this.isChecked
       this.errorMessage=""
    },
    async SubmitConfirm() {
      const modal = useConfirmationModal();
      await modal.openModal({
        title: "Are you sure you want to confirm?",
        onSubmit: () => this.submitForm(),
      });
    },
    async submitForm() {
  if (
    (this.finalCreditValue === null ||
      this.finalCreditValue === "" ||
      this.finalCreditValue <= 0) &&
    this.isChecked === false
  ) {
    this.errorMessage = "Enter a value";
    return;
  }
  let payload;
  if (
    (this.finalCreditValue === null ||
      this.finalCreditValue === "" ||
      this.finalCreditValue <= 0) &&
    this.isChecked === true
  ) {
    payload = {
      user: this.userId,
      is_max: this.isMaxSelected,
      is_percentage: null,
      value: 0,
      allow_billing: this.localallowBillingAccess,
    };
  }
  else {
    const value =
      this.isPercentageSelected || this.isMaxSelected
        ? 0
        : parseFloat(this.creditInput) || 0;

    const percentage = this.isPercentageSelected
      ? parseFloat(this.creditInput) || 0
      : 0;

    payload = {
      user: this.userId,
      is_max: this.isMaxSelected,
      is_percentage: this.isPercentageSelected,
      percentage,
      value,
      allow_billing: this.localallowBillingAccess,
    };
  }
  try {
    const response = await this.$alfredService.updateSubUserCredits(payload);
    if (response) {
      eventBus.trigger("notify", {
        notification: {
          group: "top-right",
          type: "success",
          text: response["detail"],
        },
      });
      this.$emit("close");
      eventBus.trigger("update-subuser-data");
      this.$store.dispatch("auth/FETCH_USER_DATA");
    } else {
      console.error("Invalid response:", response);
      this.errorMessage = "Unexpected response format.";
    }
  } catch (error) {
    console.error("Submission error:", error);
    this.errorMessage = "There was an error submitting the form.";
  }
},

    validateCreditInput(event) {
      let value = event.target.value;
      const regex = this.isPercentageSelected
        ? /^[0-9]*\.?[0-9]{0,2}$/
        : /^[0-9]*$/;

      if (!regex.test(value)) {
        value = value.slice(0, -1);
      }

      this.creditInput = value;
    },
  },
  watch: {
    creditInput() {
      this.updateFinalCreditValue();
    },
  },
};
</script>
