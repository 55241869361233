<template>
  <div class="mt-4 overflow-hidden p-1">
    <label>
      {{ label }}

      <div :class="`form-field sm:w-full ${inputClass}`">
        <input
        :placeholder="label"
          type="datetime-local"
          class="flex-grow focus:outline-none placeholder-gray-400 sm:text-sm date_inp"
          v-model="date"
        />
      </div>
      <p v-show="errors" class="text-red-500 text-sm mt-1">
        {{ errors }}
      </p>
    </label>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "DateField",

  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    errors: {
      type: String,
    },
  },

  setup(props) {
    const inputClass = ref("border-gray-300");
    const date = ref(props.modelValue);
    return {
      inputClass,
      date
     };
  },
  mounted() {
    this.date = this.date ? this.date.toISOString().slice(0, -8) : null
  },
  watch: {
    date(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
    modelValue(value) {
      this.date = value;
    },
  },
};
</script>

<style>
select {
  background-image: none;
  border-color: #ffffff00;
}
.date_inp{
  border-color: #fff;
  padding: 0;
}
</style>
