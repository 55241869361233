<template>
    <div class="min-h-screen bg-gray-50 p-2">
      <div class="flex justify-center sm:justify-start px-3 py-3">
        <router-link to="/">
          <img
            class="h-10"
            :src="require('@/assets/images/logo.png')"
            alt="Alfred Knows"
          />
        </router-link>
      </div>
      <div
        class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Authenticating...<br/> Please don't refresh the page.
          </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white text-center py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div>
                <PulseLoader color="#ff5a5f" />
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import PulseLoader from "vue-spinner/src/PulseLoader";

  export default {
    name: "YotpoIntegration",
    components: {
      PulseLoader,
    },
    setup() {
      return {
        code: "",
        app_key: "",
      };
    },
    mounted (){
      const element = this.$refs.navbar;
      if (element) {
      element.classList.add('navbar hidden');
    }
    window.onload = () => {
      this.getURLParams();
    };
    },
    methods: {
      async getURLParams() {
        const urlParams = new URLSearchParams(window.location.search);
        this.code = urlParams.get("code");
        this.app_key = urlParams.get("app_key")
        if(this.code && this.app_key){
          let payload = {
            code: this.code,
            app_key: this.app_key
          }
        try{
        const response = await this.$alfredService.yotpoGenerateAccessToken(payload);
        if (response) {
          this.$store.dispatch('settings/SET_SHOW_UPLOAD_MODAL', true);
          await this.$router.push({ name: "Dashboard" });
      }
      }catch(e){
        console.log(e, "error");
      }
        }
      },
    },
  };
  </script>
  